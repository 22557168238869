import React from 'react'
import styled from 'styled-components'

const Sidebar = ({ children, isSidebarVisible, ...props }) => {
  return (
    <SidebarStyledWrapper isSidebarVisible={isSidebarVisible} {...props}>
      {children}
    </SidebarStyledWrapper>
  )
}

const SidebarStyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  width: 220px;
  background-color: #323232;
`

export default Sidebar
