import { colors } from "@constants";
import React, { FC, useCallback } from "react";
import styled from "styled-components";
import { SubMenuItemType } from ".";

type Props = {
  item: SubMenuItemType;
  t: (key: string) => string;
  onNavigate: (link: string) => void;
};

export const SubItem: FC<Props> = ({ item, t, onNavigate }) => {
  const handleGoTo = useCallback(
    ({ external, linkTo }: SubMenuItemType) =>
      () => {
        if (external) {
          window.location.href = linkTo;
        } else {
          onNavigate(linkTo);
        }
      },
    [onNavigate]
  );

  return (
    <Item $separated={item.separated} onClick={handleGoTo(item)}>
      {item.icon && <span>{item.icon}</span>}
      <Text>{t(item.label)}</Text>
    </Item>
  );
};

const Item = styled.li<{ $separated?: boolean }>`
  padding: 12px;
  display: flex;
  gap: 16px;
  cursor: pointer;
  border-top: ${({ $separated }) => ($separated ? "1px solid #eee" : "none")};
  width: 100%;

  &:hover {
    background: ${colors.PRIMARY_HOVER};
  }
`;

const Text = styled.span`
  line-height: 20px;
  font-size: 14px;
`;
