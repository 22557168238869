import React from 'react'

const ArrowRightIcon = props => {
  const {
    size = 24,
    color = '#202020',
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M9.00001 15.8751L12.88 11.9951L9.00001 8.1151C8.81275 7.92827 8.70752 7.67462 8.70752 7.4101C8.70752 7.14558 8.81275 6.89193 9.00001 6.7051C9.39001 6.3151 10.02 6.3151 10.41 6.7051L15 11.2951C15.39 11.6851 15.39 12.3151 15 12.7051L10.41 17.2951C10.02 17.6851 9.39001 17.6851 9.00001 17.2951C8.62001 16.9051 8.61001 16.2651 9.00001 15.8751Z" fill={color}/>
    </svg>
  )
}

export default ArrowRightIcon
