import React from 'react'

const OperatorsIcon = ({
  size = 24,
  color = '#CCCCCC',
}) => <svg
  width={size}
  height={size}
  viewBox='0 0 20 20'
>
  <path
    d="M10 10.001C12.7614 10.001 15 7.7624 15 5.00098C15 2.23955 12.7614 0.000976562 10 0.000976562C7.23858 0.000976562 5 2.23955 5 5.00098C5 7.7624 7.23858 10.001 10 10.001Z"
    fill={color}
  />
  <path
    d="M16 20.001H20C20 19.6664 19.9832 19.3327 19.9499 19.001C19.8524 18.0308 19.6133 17.0783 19.2388 16.1741C18.7362 14.9609 17.9997 13.8585 17.0711 12.9299C16.1425 12.0013 15.0401 11.2647 13.8268 10.7622C12.6136 10.2596 11.3132 10.001 10 10.001C8.68678 10.001 7.38642 10.2596 6.17317 10.7622C4.95991 11.2647 3.85752 12.0013 2.92893 12.9299C2.00035 13.8585 1.26375 14.9609 0.761204 16.1741C0.386695 17.0783 0.147631 18.0308 0.0501249 19.001C0.0167897 19.3327 0 19.6664 0 20.001H4C4 19.6651 4.0282 19.3306 4.08392 19.001C4.15891 18.5574 4.28373 18.1225 4.45672 17.7049C4.69428 17.1314 5.01901 16.5991 5.41856 16.1266C5.52615 15.9994 5.63916 15.8765 5.75736 15.7583C5.87556 15.6401 5.99845 15.5271 6.12567 15.4195C6.59814 15.02 7.13038 14.6953 7.7039 14.4577C8.43185 14.1562 9.21207 14.001 10 14.001C10.7879 14.001 11.5681 14.1562 12.2961 14.4577C12.8696 14.6953 13.4019 15.02 13.8743 15.4195C14.0016 15.5271 14.1244 15.6401 14.2426 15.7583C14.3608 15.8765 14.4739 15.9994 14.5814 16.1266C14.981 16.5991 15.3057 17.1314 15.5433 17.7049C15.7163 18.1225 15.8411 18.5574 15.9161 19.001C15.9718 19.3306 16 19.6651 16 20.001Z"
    fill={color}
  />

  <path
    d="M13.1643 16.1296L10.2929 19.001H9.70711L6.83574 16.1296C7.21322 15.8211 7.63451 15.5688 8.08658 15.3816C8.69321 15.1303 9.34339 15.001 10 15.001C10.6566 15.001 11.3068 15.1303 11.9134 15.3816C12.3655 15.5688 12.7868 15.8211 13.1643 16.1296Z"
    fill={color}
  />

  <path
    d="M8.29289 19.001L6.12863 16.8367C5.8201 17.2142 5.56786 17.6355 5.3806 18.0876C5.25818 18.3831 5.1647 18.689 5.10102 19.001H8.29289Z"
    fill={color}
  />

  <path
    d="M11.7071 19.001L13.8714 16.8367C14.1799 17.2142 14.4321 17.6355 14.6194 18.0876C14.7418 18.3831 14.8353 18.689 14.899 19.001H11.7071Z"
    fill={color}
  />
</svg>

export default OperatorsIcon
