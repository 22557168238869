export * as background from './background'
export * as text from './text'
export * as icons from './icons'


// Легаси цвета:
// Текст
export const PRIMARY_FONT = '#202020' // Основной текст
export const SECONDARY_FONT = '#a1a1a1' // Вспомогательный текст (надписи, подсказки)
export const SELECTED_FONT = '#fce2e2' // Для текста в дереве
export const MARKED_FONT = '#ffff00' // Выделенный текст (для поиска)
export const LIGHT_FONT = '#fff' // Для надписей на темном фоне

// Фон, граница, заливка
export const PRIMARY_BACKGROUND = '#fff'
export const SECONDARY_BACKGROUND = '#f5f6f8'
export const BACKGROUND_DARK = '#323232' // Меню, навигации
export const BACKGROUND_LIGHT = '#fff'
export const BACKGROUND_NONE = 'transparent'
export const PRIMARY_OUTLINE = '#c9ccd4' // Граница, контур

// Состояния
export const PRIMARY_HOVER = 'rgba(0, 0, 0, 0.04)'
export const SECONDARY_HOVER = 'rgba(209, 0, 41, 0.08)' // Для строки таблицы
export const DARK_HOVER = '#202020' // Для элемента меню
export const PRIMARY_FOCUS = '#202020'
export const PRIMARY_DISABLE = '#e0e0e0'
export const SECONDARY_SELECT = 'rgba(209, 0, 41, 0.08)' // Для строки таблицы

// Особые цвета
export const PRIMARY_ERROR = '#D10029'
export const PRIMARY_IMPORTANT = '#D10029'

export const buttonBlackColor = '#202020'
export const buttonDisabledColor = '#E0E0E0'
export const buttonRedColor = '#D10029'


// Цвета для контролов основанных на Input
export const inputColors = {
  important: PRIMARY_IMPORTANT,
  error    : PRIMARY_ERROR,

  textDefault      : PRIMARY_FONT,
  labelDefault     : SECONDARY_FONT,
  helperTextDefault: SECONDARY_FONT,

  borderDefault: PRIMARY_OUTLINE,
  borderFocus  : PRIMARY_FOCUS,

  backgroundDefault: PRIMARY_BACKGROUND,
  backgroundDisable: PRIMARY_DISABLE,
}

// Цвета для навигационных меню, списков, панелей
export const navColors = {
  textDefault: LIGHT_FONT,

  itemHover : DARK_HOVER,
  itemActive: DARK_HOVER,

  backgroundDefault: BACKGROUND_DARK,
  backgroundDisable: BACKGROUND_NONE,
}

export const modesSchema = {
  TIME_NO_LINK          : { name: 'M0', color: '#BCBCBC' },
  TIME_MOVE_NO_HARVEST  : { name: 'M1', color: '#06a9f5' },
  TIME_IDLE             : { name: 'downtime', color: '#d10a40' }, // Спец. режим для виджета времени, обьединяет М3, М5, М6
  TIME_OFF              : { name: 'M2', color: '#202020' },
  TIME_STOP_DON         : { name: 'M3', color: '#d10a40' },
  TIME_HARVEST          : { name: 'M4', color: '#78C800' },
  TIME_STOP_HARVEST     : { name: 'M5', color: '#d61fd6' },
  TIME_STOP_HARVEST_FULL: { name: 'M6', color: '#F37203' },
}
