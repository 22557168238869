import 'imask/esm/masked/number'
import 'imask/esm/masked/pipe'
import 'imask/esm/masked/pattern'
import IMask from 'imask/esm/imask'
import { always, pipe, tryCatch } from 'ramda'

// As any for a while bug is not fixed
export const hexMacMask: (value: any) => string = tryCatch(IMask.createPipe({
  definitions: { X: /[0-9A-Z]/ },

  mask: 'XX\\:XX\\:XX\\:XX\\:XX\\:XX',

  prepare: function (append) {
    return append.toUpperCase()
  },

} as any), always(''))

// As any for a while bug is not fixed
export const hexMask: (value: any) => string = tryCatch(IMask.createPipe({
  mask: /^[0-9A-F]*$/,

  prepare: function (str) {
    return str.toUpperCase()
  },
} as any), always(''))

export const onlyHex: (value: any) => string = pipe<any, string>(hexMask)

export const padHexValue: (value: string, length: number) => string = (value, length) =>
  ('0'.repeat(length) + value).slice(-length)

export const validate = (value: any) => [toNumber(value).toString(16).toUpperCase(), onlyHex(value).toUpperCase()]
export const toNumber: (value: string) => Number = value => parseInt(onlyHex(value), 16)
export const toHex: (value: string) => String = value => Number(value).toString(16)
