import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'

export type TApperance = 'large' | 'tiny' | 'medium'

export type TApperanceProp = {
  apperance?: TApperance
}

interface TRowProps extends HTMLAttributes<HTMLTableRowElement>, TApperanceProp {
  hAlign?: string
  height?: string | number
  backgroundColor?: string
}

type TSelectable = {
  isSelected?: boolean
}

export function HeaderRow({ children, apperance, height, ...props }: TRowProps) {
  return <HeaderRowContainer apperance={apperance} height={height} {...props}>{children}</HeaderRowContainer>
}

export default function TableRow({ children, apperance, height, isSelected, ...props }: TRowProps & TSelectable) {
  return (
    <TableRowContainer apperance={apperance} height={height} isSelected={isSelected} {...props}>
      {children}
      {isSelected && <RowSelectIndicator />}
    </TableRowContainer>
  )
}

export function FooterRow({ children, ...props }: TRowProps) {
  return <FooterRowContainer {...props}>{children}</FooterRowContainer>
}

export const BaseTableRowContainer = styled.tr`
  width: 100%;
`

const ExtendedBaseTableRowContainer = styled(BaseTableRowContainer)`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const HeaderRowContainer = styled(ExtendedBaseTableRowContainer)<TRowProps>`
  /* Ширина с учетом ширины вертикального скролла */
  width: calc(100% - 17px);
  min-height: ${({ apperance, height }) => height !== undefined
    ? height
    : { large: '80px', medium: '62px', tiny: '51px' }[apperance || 'tiny']
};
`

const TableRowContainer = styled(ExtendedBaseTableRowContainer)<TRowProps & TSelectable>`
  position: relative;
  justify-content: ${p => p.hAlign || 'flex-start'};
  cursor: pointer;
  color: #202020;
  border-bottom: 1px solid #e0e0e0;

  & > td {
    color: #202020;
    border-bottom: none;
  }

  &:nth-child(even) {
    background-color: ${({ isSelected, backgroundColor }) => isSelected ? '#ffeded' : backgroundColor || '#f5f5f5'};
  }

  &:nth-child(odd) {
    background-color: ${({ isSelected, backgroundColor }) => isSelected ? '#ffeded' : backgroundColor || '#fff'};
  }

  &:nth-child(even):hover,
  &:nth-child(odd):hover {
    background-color: ${({ backgroundColor }) => backgroundColor || '#ffeded'} ;
  }

  min-height: ${({ apperance, height }) => height !== undefined
    ? height
    : { large: '80px', medium: '62px', tiny: '51px' }[apperance || 'tiny']
};
`

const FooterRowContainer = styled(ExtendedBaseTableRowContainer)<TRowProps>`
  justify-content: ${p => p.hAlign || 'flex-start'};
  position: relative;
  border-top: 1px solid #ECECEC;
`

const RowSelectIndicator = styled.td`
  position: absolute;
  left: 0;
  background-color: #D10029;
  height: 100%;
  width: 4px;
  box-sizing: border-box;
`
