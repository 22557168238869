import React from 'react'
import styled from 'styled-components'

const LeftActionBarButton = props => {
  const {
    icon,
    children,
    onClick,
    selected,
  } = props

  return (
    <Container
      selected={selected}
      onClick={onClick}
    >
      {icon({
        color: selected ? '#FFFFFF' : '#202020',
        size : 30,
      })}
      <Text selected={selected}>{children}</Text>
    </Container>
  )
}

const Container = styled.div`
  box-sizing: border-box;
  width: 92px;
  height: 92px;
  background-color: ${({ selected }) => selected ? '#D10029' : 'transparent'};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${({ selected }) => selected ? 'default' : 'pointer'};
  pointer-events: ${({ selected }) => selected ? 'none' : 'all'};
`

const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${({ selected }) => selected ? '#FFFFFF' : '#202020'};
  padding: 2px;
  text-align: center;
  user-select: none;
`

export default LeftActionBarButton
