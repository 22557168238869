import React from 'react'
import styled from 'styled-components'

const CheckboxIcon = props => {
  const { active = true, checked, size = 24, semiChecked = false, ...restProps } = props

  return (
    <svg
      width={size}
      height={size}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#FFFFFF"
      {...restProps}
    >
      <g>
        <RectStyled
          active={active}
          checked={semiChecked ? checked || checked === null : checked}
          width="23"
          height="23"
          rx="2"
          x="0.5"
          y="0.5"
        />

        {semiChecked && checked === null
          ? <rect fill="#ffffff" x="5" y="11" width="14" height="2" />
          : checked && <path

            // eslint-disable-next-line max-len
            d="M18.6138 6.21059C19.0498 6.54958 19.1285 7.17784 18.7895 7.61385L11.0145 17.6138C10.8414 17.8365 10.5822 17.9758 10.3009 17.9972C10.0197 18.0186 9.74242 17.9202 9.53757 17.7262L5.3125 13.7262C4.91144 13.3465 4.89412 12.7136 5.27382 12.3126C5.65351 11.9115 6.28644 11.8942 6.6875 12.2739L10.1122 15.5161L17.2105 6.38625C17.5495 5.95024 18.1778 5.87159 18.6138 6.21059Z"
          />
        }
      </g>
*    </svg>
  )
}

export default CheckboxIcon

const RectStyled = styled.rect`
  fill: ${({ active, checked }) => checked ? active ? '#D10029' : '#A1A1A1' : 'white'};
  stroke: ${({ active, checked }) => active && checked ? '#D10029' : '#A1A1A1'};
  transition: fill .4s ease;
`
