import { is } from 'ramda'
import { isNil } from 'ramda'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import InputControlContainer from '../InputControlContainer'

const hasValue = inputValue => {
  if(isNil(inputValue) || inputValue === ''){return false}

  return true
}

const Button = props => {
  const {
    children,
    value,
    icon,
    label,
    helperText,
    error,
    filter,
    postProcessValue,
    onChange,
    onKeyPress,
    onEnter,
    onFocus,
    onClick,
    required,
    readonly,
    disabled,
    updateHelperTextHeight,
    ...rest
  } = props

  const inputRef = useRef()

  const handleClick = () => {
    inputRef.current.focus()

    if(is(Function, onClick)) {onClick()}
  }

  const handleFocus = () => {
    if(onFocus) {onFocus()}
  }

  return (
    <InputControlContainer
      label={label}
      helperText={helperText}
      icon={icon}
      onClick={handleClick}
      focused={hasValue(value)}
      required={required}
      disabled={disabled}
      error={error}
      updateHelperTextHeight={updateHelperTextHeight}
    >
      <TextBox
        ref={inputRef}
        value={value}
        type="text"
        onFocus={handleFocus}
        readOnly
        disabled={disabled}
        withChildren={Boolean(children)}
        {...rest}
      />
      {children}
    </InputControlContainer>
  )
}

export default Button


const TextBox = styled.input`
  margin: 0;
  padding: 0;
  flex: 1 1 100%;
  height: 40px;
  position: ${({ withChildren }) => withChildren ? 'absolute' : 'inherit'};
  color: #202020;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  border: none;
  box-sizing : border-box;
  outline: none;
  cursor: pointer;
  width: 100%;

  &:disabled {
    color: #a1a1a1;
  }
`
