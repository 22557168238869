import React from 'react'
import styled, { css } from 'styled-components'

// type TProps = {
//   count?: Number,
//   size?: Number,
//   maxCount?: Number,
//   color?: string,
//   text?: string,
//   className?: string,
//   children?: ElementType,
//   textSize?: Number,
// }

const Badge = props => {
  const {
    count = 30,
    size = 20,
    maxCount = null,
    color = '#D10029',
    text = '#ffffff',
    className,
    children,
    textSize,
  } = props

  let displayCount = count

  if(maxCount != null) {
    displayCount = maxCount < count ? `+${maxCount}` : count
  }

  return (
    <Wrapper size={size} className={className}>
      <BadgeContainer
        color={color}
        size={size}
        text={text}
        textSize={textSize}
        widthChildren={Boolean(children)}
      >
        {displayCount}
      </BadgeContainer>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: fit-content;
`

const BadgeContainer = styled.div`
 ${({ widthChildren }) => widthChildren
  && css`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, -50%);
    transform-origin: 100% 0;
  `};

  background-color: ${({ color }) => color || '#D10029'};
  z-index: auto;
  min-width: ${({ size }) => size ? `${size}px` : '20px'};
  height: ${({ size }) => size ? `${size}px` : '20px'};
  padding: 0 6px;
  color: ${({ text }) => text || '#ffffff'};
  font-weight: normal;
  font-size: ${({ textSize }) => textSize ? `${textSize}px` : '12px'};
  line-height: ${({ size }) => size ? `${size}px` : '20px'};
  white-space: nowrap;
  text-align: center;
  border-radius: ${({ size }) => size ? `${size / 2}px` : '10px'};
`

export default Badge
