import { ArrowDropDownIcon, InputClearIcon } from 'icons'
import React, { useState } from 'react'
import Button from './Button'
import DropdownList from '../DropdownList'
import styled from 'styled-components'

export default function Select(props) {
  const {
    children,
    title,
    label,
    helperText,
    isPopupOpen,
    onShowPopup,
    onHidePopup,
    onChange,
    error,
    required,
    disabled,
    disableClear,
    multiple,
  } = props

  const [helperTextHeight, setHelperTextHeight] = useState(0)

  const handleClear = (e) => {
    if(onChange && typeof onChange === 'function') {
      onChange(null)
      e.stopPropagation()
    }
  }

  return (
    <DropdownList
      listChildren={children}
      onHidePopup={onHidePopup}
      isPopupOpen={isPopupOpen}
      popupOffset={helperText ? [0, -helperTextHeight] : null}
      {...props}
    >
      <Button
        {...multiple ? {} : { value: title }}
        label={label}
        onClick={onShowPopup}
        disabled={disabled || !children}
        helperText={helperText}
        icon={
          title && !disabled && !disableClear && !multiple ?
            <ClearButton onClick={handleClear}>
              <ClearIcon />
            </ClearButton>
            :
            <ArrowIcon direction={isPopupOpen ? 'top' : 'bottom'} />
        }
        error={error}
        required={required}
        updateHelperTextHeight={height => setHelperTextHeight(height)}
      >{multiple && title}</Button>
    </DropdownList>
  )
}

const ArrowIcon = styled(ArrowDropDownIcon).attrs({ color: '#a1a1a1' })``

const ClearIcon = styled(InputClearIcon).attrs({ color: '#a1a1a1' })``

const ClearButton = styled.button`
  /* Растягиваем кнопку на всю высоту и длину контейнера, в который он помещается */
  height: 100%;
  width: 100%;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
`
