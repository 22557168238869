import React, { useCallback } from 'react'
import styled from 'styled-components'

const hexToRGBA = (hex, alpha = 20) => {
  const bigint = parseInt(hex.replace('#',''), 16)
  alpha = alpha / 100
  const r = bigint >> 16 & 255
  const g = bigint >> 8 & 255
  const b = bigint & 255

  return `rgba(${r},${g},${b},${alpha})`
}

const DayItem = props => {
  const { day, isSelected, onSelect, dayColor, disabled } = props
  const { id, isInCurMonth } = day

  const handleClick = useCallback(() => {
    if(isInCurMonth && !disabled) {onSelect(day.id)}
  }, [isInCurMonth, disabled, onSelect, day.id])

  return (
    <Container disabled={disabled} onClick={handleClick}>
      {isInCurMonth &&
        <ContentContainer isSelected={isSelected} disabled={disabled} dayColor={dayColor}>
          {id}
        </ContentContainer>
      }
    </Container>
  )
}

const Container = styled.div`
  padding: 7px;
  cursor: ${({ disabled })=> disabled ? 'auto' : 'pointer'};
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  user-select: none;
  opacity: ${({ disabled })=> disabled ? 0.5 : 1};
`

const calcColor = ({ isSelected }) => isSelected ? '#D10029' : '#202020'

const calcBackgroundColor = ({ isSelected, dayColor }) => {
  if(dayColor) {
    return isSelected ? hexToRGBA(dayColor, 40) : dayColor
  }

  return isSelected ? '#f0f0f0' : 'transparent'
}

const ContentContainer = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${calcColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${calcBackgroundColor};
  transition: .2s background-color ease-in-out;

  height: 100%;

  ${Container}:hover & {
    background-color: ${({ isSelected }) => isSelected ? '#fce7e7' : '#E5E5E5'};
  }
`

export default DayItem
