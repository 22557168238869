import React from 'react'

const defaultParam = {
  className: '',
  color    : '#202020',
  rounded  : false,
  size     : '24px',
}

type TAddIcon = {
  className?: string
  color?: string
  rounded?: boolean
  size?: string
}

const AddIcon = (props:TAddIcon = defaultParam) => {
  const {
    className = defaultParam.className,
    color = defaultParam.color,
    rounded = defaultParam.rounded,
    size = defaultParam.size,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        /* eslint-disable-next-line max-len */
        d="M13 11.01H11V13.01C11 13.56 10.55 14.01 10 14.01C9.45 14.01 9 13.56 9 13.01V11.01H7C6.45 11.01 6 10.56 6 10.01V10C6 9.45 6.45 9 7 9H9V7C9 6.45 9.45 6 10 6C10.55 6 11 6.45 11 7V9H13C13.55 9 14 9.45 14 10V10.01C14 10.56 13.55 11.01 13 11.01Z"
        fill={color}
      />
      {rounded && <circle cx="10" cy="10" r="9" stroke={color} strokeWidth="2" />}
    </svg>
  )
}

export default AddIcon
