import React, { useState } from 'react'
import styled from 'styled-components'

import Select from '../Select'
import SelectItem from '../SelectItem'

const formatValue = value => Math.floor(value / 10) > 0
  ? `${value}`
  : `0${value}`

const TimeItemSelector2 = props => {
  const { maxHeight, value, options, onChange } = props
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const handleItemClick = item => {
    setIsPopupOpen(false)
    onChange(item)
  }

  return (
    <StyledSelect
      title={formatValue(value)}
      isPopupOpen={isPopupOpen}
      onShowPopup={() => setIsPopupOpen(true)}
      onHidePopup={() => setIsPopupOpen(false)}
      maxHeight={maxHeight}
    >
      {options.map(option =>
        <SelectItemStyled
          key={option}
          onClick={() => handleItemClick(option)}
        >
          {formatValue(option)}
        </SelectItemStyled>,
      )}
    </StyledSelect>
  )
}

const StyledSelect = styled(Select)`
  margin: 0 4px;

  & input {
    height: 36px;
    text-align: center;
    width: 100%;
  }

  & > div > div {
    height: 36px;
    width: 36px;

    & > div {
      margin: 0;
      width: 36px;
    }
    & > div:nth-child(2) {
      display: none;
    }
  }
`

const SelectItemStyled = styled(SelectItem)`
  padding: 8px;
`

export default TimeItemSelector2
