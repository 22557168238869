import React from 'react'

const CombineIcon = ({
  size = 24,
  color = '#CCCCCC',
}) => <svg
  width={size}
  height={size / 1.71}
  viewBox='0 0 24 14'
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16 8.00098C17.657 8.00098 19 9.34398 19 11.001C19 12.658 17.657 14.001 16 14.001C14.343 14.001 13 12.658 13 11.001C13 9.34398 14.343 8.00098 16 8.00098ZM5 8.00098C6.657 8.00098 8 9.34398 8 11.001C8 12.658 6.657 14.001 5 14.001C3.343 14.001 2 12.658 2 11.001C2 9.34398 3.343 8.00098 5 8.00098ZM23.9692 13.502C24.0033 13.776 23.7761 14.001 23.5 14.001H20V10.001C22.04 10.001 23.7235 11.5284 23.9692 13.502ZM16 1.00098C16 0.448692 16.4477 0.000976562 17 0.000976562H20.847C21.4555 0.000976562 21.923 0.539965 21.8369 1.1424L21.1227 6.1424C21.0523 6.63505 20.6304 7.00098 20.1327 7.00098H16C13.791 7.00098 12 8.79198 12 11.001V12.001C12 12.5533 11.5523 13.001 11 13.001H10C9.44771 13.001 9 12.5533 9 12.001V11.001C9 8.79198 7.209 7.00098 5 7.00098C2.95999 7.00098 1.27647 8.52844 1.03083 10.502C0.996722 10.776 0.776142 11.001 0.5 11.001C0.223858 11.001 0 10.7771 0 10.501V7.23704C0 7.0818 0.0361451 6.92869 0.105573 6.78983L0.760659 5.47966C0.910186 5.1806 1.19928 4.97607 1.53105 4.9346L9 4.00098H13L13.429 1.00098H11.5766C11.2723 1.00098 11.0385 0.731435 11.0816 0.430197C11.1168 0.183904 11.3278 0.000976562 11.5766 0.000976562H13.571H14C14.5523 0.000976562 15 0.448692 15 1.00098V4.00098H16V1.00098Z"
    fill={color}
  />

</svg>

export default CombineIcon
