import React, { Fragment, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react'
import { ArrowIcon } from 'icons'
import styled from 'styled-components'
import { ClassNames } from 'react-day-picker/types/ClassNames'


const emptyFunc = () => {}

type TAccordion = PropsWithChildren<{
  arrowLeft?: boolean
  collapsedArrowDirection?: 'bottom' | 'right' | 'left' | 'top'
  expandedArrowDirection?: 'bottom' | 'right' | 'left' | 'top'
  isExpanded?: boolean
  title: string | JSX.Element
  onToggle: (state: boolean) => void
  className: any
}>

const Accordion = ({
  arrowLeft,
  children,
  collapsedArrowDirection = 'bottom',
  expandedArrowDirection = 'top',
  isExpanded = false,
  title,
  onToggle = emptyFunc,
  className
}: TAccordion) => {
  const [expanded, setExpanded] = useState(isExpanded)
  const onClickHandler = useCallback(() => {setExpanded(!expanded); onToggle(!expanded)}, [expanded])

  useEffect(() => {
    setExpanded(isExpanded)
  }, [isExpanded])

  return <Fragment>
    <AccordionWrapper arrowLeft={arrowLeft} onClick={onClickHandler} className={className}>
      <AccordionTitle>{title}</AccordionTitle>

      <ArrowIcon
        direction={expanded ? expandedArrowDirection: collapsedArrowDirection}
        size={12}
      />
    </AccordionWrapper>
    {expanded && children}
  </Fragment>
}

const AccordionWrapper = styled.div<{ arrowLeft: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding-right: 12px;
  flex-direction: ${({ arrowLeft }) => arrowLeft ? 'row-reverse' : 'row'};
`

const AccordionTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  flex-grow: 1;
`

export default Accordion
