// Основной
export const PRIMARY = '#111111'

// Основной на тёмном фоне
export const PRIMARY_INVERSION = '#FEFEFE'

// Второстеменный текст
export const SECONDARY = '#666666'

// Брендовый текст
export const BRAND = '#D10029'

// Активное состояние, ссылка
export const ACTIVE = '#D10029'

// Активное состояние, ссылка
export const ACTIVE_HOVER = '#E70E10'

// Не активный выключен
export const DISABLED = '#0000004D'

// Текст, сообщающий онормальном состоянии
export const NORMAL = '#51B5F3'

// Текст, сообщающий об успешном состоянии
export const SUCCESS = '#22C38E'

// Текст, требующий и привлекающий внимание
export const WARNING = '#F8B000'

// Текст сообщающий о состоянии ошибки
export const ALERT = '#B82D2D'
