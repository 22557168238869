import { colors } from "@constants";
import { MainMenuItemType } from "components/MainMenu";
import React, { forwardRef, HTMLAttributes, useCallback } from "react";
import styled from "styled-components";

const { navColors } = colors;

type Props = {
  label?: string;
  icon: JSX.Element;
  active: boolean;
  disabled?: boolean;
  value: MainMenuItemType;
  isOnClickSetted?: boolean;
  onClick?: (event: React.MouseEvent, item: MainMenuItemType) => void;
  testId?: string;
} & HTMLAttributes<HTMLDivElement>;

const AppMenuNavItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      icon,
      label,
      onClick,
      active,
      disabled,
      value,
      isOnClickSetted,
      testId,
      ...rest
    },
    ref
  ) => {
    const onClickHandle = useCallback(
      (event: React.MouseEvent) => {
        if (onClick) {
          onClick(event, value);
        }
      },
      [onClick, value]
    );

    return (
      <Container
        ref={ref}
        data-testid={testId}
        onClick={onClickHandle}
        $isOnClickSetted={Boolean(onClick) || isOnClickSetted}
        $active={active}
        $disabled={disabled}
        {...rest}
      >
        {icon && <IconContainer>{icon}</IconContainer>}
        {label && <LabelContainer>{label}</LabelContainer>}
      </Container>
    );
  }
);

export default AppMenuNavItem;

const IconContainer = styled.div`
  transition: opacity 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const LabelContainer = styled.span`
  color: ${navColors.textDefault};
  font-size: 12px;
  @media (max-width: 1611px) {
    display: none;
  }
`;

const Container = styled.div<{
  $active: boolean;
  $disabled?: boolean;
  $isOnClickSetted: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 15px;
  background-color: ${({ $active }) =>
    $active ? navColors.itemActive : "transparent"};
  cursor: ${({ $disabled, $isOnClickSetted }) =>
    !$disabled && $isOnClickSetted ? "pointer" : "default"};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "all")};
  box-sizing: border-box;
  transition: background-color 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  & > ${IconContainer}, & > ${LabelContainer} {
    opacity: ${({ $active }) => ($active ? 1 : 0.6)};
  }

  &:hover {
    background-color: ${({ $disabled, $isOnClickSetted }) =>
      $disabled || !$isOnClickSetted
        ? navColors.backgroundDisable
        : navColors.itemHover};

    & > ${IconContainer}, & > ${LabelContainer} {
      opacity: ${({ $disabled, $isOnClickSetted }) =>
        !$disabled && $isOnClickSetted ? 1 : 0.6};
    }
  }
`;
