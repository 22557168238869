import React, { useState } from 'react'
import styled from 'styled-components'
import { mergeDeepRight } from 'ramda'

import DataSelector from 'components/DateSelector/Data/DataSelector'
import MonthChanger from './MonthChanger'
import DaySelector from './DaySelector'

import defaultTitles from './defaultTitles'
import TimeSelector from '../TimeSelector'

const destructure = date => date ? {
  year  : date.getFullYear(),
  month : date.getMonth(),
  day   : date.getDate(),
  hour  : date.getHours(),
  minute: date.getMinutes(),
  second: date.getSeconds(),
} : null

const copyTime = (oldDate, newDate) => {
  const dateObj = destructure(oldDate)
  const tempDate = new Date(+newDate)

  tempDate.setHours(dateObj.hour)
  tempDate.setMinutes(dateObj.minute)
  tempDate.setSeconds(dateObj.second)

  return tempDate
}

const clearTime = newDate => {
  const tempDate = new Date(+newDate)

  tempDate.setHours(0)
  tempDate.setMinutes(0)
  tempDate.setSeconds(0)
  tempDate.setMilliseconds(0)

  return tempDate
}

const getDateString = date => {
  const dateObj = destructure(date)
  return `${dateObj.day}.${dateObj.month}.${dateObj.year}`
}

const getInitialParts = value => {
  const zeroDate = new Date()
  zeroDate.setHours(0, 0, 0, 0)
  return Array.isArray(value)
    ? [destructure(value[0]), destructure(value[1])]
    : destructure(value || zeroDate)
}

const getIsSelected = (value, curYear, curMonth) => day => {
  const curDate = new Date(curYear, curMonth, day)

  if(Array.isArray(value)) {
    const [start, end] = value

    const isEqualsStart = start && +clearTime(start) === +clearTime(curDate)
    const isAfterOrEqualsStart = start && +clearTime(start) <= +clearTime(curDate)
    const isBeforeOrEqualsEnd = end && +clearTime(end) >= +clearTime(curDate)

    return isEqualsStart || isAfterOrEqualsStart && isBeforeOrEqualsEnd
  }

  return value && +clearTime(value) === +clearTime(curDate)
}

const currentDay = getDateString(new Date())

const DateSelector = props => {
  const {
    className,
    noTimeRange,
    titles = {},
    value,
    onChange,
    withTime,
    data,
    withData,
    onChangeData,
    dataSelected,
    colorValues,
    colors,
    cbChangeMonth,
    maxCurrentDate,
  } = props

  const initialParts = getInitialParts(value)

  const [year, setYear] = useState(Array.isArray(initialParts)
    ? initialParts[0].year
    : initialParts.year)

  const [month, setMonth] = useState(Array.isArray(initialParts)
    ? initialParts[0].month
    : initialParts.month)

  const handleChangeInRange = newDate => {
    if(newDate == null){return}

    const [start, end] = value

    if(
      !start
        || getDateString(start) !== getDateString(end)
        || getDateString(start) === currentDay
    ) {
      const newStart = clearTime(newDate)
      const newEnd = copyTime(end, newDate)

      // Set "to date" to end of day
      newEnd.setHours(23, 59, 59)

      onChange([newStart, newEnd])
    }else{
      const newRange = [start, newDate].sort((d1, d2) => d1 - d2)
      newRange[1] = copyTime(end, newRange[1])

      onChange(newRange)
    }
  }

  const handleDaySelect = selectedDay => {
    const newDate = new Date(year, month, selectedDay)

    if(withTime) {
      const time = Array.isArray(initialParts) ? initialParts[0] : initialParts

      if(time) {
        newDate.setHours(time.hour, time.minute, time.second)
      }
    }

    if(Array.isArray(value)) {
      handleChangeInRange(newDate)
    }else{
      const to = new Date(year, month, selectedDay)

      if(withTime) {
        // Code to save already selected time
        if(!noTimeRange && initialParts[1]) {
          to.setHours(initialParts[1].hour, initialParts[1].minute, initialParts[1].second)
        }else{
          // Set "to date" to end of day
          to.setHours(23, 59, 59)
        }
      }

      onChange([newDate, to])
    }
  }

  const setStartTime = ({ hour, minute, second }) => {
    const newEnd = new Date(value)
    newEnd.setHours(23, 59, 59)

    // eslint-disable-next-line prefer-const
    let [start, end] = Array.isArray(value)
      ? value
      : [value, newEnd]

    if(!start) {start = new Date()}

    start.setHours(hour)
    start.setMinutes(minute)
    start.setSeconds(second)
    onChange([start, end])
  }

  const setFinishTime = ({ hour, minute, second }) => {
    const [start, end] = value
    end.setHours(hour)
    end.setMinutes(minute)
    end.setSeconds(second)
    onChange([start, end])
  }

  const finalTitles = mergeDeepRight(defaultTitles, titles)

  return (
    <Container className={className}>
      <TopControlsContainer>
        <MonthChanger
          monthsTitles={finalTitles.months}
          year={year}
          month={month}
          onChangeYear={setYear}
          onChangeMonth={setMonth}
          cbChangeMonth={cbChangeMonth}
          maxCurrentDate={maxCurrentDate}
        />
      </TopControlsContainer>
      {withTime &&
      <TimeWrapper>
        <TimeSelector
          value={Array.isArray(initialParts) ? initialParts[0] : initialParts}
          onChange={setStartTime}
          maxHeight="276px"
        />
        {!noTimeRange &&
          <>
            |
            <TimeSelector
              value={initialParts[1]}
              onChange={setFinishTime}
              maxHeight="276px"
            />
          </>
        }
      </TimeWrapper>
      }
      <DaySelector
        weekDaysTitles={finalTitles.weekDays}
        maxCurrentDate={maxCurrentDate}
        year={year}
        month={month}
        colorValues={colorValues}
        withData={withData}
        getIsSelected={getIsSelected(value, year, month)}
        onSelectDay={handleDaySelect}
        colors={colors}
        levels={dataSelected && dataSelected.levels}
      />
      {withData && colors &&
        <DataSelector options={data} value={dataSelected} onChange={onChangeData} colors={colors} />
      }
    </Container>
  )
}

const Container = styled.div`
  width: fit-content;
`

const TopControlsContainer = styled.div`
  margin-bottom: 24px;
`

const TimeWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
`

export default DateSelector
