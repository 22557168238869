import React, { useCallback, useState } from 'react'
import Select from 'components/Select'
import styled from 'styled-components'
import Tree from './Tree'

export type TDataTree = {
  id: number
  name: string
  children?: TDataTree[]
}

type TProps = {
  disabled?: boolean
  items: TDataTree[]
  itemSize: number
  label?: string
  listHeight: number
  onSelectOption: (arg: number) => void
  required?: boolean
  title: string
}

const SelectTree = ({ disabled, items, itemSize, label, listHeight, onSelectOption, required, title }: TProps) => {
  const [showParentOrgList, setShowParentOrgList] = useState(false)

  const onHidePopupHandler = useCallback(() => {
    if(showParentOrgList) {
      setShowParentOrgList(false)
    }
  }, [showParentOrgList])

  const onSelectHandle = useCallback((optionId: number) => {
    onSelectOption(optionId)
    setShowParentOrgList(false)
  }, [onSelectOption])

  return <InputContainer>
    <Select
      disabled={disabled}
      isPopupOpen={showParentOrgList}
      label={label}
      onHidePopup={onHidePopupHandler}
      onShowPopup={() => setShowParentOrgList(true)}
      required={required}
      title={title}
    >
      <Tree
        height={listHeight}
        items={items}
        itemSize={itemSize}
        onSelectOption={onSelectHandle}
      />
    </Select>
  </InputContainer>
}

export default SelectTree

const InputContainer = styled.div`
  width: 384px;
`
