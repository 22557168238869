import { is } from 'ramda'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { ArrowIcon } from '../../../icons'
import IconButton from '../../IconButton'

const PagePicker = props => {
  const {
    totalPages,
    currentPage,
    onChangePage,
    pickerLength,
  } = props

  const pagesCount = pickerLength < 5 ? 5 : pickerLength

  const onClickOnPage = useCallback(pageNumber => {
    if(currentPage !== pageNumber && is(Function, onChangePage)) {onChangePage(pageNumber)}
  }, [currentPage, onChangePage])

  const onClickPrevPage = useCallback(() => {
    if(is(Function, onChangePage)) {onChangePage(currentPage - 1)}
  }, [currentPage, onChangePage])

  const onClickNextPage = useCallback(() => {
    if(is(Function, onChangePage)) {onChangePage(currentPage + 1)}
  }, [currentPage, onChangePage])

  const pagesIndexes = []

  if(currentPage <= pagesCount - 2) {
    for(let i = 1; i < totalPages && i <= pagesCount - 2; i += 1) {
      pagesIndexes.push(i)
    }

    if(totalPages - pagesCount > 1) {
      pagesIndexes.push('...')
    }

    pagesIndexes.push(totalPages)

  // Center
  }else if(totalPages - currentPage > pagesCount - 3) {
    pagesIndexes.push(1)
    pagesIndexes.push('...')

    // Calculate size of buffer of pages
    const bufferSize = Math.floor((pagesCount - 4) / 2)


    // Left buffer of pages
    for(let i = currentPage - bufferSize; i < currentPage; i += 1) {
      pagesIndexes.push(i)
    }

    pagesIndexes.push(currentPage)


    // Right buffer of pages
    for(let i = currentPage + 1; i <= currentPage + bufferSize; i += 1) {
      pagesIndexes.push(i)
    }

    pagesIndexes.push('...')
    pagesIndexes.push(totalPages)
  }else if(totalPages - currentPage <= pagesCount - 3) {
    pagesIndexes.push(1)

    if(totalPages - pagesCount > 1) {
      pagesIndexes.push('...')
    }

    for(let i = totalPages - (pagesCount - 3); i <= totalPages; i += 1) {
      pagesIndexes.push(i)
    }
  }

  const pagesComponents = pagesIndexes.map((el, i) => {
    if(el === '...') {
      return <PageNumber key={`${i}${el}`} inactive>...</PageNumber>
    }

    return (
      <PageNumber
        key={el}
        onClick={() => onClickOnPage(el)}
        selected={currentPage === el}
      >
        {el}
      </PageNumber>
    )
  })

  return (
    <PagePickerContainer>
      <IconButton
        icon={ArrowIcon}
        disabled={currentPage === 1}
        onClick={onClickPrevPage}
      />
      <Pages>
        {pagesComponents}
      </Pages>
      <IconButton
        icon={innerProps => <ArrowIcon direction="right" {...innerProps} />}
        disabled={currentPage === totalPages}
        onClick={onClickNextPage}
      />
    </PagePickerContainer>
  )
}

const PagePickerContainer = styled.div`
  display: flex;
  align-items: center;
`

const Pages = styled.div`
  margin-left: 35px;
  margin-right: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const PageNumber = styled.div`
  cursor: ${({ inactive }) => inactive ? 'default' : 'pointer'};
  min-width: 20px;
  text-align: center;
  border-radius: 2px;
  padding: 4px;
  ${({ selected }) => selected && `
    color: #fff;
    background-color: #D10029;
    font-weight: bold;
  `}
`

export default PagePicker
