import styled from 'styled-components'

import { colors } from '@constants'

const { navColors } = colors

const AppMenu = styled.nav`
  display: flex;
  align-items: stretch;
  flex: 0 0 60px;
  max-height: 60px;
  box-sizing: border-box;
  background-color: ${navColors.backgroundDefault};
`

export default AppMenu
