import { InputBase, InputBaseProps } from '../InputBase'
import React from 'react'
import styled from 'styled-components'
import { colors } from '@constants'

const { inputColors } = colors

function Input(props: InputBaseProps, ref) {
  return <InputBase {...props} ref={ref}>
    {props => <TextBox {...props as any} />}
  </InputBase>
}

export default React.forwardRef(Input)

const TextBox = styled.input<InputBaseProps>`
  margin: 0;
  padding: 0;
  flex: 1 1 100%;
  height: 40px;
  color: #202020;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  border: none;
  box-sizing : border-box;
  outline: none;

  &:disabled {
    color: #a1a1a1;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px ${inputColors.backgroundDefault} inset !important;
  }
  &:-webkit-autofill:hover {
    box-shadow: 0 0 0 1000px ${inputColors.backgroundDefault} inset !important;
  }
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px ${inputColors.backgroundDefault} inset !important;
  }

  &:disabled:-webkit-autofill {
    box-shadow: 0 0 0 1000px ${inputColors.backgroundDisable} inset !important;
  }
  &:disabled:-webkit-autofill:hover {
    box-shadow: 0 0 0 1000px ${inputColors.backgroundDisable} inset !important;
  }
  &:disabled:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px ${inputColors.backgroundDisable} inset !important;
  }
`
