import React from 'react'

const NoteAddIcon = props => {
  const { size = 32, color = '#202020' } = props

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M18.6666 2.66669H7.99998C6.53331 2.66669 5.34665 3.86669 5.34665 5.33335L5.33331 26.6667C5.33331 28.1334 6.51998 29.3334 7.98665 29.3334H24C25.4666 29.3334 26.6666 28.1334 26.6666 26.6667V10.6667L18.6666 2.66669ZM21.3333 21.3334H17.3333V25.3334H14.6666V21.3334H10.6666V18.6667H14.6666V14.6667H17.3333V18.6667H21.3333V21.3334ZM17.3333 12V4.66669L24.6666 12H17.3333Z"
      />
    </svg>
  )
}

export default NoteAddIcon
