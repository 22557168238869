import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const getContainerBottom = ref => {
  if(!ref.current){return 0}

  const rect = ref.current.getBoundingClientRect()
  return rect.bottom
}

const Tooltip = props => {
  const { children, className, content, align = 'left' } = props

  const containerRef = useRef(null)
  const [bottom, setBottom] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => { setBottom(getContainerBottom(containerRef)) }, [])


  return (
    <Container
      className={className}
      ref={containerRef}
      onMouseOver={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onFocus={() => setIsOpen(true)}
    >
      {children}
      {isOpen &&
        <ContentContainer bottom={bottom} align={align}>
          {content}
        </ContentContainer>
      }
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const ContentContainer = styled.div`
  position: absolute;
  ${({ align }) => align}: 0;
  top: ${({ bottom }) => bottom}px;
  z-index: 9999999;
`

export default Tooltip
