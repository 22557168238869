import { is } from 'ramda'
import React, { useCallback } from 'react'
import styled from 'styled-components'

export default ({ value, onClick, isSelected, ref, ...rest }) => {
  const itemClick = useCallback(
    e => {
      if(is(Function, onClick)) {onClick(value)}

      e.preventDefault()
    }, [value, onClick])

  return <StyledItem onClick={itemClick} isSelected={isSelected} ref={ref} {...rest}>{value}</StyledItem>
}

const StyledItem = styled.div`
  display: block;
  padding: 0 24px;
  white-space: nowrap;
  cursor: pointer;
  z-index: 12;
  line-height: 2.5;

  color: ${props => props.isSelected ? '#fff' : '#202020'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background-color: ${props => props.isSelected ? '#6d6d6d' : 'transparent'};

  &:hover {
    background-color: rgba(0, 0, 0, .08);
  }
`
