import { isEmpty, prop } from 'ramda'
import React, { JSXElementConstructor, ReactNode, useCallback, useMemo, useState } from 'react'
import DefaultInput from './DefaultInput'
import DefaultItem from './DefaultItem'
import DropdownList from '../DropdownList'
import { ArrowIcon } from 'icons'
import styled from 'styled-components'

type TItem = {
  displayName: string
  id: string
  name: string
}

type TMultiAutocomplete<P = TItem> = {
  icon?: ReactNode
  Input?: any
  Item?: any
  caption?: (arg: P) => string
  className?: string
  emptyCaption: string
  error?: boolean
  helperText?: string
  id?: (arg: P) => string
  items: P[]
  label?: string
  onChange: (arg: P) => void
  required?: boolean
  tags: JSX.Element | never[]
}

export type TDefaultItem<P = TItem> = {
  isSelected: boolean
  onClick: (value: P) => void
  value: P
  children: ReactNode
}

const MultiAutocomplete = ({
  icon,
  Input = DefaultInput,
  Item = DefaultItem,
  caption = prop('name'),
  className,
  emptyCaption,
  error,
  helperText,
  id = prop('id'),
  items,
  label = '',
  onChange,
  required,
  tags,
}: TMultiAutocomplete) => {
  const [helperTextHeight, setHelperTextHeight] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [isPopupShown, setIsPopupShown] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const filteredItems = useMemo(() => items.filter(
    item => caption(item).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0,
  ), [items, inputValue, caption])

  const handleHidePopup = useCallback(() => {
    setInputValue('')
    setIsPopupShown(false)
  }, [])

  const handleFocus = useCallback(() => {
    setIsPopupShown(true)
  }, [])

  const selectValue = useCallback(item => {
    onChange(item)
    setIsPopupShown(false)
  }, [onChange])

  const keyPress = useCallback(event => {
    if(![38, 40, 13].includes(event.keyCode)){return}

    event.preventDefault()

    if(event.keyCode === 13 && selectedIndex >= 0) {
      selectValue(filteredItems[selectedIndex])
      setInputValue('')
      return
    }

    if(event.keyCode === 38) {
      setSelectedIndex(
        (selectedIndex - 1 < 0 ? filteredItems.length - 1 : selectedIndex - 1) % filteredItems.length,
      )
    }else if(event.keyCode === 40) {
      setSelectedIndex((selectedIndex + 1) % filteredItems.length)
    }
  }, [setSelectedIndex, filteredItems, selectedIndex, selectValue])

  const autocompleteList = <ScrollContainer>
    {isEmpty(filteredItems) && <Empty>{emptyCaption}</Empty>}
    {filteredItems.map(item => {
      const isSelected = selectedIndex >= 0 && id(item) === id(filteredItems[selectedIndex])

      return (
        <Item
          isSelected={isSelected}
          key={id(item)}
          onClick={selectValue}
          value={item}
        >
          {caption(item)}
        </Item>
      )
    })}
  </ScrollContainer>

  const popupOffset = useMemo(() => helperText ? [0, -helperTextHeight] : null, [helperText, helperTextHeight])

  return (
    <DropdownList
      className={className}
      isPopupOpen={isPopupShown}
      listChildren={autocompleteList}
      onHidePopup={handleHidePopup}
      popupOffset={popupOffset}
    >
      <Input
        error={error}
        helperText={helperText}
        icon={icon || <ArrowIcon size={14} direction={isPopupShown ? 'top' : 'bottom'} />}
        isPopupOpen={isPopupShown}
        label={!inputValue && isEmpty(tags) && label}
        onChange={setInputValue}
        onFocus={handleFocus}
        onKeyDown={keyPress}
        required={required}
        updateHelperTextHeight={setHelperTextHeight}
      >{tags}</Input>
    </DropdownList>
  )
}

export default MultiAutocomplete

const Empty = styled.div`
  color: grey;
  display: block;
  padding: 10px 20px;
  white-space: nowrap;
  z-index: 12;
`

const ScrollContainer = styled.div`
  max-height: 150px;
  width: auto;
`
