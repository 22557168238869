import React from 'react'

const NewMapIcon = props => {
  const {size = 24, color = '#757575'} = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9949 9.58619V17.54C20.9949 18.4 20.4449 19.17 19.6349 19.44L15.6449 20.78C15.2249 20.92 14.7649 20.92 14.3449 20.77L9.34488 19.02C9.12488 18.94 8.87488 18.94 8.65488 19.03L5.72488 20.17C4.41488 20.68 3.00488 19.72 3.00488 18.31V6.45999C3.00488 5.59999 3.55488 4.83999 4.36488 4.55999L8.35488 3.21999C8.77488 3.07999 9.23488 3.07999 9.65488 3.22999L14.006 4.75288C14.002 4.83475 14 4.91714 14 5C14 5.71702 14.1509 6.39879 14.4227 7.01528L9.00488 5.10999V16.78L15.0049 18.89V8.00698C15.9174 9.21745 17.3673 10 19 10C19.7091 10 20.3838 9.85238 20.9949 9.58619Z"
        fill={color} />
      <circle cx="19" cy="5" r="3" fill="#EF3737" />
    </svg>
  )
}

export default NewMapIcon
