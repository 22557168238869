import { useCallback, useEffect, useState } from 'react'

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined'

  const getWindowDimensions = useCallback(() => ({
    width : hasWindow ? window.innerWidth : null,
    height: hasWindow ? window.innerHeight : null,
  }), [hasWindow])

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    if(hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }

    return undefined
  }, [hasWindow, getWindowDimensions])

  return windowDimensions
}
