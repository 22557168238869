import { ModalPortal, ModalPortalProvider } from 'components/ModalPortal'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import Input from '../Input'
import ModalDialog from '../ModalDialog'
import ModalFooterActions from '../ModalFooterActions'
import ModalHeader from '../ModalHeader'
import Spinner from '../Spinner'
import ApproveButton from './ApproveButton'

const secondsToMinSec = seconds => new Date(seconds * 1000).toISOString().slice(14, 19)
const onlyDigits = value=> value.replace(/\D/g, '')

type TProps = {
  commonTranslates: {
    approve: string
    confirmationCode: string
    sendCode: string
    sendNewCode: string
  }

  closeHandler: () => void
  getConfirm: (arg: number) => void
  getRequestId: () => void
  isCloseByClickOutside?: boolean
  loadRequestId?: boolean
  requestId?: string
  requestRetryTimeout: number
  showModal?: boolean
  smsConfirmError?: string
  smsConfirmIsLoading: boolean

  texts: {
    text: string
    title: string
  }
}

const CodeConfirm = ({
  commonTranslates,
  closeHandler,
  getConfirm,
  getRequestId,
  isCloseByClickOutside,
  loadRequestId,
  requestId,
  requestRetryTimeout,
  showModal,
  smsConfirmError,
  smsConfirmIsLoading,
  texts,
}: TProps) => {
  const [sessionLifetime, setSessionLifetime] = useState(0)
  const [code, setCode] = useState('')
  const inputRef = useRef(null)

  const getRequestIdHandler = useCallback(() => {
    setCode('')
    getRequestId()
  }, [getRequestId])

  const handleGetConfirm = useCallback(() => {
    getConfirm(Number(code))
  }, [code, getConfirm])

  useEffect(() => {
    // Очистка инпута при получении нового requestId
    setCode('')

    let counter = requestRetryTimeout
    let timer

    if(requestId) {
      setSessionLifetime(requestRetryTimeout)
      timer = setInterval(() => { counter > 0 && setSessionLifetime(counter-=1) }, 1000)
    }

    return () => {
      timer && clearInterval(timer)
    }
  }, [requestId])

  useEffect(() => {
    if(requestId && inputRef.current) {
      inputRef.current.focus()
    }
  }, [requestId])

  useEffect(() => {
    //сброс состояния модального окна при закрытии
    if(!showModal) {
      setCode('')
      setSessionLifetime(0)
    }
  }, [showModal])

  return <ModalPortalProvider>
    <ModalPortal
      id='modal-confirm'
      isCloseByClickOutside={isCloseByClickOutside}
      onClose={closeHandler}
      showModal={showModal}
      type='modal'
    >
      <StyledModalDialog>
        <ModalHeader onClose={closeHandler}>
          <ModalHeaderTitle>
            {texts.title}
          </ModalHeaderTitle>
        </ModalHeader>

        <ModalContentWrapper>
          <TextContainer>
            {texts.text}
          </TextContainer>

          {requestId && <NumericInputWrapper>
            <Input
              error={Boolean(smsConfirmError)}
              helperText={smsConfirmError}
              label={commonTranslates.confirmationCode}
              name="Sms"
              onChange={setCode}
              ref={inputRef}
              value={code}
              postProcessValue={onlyDigits}
            />
          </NumericInputWrapper>}

          <StyledFooter>
            <ModalFooterActions>
              {requestId && sessionLifetime > 0 && <CountDownWrapper>
                <span>{`${commonTranslates.sendNewCode}:`}</span><time>{secondsToMinSec(sessionLifetime)}</time>
              </CountDownWrapper>}

              {requestId && sessionLifetime === 0 && <Button
                onClick={getRequestIdHandler}
                color='#D10029'
                variant='text'
                width='240px'
              >
                {requestId && loadRequestId
                  ? <Spinner color='#D10029' size={18}/>
                  : <ResendButtonText>{commonTranslates.sendNewCode}</ResendButtonText>
                }
              </Button>}

              <ApproveButton
                clickHandle={requestId ? handleGetConfirm : getRequestIdHandler}
                disabled={Boolean(requestId) && !code}
                load={smsConfirmIsLoading || (!requestId && loadRequestId)}
                title={requestId ? commonTranslates.approve : commonTranslates.sendCode}
              />
            </ModalFooterActions>
          </StyledFooter>
        </ModalContentWrapper>
      </StyledModalDialog>
    </ModalPortal>
  </ModalPortalProvider>
}

const CountDownWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 48px !important;
  color: rgba(0, 0, 0, 0.3);

  & > span {
    margin-right: 5px;
    font-size: 16px;
    font-weight: 700;
  }

  & > time {
    font-size: 16px;
    font-weight: 700;
  }
`

const ResendButtonText = styled.span`
  color: #D10029;
  font-size: 16px;
`

const StyledModalDialog = styled(ModalDialog)`
  border-radius: 8px;
  padding: 30px 24px 16px 24px;
`

const NumericInputWrapper = styled.div`
  width: 288px;
`

const ModalContentWrapper = styled.div`
  width: 680px;

  @media (max-width: 600px) {
    max-width: 480px;
  }
`

const ModalHeaderTitle = styled.span`
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: #202020;
  margin-bottom: 28px;
  display: block;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 0;
  }
`

const TextContainer = styled.div`
  margin: 24px 0;
  font-size: 16px;
  font-weight: 400;
`

const StyledFooter = styled(ModalFooterActions)`
  justify-content: right;
`

export default CodeConfirm
