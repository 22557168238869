import styled from 'styled-components'
import { colors, css } from '@constants'

const themes = {
  container: {
    light: `
      background-color: white;
    `,
    dark: `
      background-color: #333333;
      border: 1px solid rgba(255, 255, 255, 0.12);
    `,
  },
  child: {
    light: `
      color: ${colors.PRIMARY_FONT};
      background-color: ${colors.PRIMARY_BACKGROUND};
      :hover{
        background: ${colors.PRIMARY_HOVER};
      }
    `,
    dark: `
      color: ${colors.LIGHT_FONT};
      background-color: ${colors.BACKGROUND_DARK};
      :hover{
        background: ${colors.DARK_HOVER};
      }
    `,
    red: `
      color: ${colors.PRIMARY_FONT};
      background-color: ${colors.PRIMARY_BACKGROUND};
      :hover{
        background: ${colors.SECONDARY_HOVER};
      }
    `,
  },
}

export const PopupContainer = styled.ul`
  ${css.scrollStyleMixin}
  padding: 8px 0;
  border-radius: 2px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.039), 0px 7px 24px rgba(0, 0, 0, 0.19);

  ${({ theme }) => themes.container[theme]};
`

PopupContainer.defaultProps = {
  theme: 'light',
}

export const PopupChild = styled.li`
  cursor: pointer;
  list-style-type: none;
  padding: 8px 24px;
  border-top: ${({ borderTop }) => borderTop && '1px solid #BDBDBD'};
  border-bottom: ${({ borderBottom }) => borderBottom && '1px solid #BDBDBD'};

  ${({ theme }) => themes.child[theme]};
`

PopupChild.defaultProps = {
  theme: 'light',
}
