import React from 'react'

const WarningDeleteIcon = props => {
  const { size = 56, color = 'white', className } = props
  return (
    <svg
      className={className}
      width={size}
      height={size}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 56 56"
      fill="none"
    >
      <rect width="56" height="56" rx="28" fill="#D10029" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8333 17.5C26.189 17.5 25.6666 18.0223 25.6666 18.6667H19.8333C19.189 18.6667 18.6666 19.189 18.6666 19.8333C18.6666 20.4777 19.189 21 19.8333 21H36.1666C36.811 21 37.3333 20.4777 37.3333 19.8333C37.3333 19.189 36.811 18.6667 36.1666 18.6667H30.3333C30.3333 18.0223 29.811 17.5 29.1666 17.5H26.8333ZM19.8333 22.1667H36.1666L35.0773 37.4164C35.0338 38.027 34.5257 38.5 33.9137 38.5H22.0863C21.4742 38.5 20.9662 38.027 20.9226 37.4164L19.8333 22.1667Z"
        fill={color}
      />
    </svg>
  )
}

export default WarningDeleteIcon
