import React from 'react'

const CalendarSearchIcon = props => {
  const { size = 20, color = '#000000' } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.5 12C16.3048 12 17.0949 12.2157 17.788 12.6249C18.481 13.034 19.0517 13.6215 19.4405 14.3262C19.8293 15.0308 20.022 15.8269 19.9985 16.6313C19.975 17.4358 19.7362 18.2193 19.307 18.9L22.391 21.984L20.984 23.391L17.876 20.322C17.2845 20.6896 16.6158 20.9149 15.9225 20.9802C15.2292 21.0455 14.5302 20.949 13.8805 20.6983C13.2308 20.4475 12.6482 20.0495 12.1784 19.5353C11.7087 19.0212 11.3647 18.4051 11.1736 17.7355C10.9824 17.0658 10.9492 16.361 11.0767 15.6764C11.2041 14.9917 11.4888 14.346 11.9081 13.7901C12.3275 13.2341 12.8702 12.7831 13.4935 12.4725C14.1167 12.1619 14.8036 12.0001 15.5 12ZM15.5 14C14.837 14 14.2011 14.2634 13.7322 14.7323C13.2634 15.2011 13 15.837 13 16.5C13 17.1631 13.2634 17.799 13.7322 18.2678C14.2011 18.7367 14.837 19 15.5 19C16.163 19 16.7989 18.7367 17.2678 18.2678C17.7366 17.799 18 17.1631 18 16.5C18 15.837 17.7366 15.2011 17.2678 14.7323C16.7989 14.2634 16.163 14 15.5 14ZM19 7.99805H5V18.998H9.497C9.80641 19.7399 10.2506 20.418 10.807 20.998H5C4.46957 20.998 3.96086 20.7873 3.58579 20.4123C3.21071 20.0372 3 19.5285 3 18.998L3.01 4.99805C3.01 3.89305 3.894 2.99805 5 2.99805H6V0.998047H8V2.99805H16V0.998047H18V2.99805H19C19.5304 2.99805 20.0391 3.20876 20.4142 3.58383C20.7893 3.95891 21 4.46761 21 4.99805V13.033C20.4887 12.224 19.806 11.5372 19 11.021V7.99805Z" />
    </svg>

  )
}

export default CalendarSearchIcon
