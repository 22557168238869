import React from 'react'

const AvatarPlaceholder = ({ size = 24 }) =>
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#BDBDBD"/>
    <path d="M12.2444 11.7248C12.9095 11.7248 13.5595 11.5276 14.1125 11.1581C14.6654 10.7887 15.0964 10.2635 15.3509 9.64913C15.6054 9.03473 15.672 8.35866 15.5422 7.70642C15.4125 7.05418 15.0923 6.45506 14.622 5.98482C14.1518 5.51458 13.5527 5.19435 12.9004 5.06461C12.2482 4.93487 11.5721 5.00146 10.9577 5.25595C10.3433 5.51044 9.81818 5.94141 9.44872 6.49435C9.07925 7.04729 8.88205 7.69738 8.88205 8.36239C8.88205 9.25416 9.2363 10.1094 9.86687 10.74C10.4974 11.3705 11.3527 11.7248 12.2444 11.7248ZM12.2444 12.6855C10.1604 12.6855 6 13.9728 6 16.5282V18.4496H18.4889V16.5282C18.4889 13.9728 14.3285 12.6855 12.2444 12.6855Z" fill="white"/>
  </svg>


export default AvatarPlaceholder
