import React from 'react'

const ControlIcon = ({ size = 24, color = '#ffffff' }) => <svg
  width={size}
  height={size}
  viewBox='0 0 22 22'
>
  <g>
    <path
      fillRule="evenodd"
      d="M19.1593 12.98C19.1993 12.66 19.2293 12.34 19.2293 12C19.2293 11.66 19.1993 11.34 19.1593 11.02L21.2693 9.37C21.4593 9.22 21.5093 8.95 21.3893 8.73L19.3893 5.27C19.2693 5.05 18.9993 4.97 18.7793 5.05L16.2893 6.05C15.7693 5.65 15.2093 5.32 14.5993 5.07L14.2193 2.42C14.1893 2.18 13.9793 2 13.7293 2H9.72933C9.47933 2 9.26933 2.18 9.23933 2.42L8.85933 5.07C8.24933 5.32 7.68933 5.66 7.16933 6.05L4.67933 5.05C4.44933 4.96 4.18933 5.05 4.06933 5.27L2.06933 8.73C1.93933 8.95 1.99933 9.22 2.18933 9.37L4.29933 11.02C4.25933 11.34 4.22933 11.67 4.22933 12C4.22933 12.33 4.25933 12.66 4.29933 12.98L2.18933 14.63C1.99933 14.78 1.94933 15.05 2.06933 15.27L4.06933 18.73C4.18933 18.95 4.45933 19.03 4.67933 18.95L7.16933 17.95C7.68933 18.35 8.24933 18.68 8.85933 18.93L9.23933 21.58C9.26933 21.82 9.47933 22 9.72933 22H13.7293C13.9793 22 14.1893 21.82 14.2193 21.58L14.5993 18.93C15.2093 18.68 15.7693 18.34 16.2893 17.95L18.7793 18.95C19.0093 19.04 19.2693 18.95 19.3893 18.73L21.3893 15.27C21.5093 15.05 21.4593 14.78 21.2693 14.63L19.1593 12.98ZM12.7271 15V17H10.7271V15H12.7271ZM10.7271 12C10.7271 12.55 11.1771 13 11.7271 13C12.2771 13 12.7271 12.55 12.7271 12V8C12.7271 7.45 12.2771 7 11.7271 7C11.1771 7 10.7271 7.45 10.7271 8V12Z"
      fill={color}/>
  </g>
</svg>

export default ControlIcon
