import { InputClearIcon, SearchIcon } from 'icons'
import { is } from 'ramda'
import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import InputControlContainer from '../InputControlContainer'

const hasValue = inputValue => {
  return !(inputValue == null || inputValue === '')
}

const Search = props => {
  const {
    value,
    filter,
    postProcessValue,
    onChange,
    onKeyPress,
    onEnter,
    onFocus,
    icon,
    disableBorder,
    className,
    ...rest
  } = props

  const inputRef = useRef()
  const [focused, setFocused] = useState(false)

  const onChangeHandler = useCallback(event => {
    let { value } = event.target

    if(is(Function, filter)) {
      const filteredValue = filter(value)

      if(filteredValue !== value) {
        if(!Number.isNaN(filteredValue)) {value = filteredValue}
        else{value = ''}

        inputRef.current.value = value
      }
    }

    if(is(Function, onChange)) {
      const resValue = is(Function, postProcessValue) ? postProcessValue(value) : value
      onChange(resValue)
    }
  }, [filter, onChange, postProcessValue])

  const onKeyHandler = useCallback(event => {
    if(event.key === 'Enter' && is(Function, onEnter)) {
      event.preventDefault()
      onEnter()
    }

    if(is(Function, onKeyPress)) {onKeyPress(event)}
  }, [onEnter, onKeyPress])

  const handleFocus = () => {
    setFocused(true)

    if(onFocus) {onFocus()}
  }

  const handleClear = () => {
    if(is(Function, onChange)) {onChange('')}
  }

  return (
    <InputControlContainer
      className={className}
      onClick={() => inputRef.current.focus()}
      focused={hasValue(value) || focused}
      disableBorder={disableBorder}
      icon={icon || value && <ClearButton onClick={handleClear}><ClearIcon /></ClearButton>}
    >
      <SearchIconStyled />
      <TextBox
        ref={inputRef}
        value={value}
        type="text"
        onChange={onChangeHandler}
        onKeyPress={onKeyHandler}
        onFocus={handleFocus}
        onBlur={() => setFocused(false)}
        {...rest}
      />
    </InputControlContainer>
  )
}

export default Search

const TextBox = styled.input`
  margin: 0 0 0 10px;
  padding: 0;
  flex: 1 1 100%;
  height: 40px;
  color: #202020;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  border: none;
  box-sizing : border-box;
  outline: none;
  width: 100%;

  &::placeholder {
    color: #A1A1A1;
  }
`

const ClearIcon = styled(InputClearIcon).attrs({ color: '#a1a1a1' })``
const SearchIconStyled = styled(SearchIcon).attrs({ color: '#a1a1a1' })``

const ClearButton = styled.button`
  /* Растягиваем кнопку на всю высоту и длину контейнера, в который он помещается */
  height: 100%;
  width: 100%;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
`
