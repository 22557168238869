import React, { Fragment } from 'react'
import styled from 'styled-components'

type TSwitch = {
  disabled?: boolean
  isChecked?: boolean
  onChangeHandler: () => void
}

const Switch = ({ disabled, isChecked = false, onChangeHandler }: TSwitch) => <Fragment>
  <StyledInput type="checkbox" />
  <StyledLabel checked={isChecked} disabled={disabled} onClick={disabled ? null : onChangeHandler}/>
</Fragment>

const StyledInput = styled.input`
  display: none;
  position: absolute;
`

const StyledLabel = styled.label<{checked: boolean, disabled?: boolean, onClick: () => void}>`
  position: relative;
  display: block;
  width: 32px;
  height: 14px;
  cursor: ${({ disabled }) => disabled ? 'unset' : 'pointer'};
  transition: 350ms;
  padding: 0 14px;

  &::after {
    position: absolute;
    content:'';
    width: 20px;
    height: 20px;
    top: -3px;
    left: ${({ checked }) => checked ? '27px' : '10px'};
    border-radius: 50%;
    transition: 250ms ease-in-out;

    background: ${({ checked, disabled }) => {
    if(checked && disabled) {
      return '#e793a4'
    }

    if(checked){
      return '#D10029'
    }

    return '#fff'
  }};

    box-shadow: 0 0.25px 1px rgba(0, 0, 0, 0.039), 0 0.85px 3px rgba(0, 0, 0, 0.19);
  }

  &::before {
    position: absolute;
    content:'';
    width: 32px;
    height: 14px;
    top: 0;
    left: 13px;
    border-radius: 7px;
    transition: 250ms ease-in-out;

    background: ${({ checked, disabled }) => {
    if(checked && disabled) {
      return 'rgba(209, 0, 41, .24)'
    }

    if(checked){
      return 'rgba(209, 0, 41, .54)'
    }

    if(disabled){
      return 'rgba(0, 0, 0, .12)'
    }

    return 'rgba(0, 0, 0, .32)'
  }};
  }
`

export default Switch

