import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { CheckboxIcon, DragIcon, SettingsIcon } from '../../icons'
import Button from '../Button'
import Popup from '../Popup'

const transformOrigin = { horizontal: 'left', vertical: 'top' }

const DragIconContainer = styled.span`
  padding-right: 20px;
`

const placeholderStyle = css`
    border: black dotted 1px;
    line-height: 32px;
    width: 100%;
    display: inline-block;
    height: 48px;
`

export default function TableSettings({ toggleDisplayColumn, columns, reorderColumn }) {
  const [isPopupShown, setIsPopupShown] = useState(false)
  const [dragged, setDragged] = useState(null)
  const [targetColumn, setTargetColumn] = useState(null)
  const [targetPosition, setTargetPosition] = useState(null)
  const popupAnchor = useRef()

  const placeholder = useMemo(() => {
    const placeHolderLi = document.createElement('li');
    [placeHolderLi.style.cssText] = placeholderStyle
    placeHolderLi.className = 'placeholder'
    return placeHolderLi
  }, [])


  const togglePopup = useCallback(
    () => setIsPopupShown(!isPopupShown),
    [setIsPopupShown, isPopupShown],
  )

  const dragStart = useCallback((e, column) => {
    setDragged(e.target)
    setTargetColumn(column)
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target)
  }, [setDragged])

  const dragEnd = useCallback(() => {
    dragged.style.display = 'block'
    dragged.parentNode.replaceChild(dragged, placeholder)

    if(targetPosition !== null) {reorderColumn(targetPosition, targetColumn)}

    setTargetColumn(null)
    setTargetPosition(null)
  },

  [dragged, placeholder, targetPosition, reorderColumn, targetColumn])

  const dragOver = useCallback(e => {
    e.preventDefault()

    // Target should be li not included elements
    const target = e.target.tagName !== 'LI' ? e.target.parentNode : e.target
    const list = target.parentNode

    // do nothink if over placeholder
    if(target === placeholder || target.tagName !== 'LI'){return}

    dragged.style.display = 'none'

    // Get height of first visible element for calculate target position (all must be same hight)
    const firstVisible = [...list.children]
      .find(el => el.offsetParent !== null && el !== placeholder)

    if(!firstVisible){return}

    const { height } = firstVisible.getBoundingClientRect()
    const pos = (e.clientY - list.getBoundingClientRect().top) / height
    const roundPos = Math.round(pos)

    // If placeholder get in rounded count items subtract it from target postion
    setTargetPosition(
      roundPos - ([...list.children].slice(0, roundPos).includes(placeholder) ? 1 : 0),
    )

    // Border goes by middle of element, if it crossed insert placeholder after element
    if(roundPos - Math.floor(pos)) {
      target.insertAdjacentElement('afterend', placeholder)
    }else{
      list.insertBefore(placeholder, target)
    }
  }, [placeholder, dragged])

  const onItemClick = useCallback((event, column) => {
    event.preventDefault()
    event.stopPropagation()

    if(column.isRequired){return}

    toggleDisplayColumn(column)
  }, [toggleDisplayColumn])

  return (
    <>
      <PopupPositionAnchor ref={popupAnchor} />
      <Button variant="icon" onClick={togglePopup}><SettingsIcon key="settings" /></Button>

      <Popup
        key="popup"
        isShown={isPopupShown}
        onClickOutside={togglePopup}
        anchorElement
        dropDirection="right"
        anchorEl={popupAnchor.current}
        transformOrigin={transformOrigin}
        backgroundColor="transparent"
        border="0"
        maxHeight="400px"
        overflowY="hidden"
        boxShadow="none"
      >
        <Arrow />
        <PopupPane>
          <List onDragOver={dragOver}>
            {columns.map(column =>
              <Item
                draggable="true"
                key={column.id}
                onClick={event => onItemClick(event, column)}
                onDragStart={event => dragStart(event, column)}
                onDragEnd={dragEnd}
              >
                <DragIconContainer><DragIcon /></DragIconContainer>
                <Checkbox
                  type="checkbox"
                  checked={column.isDisplayed || column.isRequired}
                  active={!column.isRequired}
                />
                <Caption>{column.caption}</Caption>
              </Item>,
            )}
          </List>
        </PopupPane>
      </Popup>
    </>
  )
}

const PopupPositionAnchor = styled.div`
  position:relative;
  top: -55px;
  left: 30px;
`

const List = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  margin: 0;
`

const Item = styled.li`
  scroll-snap-align: start;
  padding-right: 20px;
  cursor: grab;

  :active {
    cursor: grabbing;
  }

  line-height: 48px;
  :hover {
    background: #F5F5F5;
  }
`

const Caption = styled.span`
  display: inline;
  vertical-align: super;
  font-size: 12px;
  font-weight: normal;
  margin-left: 7px;
`

const Checkbox = styled(CheckboxIcon)`
  display: inline;
  vertical-align: super;
  margin-left: 16px;
  position: relative;
  top: 3px;
`

const PopupPane = styled.div`
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  max-height: 370px;
  min-width: 250px;
  position: relative;
  margin: 20px 40px 40px 10px;
  padding: 0.3em 1em 0.3em 0.3em;
  box-sizing: border-box;
  background: #fff;
  border-top: 14px solid white;
  border-bottom: 14px solid white;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.3);
`

const Arrow = styled.div`
  position: absolute;
  top: 45px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right:10px solid white;
  z-index: 11;
`
