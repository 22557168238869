import React from 'react'
import styled, { keyframes } from 'styled-components'

const CircleSpinner = ({ size = 30, color = '#D10029', className = undefined }) => {
  return <Wrapper size={size} color={color} className={className} />
}

const rotate = keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
`

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border: 2px solid ${props => props.color};
    border-right-color: transparent;
    border-radius: 50%;
    animation: ${rotate} 0.5s linear infinite;
`

export default CircleSpinner
