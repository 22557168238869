import React, {
  ButtonHTMLAttributes, PropsWithChildren,
  ReactChild,
  useCallback,
  useState,
} from 'react'

import styled, { css } from 'styled-components'
import Spinner from 'components/Spinner'
import { buttonBlackColor, buttonRedColor } from 'constants/colors'

const colorsPrimaryBtn = {
  default: { // Red
    backgroundColor     : '#D10029',
    backgroundColorHover: '#ea0e0e',
    borderColor         : '#D10029',
  },
  transparent: {
    backgroundColor     : 'transparent',
    backgroundColorHover: 'transparent',
  },
}

const selectButtonContainer = variant => {
  switch(variant) {
    case'grey': return GreyButtonContainer
    case'primary': return PrimaryButtonContainer
    case'border': return BorderButtonContainer
    case'round': return RoundButtonContainer
    case'icon': return IconButtonContainer
    case'text': return TextButtonContainer
    case'navigation': return NavigationButtonContainer
    default: return PrimaryButtonContainer
  }
}

type TButtonState = {
  pressed: boolean
  hover: boolean
}

interface TButtonProps extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  variant?: 'grey' | 'primary' | 'border' | 'round' | 'icon' | 'text' | 'navigation' | undefined
  isLoading?: Boolean
  width?: string
  height?: string
  disabled?: boolean
  isPressed?: boolean
  noPadding?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, TButtonProps>((props, ref) => {
  const {
    className,
    children,
    variant = 'primary',
    disabled = false,
    isLoading = false,
    color = 'default',
    onClick,
    width,
    ...rest
  } = props

  const [isPressed, setIsPressed] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const onMouseDown = useCallback(() => {
    setIsPressed(true)
  }, [setIsPressed])

  const onMouseEnter = useCallback(() => {
    setIsHover(true)
  }, [setIsHover])

  const onMouseUp = useCallback(() => {
    setIsPressed(false)
  }, [setIsPressed])

  const onMouseLeave = useCallback(() => {
    setIsPressed(false)
    setIsHover(false)
  }, [setIsPressed, setIsHover])

  const handleClick = useCallback(event => {
    event.preventDefault()

    if(!disabled && !isLoading && onClick instanceof Function) {onClick(event)}
  }, [onClick, disabled, isLoading])

  const ButtonContainer = selectButtonContainer(variant)

  const child =
    ['icon', 'text', 'navigation'].includes(variant) && children instanceof Function
      ? (children as Function)({ pressed: isPressed, hover: isHover, disabled })
      : children

  return (
    <ButtonContainer
      className={className}
      type="button"

      // @ts-ignore
      disabled={disabled || isLoading}

      color={color}
      width={width}
      onClick={handleClick}
      isPressed={isPressed}
      isHover={isHover}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      ref={ref}
      {...rest}
    >
      {isLoading ?
        <Spinner size={16} color="blue" />
        : child}
    </ButtonContainer>
  )
})

const BaseButton = styled.button<TButtonProps>`
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  font-size: 16px;
  width: ${({ width }) => width || '100%'};
  min-height: 48px;
  font-weight: 600;
  transition: .2s all ease-in-out;
  border: none;
  border-radius: 2px;

  ${({ disabled }) => disabled ? css`pointer-events: none;` : css`cursor: pointer;`};

  :focus {
    outline: none;
  }
`

const GreyButtonContainer = styled(BaseButton)`
  background-color: #747474;
  color: #fff;
  padding: 8px;
  min-height: 0;
`

const PrimaryButtonContainer = styled(BaseButton)`
  padding: ${({ noPadding }) => noPadding ? '0' : '13px 14px'};
  color: ${({ disabled }) => disabled ? 'rgba(32, 32, 32, 0.2)' : '#fff'};
  background-color: ${({ color }) => colorsPrimaryBtn[color].backgroundColor};
  border: 1px solid ${colorsPrimaryBtn.default.borderColor};

  &:not(:disabled):hover {
    background-color: ${({ color }) => colorsPrimaryBtn[color].backgroundColorHover};
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.12);
    border-color: transparent;
  }
`

const BorderButtonContainer = styled(BaseButton)`
  padding: ${({ noPadding }) => noPadding ? '0' : '13px 14px'};
  color: ${({ disabled }) => disabled ? 'rgba(32, 32, 32, 0.2)' : '#202020'};
  background-color: transparent;
  border: 1px solid #C9CCD4;

  &:not(:active):not(:disabled):hover {
    border-color: #202020;
  }

 &:active {
  background-color: rgba(209, 0, 41, 0.12);
  border-color: #D10029;
 }

  &:disabled {
    color: rgba(32, 32, 32, 0.2);
    background-color: rgba(0, 0, 0, 0.04);
  }
`

const TextButtonContainer = styled(BaseButton)`
  padding: ${({ noPadding }) => noPadding ? '0' : '13px 14px'};
  color: ${({ disabled }) => disabled ? 'rgba(32, 32, 32, 0.2)' : '#202020'};
  background-color: transparent;
  border: none;

  &:not(:active):not(:disabled):hover {
    background-color: rgba(0, 0, 0, .12);
  }

 &:active {
  background-color: rgba(0, 0, 0, .2);
 }

  &:disabled {
    color: #000000;
    opacity: .3;
  }
`

const NavigationButtonContainer = styled(TextButtonContainer)<{disabled: boolean, isHover: boolean}>`
  color: ${({ disabled, isHover }) => disabled ? 'rgba(32, 32, 32, 0.2)' : (isHover ? buttonRedColor : buttonBlackColor)};
  background-color: transparent;

  &:not(:active):not(:disabled):hover {
    background: transparent;
  }

 &:active {
   color: rgba(0, 0, 0, .2);
   background-color: transparent;
 }

  &:disabled {
    color: #000000;
    opacity: .3;
    background-color: transparent;
  }
`

const RoundButtonContainer = styled.button`
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 10px;
  overflow: hidden;
  outline: none;
  font-size: 1.5rem;
  text-align: center;
  background-color: ${({ color }) => colorsPrimaryBtn[color].backgroundColor};
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  border-radius: 50%;

  &:not(:disabled):hover {
    cursor: pointer;
    background-color: ${({ color }) => colorsPrimaryBtn[color].backgroundColorHover};
  }

  &:disabled {
    background-color: #e0e0e0;
  }

  &:disabled > svg {
    fill: #999;
  }
`

const IconButtonContainer = styled(RoundButtonContainer)<TButtonProps>`
  width: ${({ width }) => width || '30px' };
  height: ${({ height }) => height || '30px' };
  padding: 0;

  background-color: ${({ isPressed }) => isPressed ? 'rgba(209, 0, 41, 0.08)' : 'transparent'};

  &:not(:disabled):hover {
    cursor: pointer;
    background-color: ${({ isPressed }) => isPressed ? 'rgba(209, 0, 41, 0.08)' : 'rgba(0, 0, 0, 0.12)'};
  }

  &:disabled {
    background-color: transparent;
  }

  & * {
    transition: fill 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`

export default Button
