// Основной
export const PRIMARY = '#323232'

/*
 Основной на тёмном фоне
 */
export const INVERSE = '#FFFFFF'
export const SECONDARY = '#CCCCCC' // Второстеменный текст
export const ACTIVE = '#BA0C2F' // Активное состояние, ссылка
export const DISABLED = 'rgba(0, 0, 0, 0.1)' // Не активный выключен
export const INVERSE_DISABLED = 'rgba(255, 255, 255, 0.3)' // Не активный выключен
export const NORMAL = '#32B4FF' // Текст, сообщающий онормальном состоянии
export const SUCCESS = '#22C38E' // Текст, сообщающий об успешном состоянии
export const WARNING = '#F8B000' // Текст, требующий и привлекающий внимание
export const ALERT = '#EF3737' // Текст сообщающий о состоянии ошибки
