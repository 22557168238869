import { StyledMenuTippy } from "components/UserMenu/Popover";
import React, { FC, useCallback, useRef } from "react";
import styled from "styled-components";
import { Instance } from "tippy.js";
import AppMenuNavItem from "../AppMenuNavItem";
import { MainMenuItemType } from ".";
import { SubItem } from "./SubItem";

type Props = {
  item: MainMenuItemType;
  t: (key: string) => string;
  onNavigate: (link: string) => void;
  testId?: string;
};

export const MenuTippy: FC<Props> = ({ item, t, onNavigate, testId }) => {
  const instanceRef = useRef<Instance | null>(null);

  const onTriggerTippy = useCallback((instance: Instance) => {
    instanceRef.current = instance;
  }, []);

  const onClick = useCallback(
    (link: string) => {
      if (instanceRef.current) {
        instanceRef.current.hide();
      }

      onNavigate(link);
    },
    [onNavigate]
  );

  return (
    <StyledMenuTippy
      placement="bottom-start"
      interactive
      content={
        <List>
          {item.items.map((element) => (
            <SubItem
              key={element.link}
              item={element}
              t={t}
              onNavigate={onClick}
            />
          ))}
        </List>
      }
      trigger="click"
      onTrigger={onTriggerTippy}
      data-testid={testId}
    >
      <AppMenuNavItem
        label={t(item.label)}
        icon={item.icon}
        active={item.active}
        value={item}
        isOnClickSetted
        testId={testId}
      />
    </StyledMenuTippy>
  );
};

const List = styled.ul`
  width: 352px;
  background-color: #fff;
  color: #111;
  padding: 0;
`;
