import React, { useCallback } from 'react'
import styled from 'styled-components'


export default function SidebarNavItem({ item, inActive, onNavigate }) {
  const click = useCallback(e => {
    e.preventDefault()
    onNavigate(item)
  }, [onNavigate, item])

  return (
    <StyledWrapper inActive={inActive}>
      <LinkStyled onClick={click}>
        <LabelStyled>
          <FontStyled>{item.name}</FontStyled>
        </LabelStyled>
      </LinkStyled>
    </StyledWrapper>
  )
}


const StyledWrapper = styled.div`
  margin: 4px 8px 0 8px;
  padding: 0 12px;
  height: auto;
  padding-bottom: 5px;
  background-color: ${({ inActive }) => inActive ? '#202020' : 'transparent'};
  border-radius: 18px;

  transition: 0.3s all ease-in-out;

  :hover {
    background-color: #202020;
  }
`

const LabelStyled = styled.span`
  align-items: center;
  max-width: 160px;
  overflow: hidden;
  width: 100%;
  transition: 0.3s all ease-in-out;
  margin-left: 7px;
`

const FontStyled = styled.div`
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 2px;
  line-height: 16px;
  z-index: 1;
`

const LinkStyled = styled.a`
  height: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  z-index: 2;
  padding-top: 8px;
`
