import React from 'react'
import styled from 'styled-components'
import Tag, { TTagProps } from '../Tag'

const noop = () => {}

function ScrollingTag<P = any>({ closeIconColor="#000000", onClose = noop, title, value, onClick = noop }: TTagProps<P>) {
  const ref = React.useRef<HTMLDivElement>()

  React.useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }, [])

  return (
    <div ref={ref}>
      <TagStyled
        closeIconColor={closeIconColor}
        onClick={onClick}
        onClose={onClose}
        title={title}
        value={value}
      />
    </div>
  )
}

export default ScrollingTag

const TagStyled = styled(Tag)<TTagProps>`
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  color: black;
  margin: 0 15px 0 0;
`
