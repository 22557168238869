import React from 'react'

const InputClearIcon = ({ color = '#202020', size = '20px' }) =>
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5355 5.63648C11.9259 5.24597 11.9259 4.6128 11.5355 4.22229C11.1449 3.83173 10.5118 3.83173 10.1212 4.22229L7.99988 6.34362L5.87854 4.22229C5.48816 3.83179 4.85486 3.83179 4.46447 4.22229C4.07385 4.61285 4.07385 5.24597 4.46448 5.63654L6.58581 7.75782L4.46448 9.87915C4.07385 10.2697 4.07385 10.9028 4.46448 11.2933C4.85486 11.6838 5.48816 11.6838 5.87854 11.2933L7.99988 9.172L10.1212 11.2934C10.5118 11.6839 11.1449 11.6839 11.5355 11.2934C11.9259 10.9028 11.9259 10.2697 11.5355 9.87915L9.41418 7.75782L11.5355 5.63648Z"
    />
  </svg>

export default InputClearIcon
