import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import PagePicker from './PagePicker'
import RowsPerPage from './RowsPerPage'

const Pagination = props => {
  const {
    currentPage,
    initialRowsPerPage = 10,
    ofLabel = 'из',
    onChangePage,
    onChangeRowsPerPage,
    pickerLength = 9,
    rowsPerPageLabel = 'Строк на странице',
    totalPages,
    rowsPerPageSelectorList = [
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '30', value: 30 },
      { label: '40', value: 40 },
      { label: '50', value: 50 },
    ],
  } = props

  return (
    <Container>
      <CurrentPageInfo>
        {`${currentPage} ${ofLabel} ${totalPages}`}
      </CurrentPageInfo>
      <PagePicker
        currentPage={currentPage}
        onChangePage={onChangePage}
        pickerLength={pickerLength}
        totalPages={totalPages}
      />
      <RowsPerPage
        pages={rowsPerPageSelectorList}
        initialRowsPerPage={initialRowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPageLabel={rowsPerPageLabel}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`

const CurrentPageInfo = styled.div`
  white-space: nowrap;
`

export default Pagination
