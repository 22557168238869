import React from 'react'
import styled, { css } from 'styled-components'

import { CloseIcon } from 'icons'

import { colors } from '@constants'

const {
  PRIMARY_FONT,
  BACKGROUND_LIGHT,
  PRIMARY_HOVER,
  PRIMARY_OUTLINE,
  SECONDARY_HOVER,
  PRIMARY_IMPORTANT,
} = colors

const TabItem = ({ tab, active, onSelect, onClose, ...props }) => {
  const handleClose = e => {
    e.stopPropagation()
    onClose(tab)
  }

  return (
    <Container active={active} onClick={() => onSelect(tab)} {...props}>
      {tab.label}
      {onClose &&
        <IconContainer onClick={handleClose} >
          <CloseIcon color={PRIMARY_OUTLINE} size={16} />
        </IconContainer>
      }
      {active && <BorderBottom />}
    </Container>
  )
}

export default TabItem

const Container = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  position: relative;
  padding: 0 20px;
  cursor: pointer;
  color: ${PRIMARY_FONT};
  font-size: 14px;

  border: 1px solid ${PRIMARY_OUTLINE};
  border-bottom: none;
  border-radius: 2px 2px 0 0;
  user-select: none;
  box-sizing: border-box;

  ${({ active }) => active
    ? css`
      margin-top: -4px;
      background-color: ${BACKGROUND_LIGHT};
  ` : css`
    background-color: #eaeaea;
  `};
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: 2px;
  /* Не удалять, перестанет работать обработчик закрытия вкладки */
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${PRIMARY_HOVER};
    border-radius: 50%;

    path {
      fill: #a1a1a1;
    }
  }

  &:active {
    background-color: ${SECONDARY_HOVER};
    border-radius: 50%;

    path {
      fill: ${PRIMARY_IMPORTANT};
    }
  }
`

const BorderBottom = styled.div`
  position: absolute;
  bottom: -2px;
  height: 3px;
  width: 100%;
  background-color: ${BACKGROUND_LIGHT};
`
