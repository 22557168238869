import React from 'react'

const KamazIcon = props => {
  const { className, size = 24, color = '#202020' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M18.0071 4.70098C18.4766 4.70098 18.8571 4.32042 18.8571 3.85098C18.8571 3.38153 18.4766 3.00098 18.0071 3.00098H15.9666C14.6423 3.00098 13.4874 3.90086 13.1637 5.18494L12.8571 6.40098H0.857143C0.383756 6.40098 0 6.78154 0 7.25098V12.351C0 12.8204 0.383756 13.201 0.857143 13.201H16.2857V4.70098H18.0071Z"
        fill={color}
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M24 16.601C24 17.0704 23.6162 17.451 23.1429 17.451H22.2553C22.0474 16.0093 20.7971 14.901 19.2857 14.901C17.7744 14.901 16.5241 16.0093 16.3161 17.451H13.225C13.017 16.0093 11.7667 14.901 10.2553 14.901C8.74399 14.901 7.49367 16.0093 7.28571 17.451H6.82676C6.6188 16.0093 5.36849 14.901 3.85714 14.901C2.3458 14.901 1.09548 16.0093 0.887524 17.451H0.857143C0.383756 17.451 0 17.0704 0 16.601V14.901C0 14.4315 0.383756 14.051 0.857143 14.051H17.1429V6.40098C17.1429 5.93154 17.5266 5.55098 18 5.55098H21.2531C21.6153 5.55098 21.9385 5.77678 22.0603 6.11509L24 11.501V16.601Z"
        fill={color}
      />

      <path
        // eslint-disable-next-line max-len
        d="M3.85714 20.001C5.04061 20.001 6 19.0496 6 17.876C6 16.7023 5.04061 15.751 3.85714 15.751C2.67368 15.751 1.71429 16.7023 1.71429 17.876C1.71429 19.0496 2.67368 20.001 3.85714 20.001Z"
        fill={color}
      />
      <path
        // eslint-disable-next-line max-len
        d="M12.4286 17.876C12.4286 19.0496 11.4692 20.001 10.2857 20.001C9.10225 20.001 8.14286 19.0496 8.14286 17.876C8.14286 16.7023 9.10225 15.751 10.2857 15.751C11.4692 15.751 12.4286 16.7023 12.4286 17.876Z"
        fill={color}
      />
      <path
        // eslint-disable-next-line max-len
        d="M19.2857 20.001C20.4692 20.001 21.4286 19.0496 21.4286 17.876C21.4286 16.7023 20.4692 15.751 19.2857 15.751C18.1022 15.751 17.1429 16.7023 17.1429 17.876C17.1429 19.0496 18.1022 20.001 19.2857 20.001Z"
        fill={color}
      />
    </svg>
  )
}

export default KamazIcon
