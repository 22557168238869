import { isNil, path, prop } from 'ramda'
import React, { PropsWithChildren, RefObject, useCallback, useContext } from 'react'
import { useRootElement } from '../../../hooks'

export type TPortalMeta = {
  id: string
  ref: RefObject<HTMLElement>
  portalRef: RefObject<HTMLElement>
}

const modalPortalContext = Object.freeze({
  popups: new Array<TPortalMeta>(),
})

export type TModalPortalContext = typeof modalPortalContext

export const ModalPortalContext = React.createContext(null)

export function ModalPortalProvider({ children }: PropsWithChildren<{}>) {
  return <ModalPortalContext.Provider value={modalPortalContext}>{children}</ModalPortalContext.Provider>
}

export function useLastModalPotral(id?: string): HTMLElement | undefined {
  const context = useContext<TModalPortalContext>(ModalPortalContext)
  const root = useRootElement()

  if(isNil(id)) { return root }

  return path(['ref', 'current'], context.popups.find(p => p.id === id))
}

type TUseModalPortal = {
  getOrRegisterPortal: (id: string) => TPortalMeta
  indexOf: (id: string) => number
  getPortal: (id: string) => TPortalMeta
  removeAt: (index: number) => TPortalMeta[]
}

export function useModalPotral(): TUseModalPortal {
  const context = useContext<TModalPortalContext>(ModalPortalContext)

  if(isNil(context) || isNil(context.popups)) {
    throw 'ModalPortal should be used only into <ModalPortalProvider>'
  }

  const indexOf = useCallback((id: string) => context.popups.findIndex(P => P.id === id), [context.popups])
  const getPortal = useCallback((id: string) => context.popups.find(P => P.id === id), [context.popups])

  const getOrRegisterPortal = useCallback((id: string) => {
    return getPortal(id) || context.popups[context.popups.push({
      id,
      portalRef: React.createRef<HTMLElement>(),
      ref      : React.createRef<HTMLDivElement>(),
    }) - 1]
  }, [context.popups, getPortal])

  const removeAt = useCallback(index => context.popups.splice(index, 1), [context.popups])

  return {
    getPortal,
    indexOf,
    getOrRegisterPortal,
    removeAt,
  }
}
