import React, { ReactNode, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import ModalOverlay, { TModalOverlayProps } from '../ModalOverlay'
import { useCreatePortal } from '../../hooks'
import { useLastModalPotral, useModalPotral } from './ModalPortalProvider'

const BLUR_RADIUS = 2

type TType = {
  type: 'modal' | 'sidebarLeft' | 'sidebarRight'
}

type TModalPortal = {
  aboveId?: string
  blurredElement?: HTMLElement
  children: ReactNode
  isCloseByClickOutside?: boolean
  id: string
  onClose: () => void

  // блок, в который нужно встроить модальное окно. Если не указано - портал создаётся в body.
  outerElement?: HTMLElement
  showModal: boolean
  transparentBackground?: boolean
  alwaysOnTop?: boolean
  noBlur?: boolean
  noOverlay?: boolean
} & TType

export const ModalPortal = ({
  aboveId,
  id,
  isCloseByClickOutside,
  children,
  onClose,
  outerElement,
  showModal,
  type,
  transparentBackground,
  alwaysOnTop,
  noBlur,
  noOverlay,
}: TModalPortal) => {
  const blurredElement = useLastModalPotral(aboveId)
  const modalPortal = useModalPotral()
  const portal = modalPortal.getOrRegisterPortal(id)
  const createPortal = useCreatePortal(portal.portalRef, id, outerElement)
  const abovePortal = modalPortal.getPortal(aboveId)

  useEffect(() => {
    if(!noBlur && blurredElement && showModal){
      blurredElement.style.filter = `blur(${BLUR_RADIUS}px)`
      return () => { blurredElement.style.filter = 'none' }
    }
  }, [showModal, blurredElement])


  useEffect(() => {
    const bodyStyle = document.getElementsByTagName('body')[0].style
    let prevOverflowValue = bodyStyle.overflow

    if(!noOverlay && showModal){
      bodyStyle.overflow = 'hidden'
    }

    return () => {
      if(showModal) {
        bodyStyle.overflow = prevOverflowValue
      }
    }
  }, [showModal])

  useEffect(() => {
    if(showModal && (abovePortal && abovePortal.ref.current || alwaysOnTop)) {
      portal.portalRef.current.parentNode.insertBefore(
        portal.portalRef.current,
        alwaysOnTop ? null : abovePortal.portalRef.current.nextSibling,
      )
    }
  }, [showModal, abovePortal && abovePortal.ref.current])

  useEffect(() => {
    return () => {
      const index = modalPortal.indexOf(id)

      if(index !== -1) {
        modalPortal.removeAt(index)
      }
    }
  }, [])

  return showModal && createPortal(
    noOverlay
      ? <Container ref={portal.ref as any} type={type}>{children}</Container>

      : <ModalOverlay
        key={`children-${id}`}
        isCloseByClickOutside={isCloseByClickOutside}
        onClose={onClose}
        transparentBackground={transparentBackground}
        visible
      >{<Container ref={portal.ref as any} type={type}>{children}</Container>}</ModalOverlay>,
  )
}

const sidebarCommonStyles = css`
  position: absolute;
  height: 100%;
  top: 0;
`

const sidebarStyles = {
  modal: '',

  sidebarLeft: css`
    left: 0;
    border-right: 1px solid #cecece;
  `,

  sidebarRight: css`
    right: 0;
    border-left: 1px solid #cecece;
  `,
}

const Container = styled.div<TType>`
  z-index: 999;
  overflow: hidden;
  ${({ type }) => ['sidebarLeft', 'sidebarRight'].includes(type) ? sidebarCommonStyles : ''};
  ${({ type }) => sidebarStyles[type]};
`

export { ModalPortalProvider, useLastModalPotral } from './ModalPortalProvider'
