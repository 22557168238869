import Tippy from "@tippyjs/react";
import React, { FC, useCallback } from "react";
import AppMenuNavItem from "../AppMenuNavItem";
import { MainMenuItemType } from ".";

type Props = {
  item: MainMenuItemType;
  noTooltip: boolean;
  t: (key: string) => string;
  onNavigate: (link: string) => void;
  testId?: string;
};

export const SingleTippy: FC<Props> = ({
  item,
  t,
  noTooltip,
  onNavigate,
  testId,
}) => {
  const handleGoTo = useCallback(
    ({ external, linkTo }: MainMenuItemType) =>
      () => {
        if (external) {
          window.location.href = linkTo;
        } else {
          onNavigate(linkTo);
        }
      },
    [onNavigate]
  );

  return (
    <Tippy
      placement="bottom"
      content={t(item.label)}
      touch="hold"
      disabled={noTooltip}
      data-testid={testId}
    >
      <AppMenuNavItem
        label={t(item.label)}
        icon={item.icon}
        active={item.active}
        onClick={handleGoTo(item)}
        value={item}
        testId={testId}
      />
    </Tippy>
  );
};
