import { areEqual, FixedSizeList as List } from 'react-window'
import { always, identity, ifElse, is, isEmpty, isNil, prop, reject } from 'ramda'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ArrowDropDownIcon, InputClearIcon } from 'icons'
import DropdownList from '../DropdownList'
import DefaultItem from './DefaultItem'
import DefaultInput from './DefaultInput'
import Spinner from "../Spinner";


const notNullValue = ifElse(isNil, always(''), identity)

const makeFindItemNameById = (getId, getCaption) => (itemId, items) => {
  const item = items.find(item => getId(item) === itemId)

  if(!item){return ''}

  return getCaption(item)
}

const Autocomplete = props => {
  const {
    label = '',
    helperText,
    error,
    className,
    value,
    items,
    caption = prop('name'),
    id = prop('id'),
    disabled,
    loading = false,
    placeholder = '',
    Item = DefaultItem,
    Input = DefaultInput,
    onChange,
    onHideList,
    isPopupOpen,
    required,

    filterBy = (item, inputValue) => {
      try { return caption(item).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 } catch{ return false }
    },

    rejectBy,
    nullValue = null,
    emptyLabel = 'Нет элементов',
    allowFreeText = false,
    itemHeight = 35,
    ...rest
  } = props

  const [helperTextHeight, setHelperTextHeight] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [isPopupShown, setIsPopupShown] = useState(isPopupOpen || false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const findItemNameById = makeFindItemNameById(id, caption)
  const filteredItems = isEmpty(inputValue) ? items : items.filter(item => filterBy(item, inputValue))

  useEffect(() => {
    if(isPopupOpen !== undefined) {
      if(isPopupShown !== isPopupOpen) {setIsPopupShown(isPopupOpen)}
    }
  }, [isPopupOpen, isPopupShown])

  const setInputValueHandler = value => {
    if(allowFreeText) {
      onChange(value)
    }

    setInputValue(notNullValue(value))
  }

  const handleHidePopup = () => {
    if(!allowFreeText) {
      setInputValueHandler(findItemNameById(value, items))
    }

    if(onHideList && typeof onHideList === 'function'){
      onHideList()
    }

    setIsPopupShown(false)
  }

  const handleFocus = () => {
    if(!allowFreeText) {
      setInputValueHandler('')
    }

    setIsPopupShown(true)
  }

  // Очищает введенный текст и выбранное значение
  const handleClear = () => {
    if(onChange && typeof onChange === 'function') {onChange(nullValue)}

    setInputValueHandler('')
  }

  const selectValue = useCallback(item => {
    onChange(item)
    setIsPopupShown(false)
  }, [onChange])

  const keyPress = useCallback(event => {
    if(![38, 40, 13].includes(event.keyCode)){return}

    event.preventDefault()

    if(event.keyCode === 13 && selectedIndex >= 0) {
      selectValue(filteredItems[selectedIndex])
      return
    }

    if(event.keyCode === 38) {
      setSelectedIndex(
        (selectedIndex - 1 < 0 ? filteredItems.length - 1 : selectedIndex - 1) % filteredItems.length,
      )
    }else if(event.keyCode === 40) {
      setSelectedIndex((selectedIndex + 1) % filteredItems.length)
    }
  }, [setSelectedIndex, filteredItems, selectedIndex, selectValue])

  useEffect(() => setInputValueHandler(allowFreeText ? value: findItemNameById(value, items)), [value, items])

  const itemsWithoutRejected = useMemo(() => is(Function, rejectBy)
    ? reject(rejectBy, filteredItems)
    : filteredItems, [filteredItems, rejectBy])

  const MemoizedRow = memo(({ index, style }) => <Item
    data-id={id(itemsWithoutRejected[index])}
    isSelected={selectedIndex >= 0 && id(itemsWithoutRejected[index]) === id(filteredItems[selectedIndex])}
    item={itemsWithoutRejected[index]}
    key={id(itemsWithoutRejected[index])}
    onClick={() => selectValue(itemsWithoutRejected[index])}
    value={caption(itemsWithoutRejected[index])}
    style={style}
  />, areEqual)

  const listHeight = useMemo(() => {
    const itemsHeight = itemsWithoutRejected.length * itemHeight
    return itemsHeight > 150 ? 150 : itemsHeight
  }, [itemsWithoutRejected.length])

  const autocompleteList = () => {
    return (
      <ScrollContainer>
        {isEmpty(itemsWithoutRejected) && <Empty>{emptyLabel}</Empty>}
        {<StyledList
          height={listHeight}
          itemCount={itemsWithoutRejected.length}
          itemSize={itemHeight}
        >
          {MemoizedRow}
        </StyledList>}
      </ScrollContainer>
    )
  }

  const LoadingIcon = loading ? <Spinner size={24} /> : undefined

  return (
    <DropdownList
      className={className}
      listChildren={autocompleteList()}
      onHidePopup={handleHidePopup}
      isPopupOpen={isPopupShown}
      popupOffset={helperText ? [0, -helperTextHeight] : null}
    >
      <Input
        value={inputValue}
        label={label}
        helperText={helperText}
        disabled={disabled}
        onChange={setInputValueHandler}
        onFocus={handleFocus}
        onKeyDown={keyPress}
        placeholder={placeholder}
        required={required}
        error={error}
        icon={
          LoadingIcon ||
          (inputValue && !disabled ?
            <ClearButton onClick={handleClear}>
              <ClearIcon />
            </ClearButton>
            :
            <ArrowIcon direction={isPopupShown ? 'top' : 'bottom'} />)

        }
        updateHelperTextHeight={height => setHelperTextHeight(height)}
        {...rest}
      />
    </DropdownList>
  )
}

export default Autocomplete

const StyledList = styled(List)`
  overflow-x: hidden !important;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(209, 0, 41, 0.7);
    border-radius: 3px;
  }
`

const Empty = styled.div`
  display: block;
  padding: 10px 20px;
  white-space: nowrap;
  z-index: 12;
  color: grey;
`

const ScrollContainer = styled.div`
  width: auto;
  max-height: 150px;
`

const ArrowIcon = styled(ArrowDropDownIcon).attrs({ color: '#a1a1a1' })``

const ClearIcon = styled(InputClearIcon).attrs({ color: '#a1a1a1' })``

const ClearButton = styled.button`
  /* Растягиваем кнопку на всю высоту и длину контейнера, в который он помещается */
  height: 100%;
  width: 100%;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
`
