import React from 'react'

const FilterIcon = props => {
  const { size = 20, color = '#000000' } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 12V19.883C14.0264 20.09 13.9872 20.3001 13.8881 20.4837C13.789 20.6674 13.6348 20.8154 13.4473 20.9069C13.2598 20.9985 13.0483 21.029 12.8425 20.9942C12.6367 20.9595 12.447 20.8611 12.3 20.713L10.285 18.697C10.1771 18.5893 10.0953 18.4583 10.0459 18.3141C9.99651 18.1699 9.9808 18.0162 10 17.865V12H9.974L4.21 4.622C4.09472 4.4742 4.02331 4.29697 4.0039 4.11054C3.98449 3.9241 4.01788 3.73597 4.10024 3.56759C4.1826 3.39921 4.31063 3.25737 4.46972 3.15825C4.62881 3.05913 4.81256 3.00672 5 3.007V3H19V3.007C19.1876 3.00658 19.3716 3.05894 19.5308 3.15811C19.6901 3.25728 19.8183 3.39925 19.9007 3.56781C19.983 3.73636 20.0164 3.92469 19.9968 4.11129C19.9772 4.29788 19.9056 4.47521 19.79 4.623L14.026 12H14Z" />
    </svg>
  )
}

export default FilterIcon
