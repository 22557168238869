import React from 'react'

const CatalogsIcon = ({ size = 24, color = '#ffffff' }) => <svg
  width={size}
  height={size}
  viewBox={`0 0 ${size} ${size}`}
>
  <path
    fillRule="evenodd"
    d="M14.5 12.002C15.3048 12.002 16.0949 12.2177 16.788 12.6269C17.481 13.0359 18.0517 13.6234 18.4405 14.3281C18.8293 15.0328 19.022 15.8288 18.9985 16.6333C18.975 17.4377 18.7362 18.2212 18.307 18.9019L21.391 21.9859L19.984 23.393L16.876 20.3239C16.2845 20.6915 15.6158 20.9168 14.9225 20.9821C14.2292 21.0474 13.5302 20.9509 12.8805 20.7002C12.2308 20.4494 11.6482 20.0514 11.1784 19.5372C10.7087 19.0231 10.3647 18.4071 10.1736 17.7375C9.9824 17.0678 9.9492 16.363 10.0767 15.6784C10.2041 14.9937 10.4888 14.3479 10.9081 13.7921C11.3275 13.2361 11.8702 12.7851 12.4935 12.4745C13.1167 12.1638 13.8036 12.0021 14.5 12.002ZM14.5 14.002C13.837 14.002 13.2011 14.2654 12.7322 14.7343C12.2634 15.203 12 15.839 12 16.502C12 17.1651 12.2634 17.8009 12.7322 18.2697C13.2011 18.7386 13.837 19.0019 14.5 19.0019C15.163 19.0019 15.7989 18.7386 16.2678 18.2697C16.7366 17.8009 17 17.1651 17 16.502C17 15.839 16.7366 15.203 16.2678 14.7343C15.7989 14.2654 15.163 14.002 14.5 14.002ZM5 21.9999C3.896 21.9999 3 21.1039 3 19.9999V4C3 2.896 3.896 2 5 2H6V9L8.5 7.5L11 9V2H17C17.5304 2 18.0391 2.21071 18.4142 2.58578C18.7893 2.96086 19 3.46956 19 4V11.811C18.344 11.1825 17.5635 10.6985 16.7089 10.3902C15.8544 10.0819 14.9446 9.95614 14.0385 10.0211C13.1323 10.0861 12.2498 10.3403 11.4479 10.7673C10.646 11.1943 9.9426 11.7846 9.3829 12.5003C8.82328 13.216 8.41987 14.041 8.19871 14.9222C7.97756 15.8034 7.94357 16.7211 8.09896 17.6162C8.25434 18.5113 8.59564 19.3638 9.1008 20.1189C9.606 20.8741 10.2639 21.5148 11.032 21.9999H4.998H5Z"
    fill={color} />
</svg>

export default CatalogsIcon
