import React from 'react'

const ArrowUpIcon = props => {
  const {
    size = 24,
    color = '#202020',
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.12501 15L12.005 11.12L15.885 15C16.275 15.39 16.905 15.39 17.295 15C17.685 14.61 17.685 13.98 17.295 13.59L12.705 9.00001C12.5182 8.81275 12.2645 8.70752 12 8.70752C11.7355 8.70752 11.4818 8.81275 11.295 9.00001L6.70501 13.59C6.31501 13.98 6.31501 14.61 6.70501 15C7.09501 15.38 7.73501 15.39 8.12501 15Z" fill={color}/>
    </svg>
  )
}

export default ArrowUpIcon
