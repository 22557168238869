import React from 'react'

const MachineIcon = props => {
  const { size = 24, color = '#A1A1A1' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fillRule="evenodd"
          d="M13.0062 15.6897C13.0062 13.5297 14.7575 11.7784 16.9175 11.7784H18.6072L19.851 4.9335H13.984L13.8061 5.91134H1.0942L0 11.9299L7.56747 13.6118L9.09485 16.6675H13.145C13.0629 16.3536 13.0062 16.03 13.0062 15.6897ZM7.1392 16.6675C7.1392 17.7471 6.26306 18.6232 5.18353 18.6232C4.104 18.6232 3.22786 17.7471 3.22786 16.6675C3.22786 15.588 4.104 14.7119 5.18353 14.7119C6.26306 14.7119 7.1392 15.588 7.1392 16.6675ZM20.8289 15.69V15.4524L24 18.6235H19.4804C20.2999 17.9058 20.8289 16.8644 20.8289 15.69ZM16.9176 12.7562C15.2973 12.7562 13.9841 14.0694 13.9841 15.6897C13.9841 17.31 15.2973 18.6232 16.9176 18.6232C18.5378 18.6232 19.8511 17.31 19.8511 15.6897C19.8511 14.0694 18.5378 12.7562 16.9176 12.7562Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default MachineIcon
