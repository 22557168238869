import { compose, insertAll, range, remove } from 'ramda'

const allDaysInitial = range(0, 35).map(id => ({
  id,
  isInCurMonth: false,
}))

const getDayOFWeek = date => date.getDay() || 7

const getDaysInMonthCount = (curYear, curMonth) => new Date(curYear, (curMonth + 1) % 12, 0)
  .getDate()

const genMonthsDays = daysInMonthCount => range(1, daysInMonthCount + 1).map(id => ({
  id,
  isInCurMonth: true,
}))

const buildDaysArr = (monthDays, daysInMonthCount, dayOfWeek) => compose(
  insertAll(dayOfWeek - 1, monthDays),
  remove(dayOfWeek - 1, daysInMonthCount),
)(allDaysInitial)

const splitDaysByWeeks = daysArr => {
  const weeksCount = daysArr.length > 35 ? 6 : 5
  return range(0, weeksCount).map(id => ({
    id,
    days: daysArr.slice(id * 7, id * 7 + 7),
  }))
}

export const getDaysByWeeks = (curYear, curMonth) => {
  const dayOfWeek = getDayOFWeek(new Date(curYear, curMonth))
  const daysInMonthCount = getDaysInMonthCount(curYear, curMonth)
  const monthDays = genMonthsDays(daysInMonthCount)
  const daysArr = buildDaysArr(monthDays, daysInMonthCount, dayOfWeek)
  return splitDaysByWeeks(daysArr)
}

export const genDayKey = dayConfig => `${dayConfig.isInCurMonth ? 1 : 0}-${dayConfig.id}`

export const getDaysColor = (colorValues, levels, colors) => {
  return colorValues.map(day => {
    if(!day){return null}

    const index = levels.findIndex(level => day <= level)
    return index > -1 ? colors[index] : colors[colors.length - 1]
  })
}
