import { is } from 'ramda'
import React, { ReactNode, useCallback } from 'react'
import styled from 'styled-components'

type TSelectItemProps = {
  children: ReactNode
  item?: any
  onClick?: (P) => void
  className?: string
}

export default function SelectItem({ children, item, onClick, className }: TSelectItemProps) {
  const onClickHandler = useCallback(e => {
    if(is(Function, onClick)) {onClick(item)}

    e.preventDefault()
  }, [item, onClick])

  return <StyledItem onClick={onClickHandler} className={className}>{children}</StyledItem>
}

const StyledItem = styled.div`
  display: block;
  padding: 8px 24px;
  white-space: nowrap;
  cursor: pointer;
  color: #202020;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  user-select: none;

  &:hover {
    background-color: rgba(0, 0, 0, .08);
  }
`
