import React from 'react'
import styled from 'styled-components'
import { range } from 'ramda'

const WeekDaysTitlesHeader = props => {
  const { weekDaysTitles } = props

  return (
    <Container>
      {range(1, 8).map(i =>
        <WeekDayTitle key={i}>
          {weekDaysTitles[i]}
        </WeekDayTitle>,
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  margin-bottom: 14px;
`

const WeekDayTitle = styled.div`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #787878;
  width: 46px;
`

export default WeekDaysTitlesHeader
