import Button from 'components/Button'
import Spinner from 'components/Spinner'
import React from 'react'
import styled from 'styled-components'

type TApproveButton = {
  clickHandle: () => void
  disabled?: boolean
  load: boolean
  title: string
}

const ApproveButton = ({ clickHandle, disabled, load, title }: TApproveButton) => <StyledButton
  disabled={disabled}
  loadRequestId={load}
  onClick={load ? null : clickHandle}
  variant='primary'
  width='175px'
>
  {load
    ? <Spinner color="#FFFFFF" size={18}/>
    : title
  }
</StyledButton>

export default ApproveButton

const StyledButton = styled(Button)<{ loadRequestId: boolean }>`
  cursor: ${({ loadRequestId }) => loadRequestId ? 'unset' : 'pointer'};
`
