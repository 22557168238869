import React from 'react'

const TractorIcon = props => {
  const { className, size = 25, color = '#202020' } = props
  return (
    <svg
      className={className}
      width={size}
      height={size * 14 / 25}
      viewBox="0 0 50 28"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs />
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1.000000, -3.000000)">
          <polygon points="0 20 26 20 26 0 0 0" />

          <path
            d="M4.5,10 C6.433,10 8,11.567 8,13.5 C8,15.433 6.433,17 4.5,17 C2.567,17 1,15.433 1,13.5 C1,11.567 2.567,10 4.5,10 Z M17.5,10 C19.433,10 21,11.567 21,13.5 C21,15.433 19.433,17 17.5,17 C15.567,17 14,15.433 14,13.5 C14,11.567 15.567,10 17.5,10 Z M3.1084,9.2422 L3.2854,8.0002 L8.0004,8.0002 L8.0004,10.7032 C7.1754,9.6732 5.9224,9.0002 4.5004,9.0002 C4.0114,9.0002 3.5484,9.0972 3.1084,9.2422 Z M25,13 L26,13 L26,14 L24,14.286 L24,13 L21.95,13 C21.698,10.753 19.814,9 17.5,9 C15.015,9 13,11.015 13,13.5 C13,13.671 13.032,13.833 13.05,14 L11,14 L8.95,14 C8.968,13.833 9,13.671 9,13.5 C9,13.329 8.968,13.167 8.95,13 L11,13 L11,10 L9,9.714 L9,8 L10,8 L10.571,4 L9,4 L9,3 L10.714,3 L11,3 L12,3 L17,3 L17.582,7.073 L24.854,7.982 L25,9 L25,13 Z M17.5,15 C18.329,15 19,14.329 19,13.5 C19,12.671 18.329,12 17.5,12 C16.671,12 16,12.671 16,13.5 C16,14.329 16.671,15 17.5,15 Z M4.5,15 C5.329,15 6,14.329 6,13.5 C6,12.671 5.329,12 4.5,12 C3.671,12 3,12.671 3,13.5 C3,14.329 3.671,15 4.5,15 Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
}

export default TractorIcon
