import 'imask/esm/masked/number'
import 'imask/esm/masked/pipe'
import 'imask/esm/masked/pattern'
import IMask from 'imask/esm/imask'
import { always, pipe, tryCatch } from 'ramda'

// As any for a while bug is not fixed
export const phoneMask: (value: any) => string = tryCatch(IMask.createPipe({
  mask: '+{7} (000) 000-00-00',

  prepare: (appended: string, masked: { value: string }) => {
    if(appended === '8' && masked.value === '') {
      return ''
    }

    return appended
  },
} as any), always(''))

const digitsMask = IMask.createPipe({ mask: '00000000000' } as any)
export const onlyDigits: (value: any) => string = pipe<any, string, string>(phoneMask, digitsMask)
export const validate = (value: any) => onlyDigits(value).length === 11
