import React from 'react'

const AssignmentTurnedIcon = props => {
  const { size = 24, color = '#A1A1A1' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 4H14.82C14.4 2.84 13.3 2 12 2C10.7 2 9.6 2.84 9.18 4H5C3.9 4 3 4.9 3 6V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM12 4C12.55 4 13 4.45 13 5C13 5.55 12.55 6 12 6C11.45 6 11 5.55 11 5C11 4.45 11.45 4 12 4ZM6.7 14.7L9.29 17.29C9.68 17.68 10.32 17.68 10.7 17.29L17.29 10.7C17.68 10.31 17.68 9.68 17.29 9.29C16.9 8.9 16.27 8.9 15.88 9.29L10 15.17L8.11 13.29C7.72 12.9 7.09 12.9 6.7 13.29C6.51275 13.4768 6.40751 13.7305 6.40751 13.995C6.40751 14.2595 6.51275 14.5132 6.7 14.7Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default AssignmentTurnedIcon
