import { useEffect, useState } from 'react'

const lang = getLang()

export function setLang(lang) {
  const newLang = lang.toLowerCase()
  localStorage.setItem('rsm-lang', newLang)
  lang = newLang
}

export function getLang(defaultLang = 'en') {
  return localStorage.getItem('rsm-lang') || (/^ru\b/.test(navigator.language) ? 'ru' : defaultLang)
}

export function useLang(defaultLang = 'en') {
  const [langInternal, setLangInternal] = useState(getLang(defaultLang))

  const setLang = newLang => {
    setLang(newLang)
    setLangInternal(newLang)
  }

  useEffect(() => {
    if(langInternal !== lang) { setLangInternal(lang) }
  }, [lang])

  return { lang, setLang }
}
