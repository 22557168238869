import React from 'react'

const XlsIcon = props => {
  const {
    color = '#80B757',
    size = 24,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9498 5.18996V19.3351C21.9498 21.0711 20.021 23 18.285 23H5.71505C3.97906 23 2.05017 21.0711 2.05017 19.3351V16.7097H14.62C16.356 16.7097 17.7598 15.3059 17.7598 13.5699V9.37996C17.7598 7.64396 16.356 6.24017 14.62 6.24017H2.05017V4.66488C2.05017 2.92888 3.97906 1 5.71505 1H17.7599V5.18996H21.9498ZM7.66331 14.0348H6.88828V7.59991H7.66331V14.0348ZM4.08557 11.1567L5.09103 9.50189H5.99593L4.51289 11.7432L6.04202 14.0348H5.14549L4.09814 12.3381L3.05079 14.0348H2.15007L3.6792 11.7432L2.19616 9.50189H3.09269L4.08557 11.1567ZM11.3081 12.3465C11.4673 12.461 11.5469 12.623 11.5469 12.8325C11.5469 13.0308 11.4575 13.19 11.2788 13.3101C11.1028 13.4274 10.8682 13.486 10.5749 13.486C10.2565 13.486 9.9996 13.4106 9.80409 13.2598C9.61138 13.1062 9.50804 12.8995 9.49408 12.6397H8.71904C8.71904 12.9079 8.79724 13.1578 8.95365 13.3896C9.11284 13.6187 9.33209 13.7974 9.61138 13.9259C9.89067 14.0544 10.2119 14.1186 10.5749 14.1186C11.0916 14.1186 11.512 13.9971 11.8359 13.7541C12.1599 13.5083 12.3219 13.183 12.3219 12.778C12.3219 12.535 12.2633 12.3297 12.146 12.1622C12.0315 11.9918 11.8541 11.8466 11.6139 11.7265C11.3737 11.6064 11.0595 11.503 10.6713 11.4164C10.2859 11.3299 10.0191 11.2391 9.87112 11.1441C9.7231 11.0492 9.64909 10.9081 9.64909 10.721C9.64909 10.5227 9.72868 10.3621 9.88788 10.2392C10.0471 10.1163 10.2677 10.0549 10.5498 10.0549C10.8235 10.0549 11.0497 10.1317 11.2285 10.2853C11.41 10.4389 11.5008 10.6219 11.5008 10.8341H12.28C12.28 10.4208 12.1194 10.0814 11.7982 9.8161C11.4799 9.55077 11.0637 9.4181 10.5498 9.4181C10.061 9.4181 9.65886 9.54658 9.34326 9.80353C9.03045 10.0605 8.87405 10.3747 8.87405 10.7461C8.87405 10.9696 8.92851 11.1623 9.03743 11.3243C9.14915 11.4863 9.31952 11.6259 9.54854 11.7432C9.78035 11.8605 10.0918 11.9611 10.4828 12.0449C10.8766 12.1286 11.1517 12.2292 11.3081 12.3465Z"
        fill={color}
      />
    </svg>
  )
}

export default XlsIcon
