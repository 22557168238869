import { HTMLProps } from 'react'
import { PropsWithChildren } from 'react'
import React from 'react'
import styled from 'styled-components'

type TBackgroundProp = { background?: string }
type TModalDialogProps = PropsWithChildren<Pick<HTMLProps<HTMLDivElement>, 'className'>> & TBackgroundProp

const ModalDialog = ({ children, className, background }: TModalDialogProps) => {
  return (
    <Dialog className={className} background={background}>
      {children}
    </Dialog>
  )
}

const Dialog = styled.div<TBackgroundProp>`
  position: relative; /* Для позиционирования кнопки закрытия диалога */
  height: fit-content;
  min-width: 320px;
  width: fit-content;
  padding: 30px;
  background-color: ${props => props.background ? props.background : '#fff'};
  border: 1px solid #999;
  outline: none;
  box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.19), 0 3px 6px 0 rgba(0, 0, 0, 0.039);
  border-radius: 4px;
`

export default ModalDialog
