import React from 'react'

const defaultParam = {
  size: '48px',
}

const FolderCatalogIcon = (props = defaultParam) => {
  const {
    size = defaultParam.size,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ddd)">
        <path d="M21 8H9C6.8 8 5.02 9.8 5.02 12L5 36C5 38.2 6.8 40 9 40H41C43.2 40 45 38.2 45 36V16C45 13.8 43.2 12 41 12H25L21 8Z" fill="#D10029"/>
      </g>

      <defs>
        <filter id="filter0_ddd" x="-4" y="-4" width="58" height="59" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="2.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.917647 0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 0.2 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="3"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.917647 0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 0.12 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.917647 0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 0.14 0"/>
          <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>
  )
}

export default FolderCatalogIcon
