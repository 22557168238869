import React from 'react'

const DashboardIcon = ({ size = 24, color = '#ffffff' }) => <svg
  width={size}
  height={size}
  viewBox="0 0 18 18"
>
  <path
    d='M7 10H1C0.45 10 0 9.55 0 9V1C0 0.45 0.45 0 1 0H7C7.55 0 8 0.45 8 1V9C8 9.55 7.55 10 7 10ZM7 18H1C0.45 18 0 17.55 0 17V13C0 12.45 0.45 12 1 12H7C7.55 12 8 12.45 8 13V17C8 17.55 7.55 18 7 18ZM11 18H17C17.55 18 18 17.55 18 17V9C18 8.45 17.55 8 17 8H11C10.45 8 10 8.45 10 9V17C10 17.55 10.45 18 11 18ZM10 5V1C10 0.45 10.45 0 11 0H17C17.55 0 18 0.45 18 1V5C18 5.55 17.55 6 17 6H11C10.45 6 10 5.55 10 5Z'
    fill={color}
  />
</svg>

export default DashboardIcon
