import { is } from 'ramda'
import React, { PropsWithChildren, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'

export type TModalOverlayProps = PropsWithChildren<{
  onClose: Function
  visible: boolean
  isCloseByClickOutside?: boolean
  isCloseByEsc?: boolean
  transparentBackground?: boolean
}>

const ModalOverlay = (
  { children, onClose, visible, isCloseByClickOutside = true, isCloseByEsc = true, transparentBackground }: TModalOverlayProps
) => {
  const outsideRef = useRef<HTMLDivElement>()

  useEffect(() => {
    // Чтобы сработал обработчик KeyDown устанавливаем фокус на Outside
    if(visible && outsideRef.current) {
      outsideRef.current.focus()
    }
  }, [visible])

  const handleOutside = e => {
    if(e.target === e.currentTarget) {
      e.preventDefault()

      if(is(Function, onClose) && isCloseByClickOutside) {onClose()}
    }
  }

  const handleKeyDown = e => {
    if(e.key === 'Escape' && isCloseByEsc) {
      if(is(Function, onClose)) {onClose()}
    }
  }

  return (
    <Outside
      onClick={handleOutside}
      onKeyDown={handleKeyDown}
      ref={outsideRef}
      tabIndex={0}
      visible={visible}
      transparentBackground={transparentBackground}
    >
      {children}
    </Outside>
  )
}

const opacityAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Outside = styled.div<{visible: boolean, transparentBackground?: boolean}>`
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ transparentBackground }) => transparentBackground ? 'transparent': 'rgb(0, 0, 0, 0.3)' };
  z-index: 10002;
  animation: ${opacityAnimation} .6s ease;
`


export default ModalOverlay
