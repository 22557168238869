import React, { forwardRef, useCallback, useMemo } from 'react'
import { FixedSizeNodeData, FixedSizeTree } from 'react-vtree'
import styled from 'styled-components'
import { TDataTree } from '../index'
import Node from './Node'

type StackElement = {
  nestingLevel: number
  node: TDataTree
}

export type ExtendedData = FixedSizeNodeData &
  Readonly<{
    isLeaf: boolean
    node: TDataTree
    nestingLevel: number
    onSelectOption: (node: TDataTree) => void
  }>;


type TreePresenterProps = {
  height: number
  items: TDataTree[]
  itemSize: number
  onSelectOption: (node: TDataTree) => void
}

const Tree = ({ height, items, itemSize, onSelectOption }: TreePresenterProps) => {
  const treeWalker = useCallback(function* (): Generator<ExtendedData | string | symbol, void, boolean> {
    const stack: StackElement[] = []

    items.forEach(I => stack.push({
      nestingLevel: 0,
      node        : I,
    }))

    while(stack.length !== 0) {
      const {
        node,
        nestingLevel,
      } = stack.pop()!

      const isOpened = yield {
        id             : `${node.id}_${nestingLevel}`,
        isLeaf         : !node.children || node.children.length === 0,
        isOpenByDefault: false,
        node,
        nestingLevel,
        onSelectOption,
      }

      if(node?.children?.length !== 0 && isOpened) {
        for(let i = node.children.length - 1; i >= 0; i--) {
          stack.push({
            nestingLevel: nestingLevel + 1,
            node        : node.children[i],
          })
        }
      }
    }
  }, [items])

  const outerElementType = useMemo(
    () => forwardRef((props, ref) => <StyledLi {...props} height={height} /> ),
    [])

  return <FixedSizeTree
    height={height}
    itemSize={itemSize}
    outerElementType={outerElementType}
    treeWalker={treeWalker}
    width='100%'
  >
    {Node}
  </FixedSizeTree>
}

export default Tree

const StyledLi = styled.li<{ height: number}>`
  height: unset !important;
  overflow-x: hidden !important;
  max-height: ${({ height }) => `${height}px !important`};

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(209, 0, 41, 0.7);
    border-radius: 3px;
  }
`
