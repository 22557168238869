import { isNil } from 'ramda'
import { useEffect, useRef } from 'react'

type TCallbacks = {
  onClose?: () => any
  onOpen?: () => any
  onToggle?: (state: boolean) => any
}

export default (isShown, { onOpen, onClose, onToggle }: TCallbacks = {}) => {
  const prevState = useRef()

  useEffect(() => {
    if(isNil(prevState.current)) {
      prevState.current = isShown
      return
    }

    if(isShown && onOpen) {
      onOpen()
    }

    if(!isShown && onClose) {
      onClose()
    }

    if(onToggle) {
      onToggle(isShown)
    }
  }, [isShown])
}
