import React from 'react'

const EquipmentIcon = ({
  size = 24,
  color = '#CCCCCC',
}) => <svg
  width={size}
  height={size / 1.26}
  viewBox='0 0 24 19'
>
  <path
    d="M9.66389 18.4947C9.05505 18.4947 8.54474 18.0728 8.40931 17.5055H14.0706C14.8798 17.5055 15.6276 17.0737 16.0322 16.3729L20.2135 9.13074C20.6181 8.42991 20.6181 7.56645 20.2135 6.86562L18.645 4.14892H21.5653C22.8981 4.14892 23.858 5.42781 23.4858 6.70749L20.4766 17.0533C20.2282 17.9073 19.4456 18.4947 18.5562 18.4947H9.66389Z"
    fill={color}
  />
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.7245 0.378497C14.5896 0.144886 14.3403 0.000976562 14.0706 0.000976562H5.70807C5.43832 0.000976562 5.18906 0.144887 5.05419 0.378497L0.872927 7.62066C0.738052 7.85427 0.738052 8.14209 0.872927 8.3757L5.05419 15.6179C5.18906 15.8515 5.43832 15.9954 5.70807 15.9954L14.0706 15.9954C14.3403 15.9954 14.5896 15.8515 14.7245 15.6179L18.9057 8.3757C19.0406 8.14209 19.0406 7.85427 18.9057 7.62066L14.7245 0.378497ZM9.88411 10.9443C11.5521 10.9443 12.9043 9.59216 12.9043 7.92417C12.9043 6.25618 11.5521 4.90401 9.88411 4.90401C8.21612 4.90401 6.86395 6.25618 6.86395 7.92417C6.86395 9.59216 8.21612 10.9443 9.88411 10.9443Z"
    fill={color}
  />

</svg>

export default EquipmentIcon
