import { colors } from '@constants'
import { ErrorOutlineIcon } from 'icons'
import { is } from 'ramda'
import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

const { inputColors } = colors

const InputControlContainer = props => {
  const {
    children,
    className,
    disableBorder = false,
    disabled,
    error,
    focused = false,
    helperText = '',
    icon,
    label,
    onClick,
    required,
    withLegend,
  } = props

  const helpTextRef = useRef()

  useEffect(() => {
    // При изменении helperText - обновляем отступ Popup от Border
    const { updateHelperTextHeight } = props

    if(is(Function, updateHelperTextHeight)) {
      if(helperText && helpTextRef.current) {
        const { clientHeight } = helpTextRef.current
        updateHelperTextHeight(clientHeight)
      }
      else{updateHelperTextHeight(0)}
    }
  }, [helperText, props])

  return (
    <Container className={className}>
      <Border error={error} onClick={onClick} disabled={disabled} disableBorder={disableBorder}>
        <ContentContainer hasLabel={Boolean(label)} hasFocus={focused} withLegend={withLegend}>
          {label &&
            <Label focused={focused} error={error} withLegend={withLegend}>
              {label} {required && <Asterisk>*</Asterisk>}
            </Label>
          }
          {children}
        </ContentContainer>
        {Boolean(error) ?
          <IconContainer>
            <ErrorOutlineIcon color={inputColors.error} />
          </IconContainer>
          :
          Boolean(icon) && <IconContainer>{icon}</IconContainer>
        }
      </Border>
      { helperText && <HelperText ref={helpTextRef} error={error}>{helperText}</HelperText> }
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
`

const Border = styled.div`
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  background-color: ${inputColors.backgroundDefault};


  ${({ disableBorder }) => !disableBorder
    && css`
      border: 1px solid ${({ error }) => error ? inputColors.error : inputColors.borderDefault};
      border-radius: 2px;
    `
};

  ${({ disabled }) => disabled
    && css`
        background-color: ${inputColors.backgroundDisable};
        pointer-events: none;
        cursor: default;
    `
};

  &:focus-within {
    border-color: ${({ error }) => error ? inputColors.error : inputColors.borderFocus};
  }
`

const positionHandler = ({ hasLabel, hasFocus, withLegend }) => {
  /* hasFocus = focus + value */

  if(withLegend && hasLabel) {return 'flex-end'}

  if(!hasLabel){return 'center'}

  if(hasLabel && !hasFocus){return 'center'}

  if(hasLabel && hasFocus){return 'flex-end'}

  return 'center'
}

const ContentContainer = styled.div`
  display: flex;
  align-items: ${({ hasLabel, hasFocus, withLegend }) => positionHandler({ hasFocus, hasLabel, withLegend })};
  position: relative;
  width: calc(100% - 20px);
  height: 100%;
  margin: 0 0 0 20px;
  box-sizing: border-box;
`

const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  color: ${({ error }) => error ? inputColors.error : inputColors.labelDefault};
  font-size: ${({ focused, withLegend }) => focused || withLegend ? '12px' : '14px'};
  line-height: 1.2;
  border: none;
  transform: ${({ focused, withLegend }) => focused || withLegend ? 'translate(0, 10px)' : 'translate(0, 20px)'};
  transition: .1s ease all ${({ focused }) => focused ? '0s' : '.15s'};
  pointer-events: none;
`

const HelperText = styled.div`
  padding: 7px 0 0 20px;
  color: ${({ error }) => error ? inputColors.error : inputColors.helperTextDefault};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  box-sizing: border-box;
`

const Asterisk = styled.span`
  color: ${inputColors.important};
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  max-width: 40px;
  outline: none;
  overflow: hidden;
  cursor: pointer;
`

export default InputControlContainer
