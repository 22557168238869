import React from 'react'
import styled from 'styled-components'

const ButtonGroup = ({ children, className }) =>
  <ButtonGroupContainer className={className}>{children}</ButtonGroupContainer>

const ButtonGroupContainer = styled.div`
  display: flex;
  width: auto;

  & > button {
    border-radius: 0;
  }

  & > button:first-child {
   border-top-left-radius: 2px;
   border-bottom-left-radius: 2px;
  }

  & > button:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`

export default ButtonGroup
