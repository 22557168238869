import Accordion from 'components/Accordion'
import React, { useCallback } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeTree } from 'react-vtree'
import styled from 'styled-components'
import CheckBox from '../CheckBox'
import Search from '../Search'
import { css } from '../../constants'

const calcNestOffset = nestingLevel => nestingLevel * 16 + 32

export default React.forwardRef(function Tree({
  treeWalker, searchInputLabel, searchValue, setSearchValue, defaultWidht=200, defaultHeight=500 }, ref,
) {
  const Node = ({ data: { isLeaf, name, checked, onChange, nestingLevel, id }, height, isOpen, style, toggle }) => {
    const onChangeValueHandler = useCallback(e => {
      e.stopPropagation()
      onChange(id, !checked)
    })

    const onToggleHandler = useCallback((e, ...args) => {
      if(e && e.stopPropagation) {
        e.stopPropagation()
      }

      toggle(e, ...args)
    })

    return <Item style={style} height={height} nestingLevel={nestingLevel} key={id} isLeaf={isLeaf}>
      {!isLeaf ?
        <AccordionStyled
          title={<CheckBox
            semiChecked={!isLeaf}
            checked={checked}
            onChange={onChangeValueHandler}
            applyClickOnlyCheckbox
            label={<div onClick={onToggleHandler}>{name}</div>}/>
          }

          onToggle={onToggleHandler}
          isExpanded={isOpen}
        />

        : <CheckBox semiChecked={!isLeaf} checked={checked} onChange={onChangeValueHandler} label={name}/>}
    </Item>
  }

  return (
    <Root>
      <Pane><Search value={searchValue} onChange={setSearchValue} placeholder={searchInputLabel} /></Pane>
      <AutoSizer defaultWidht={defaultWidht} defaultHeight={defaultHeight}>
        {({ height, width }) =>
          <FixedSizeTreeStyled
            treeWalker={treeWalker}
            height={height - 115}
            width={width}
            itemSize={56}
            ref={ref}
            placeholder={<div>Loading...</div>}
          >
            {Node}
          </FixedSizeTreeStyled>}
      </AutoSizer>
    </Root>
  )
})

const FixedSizeTreeStyled = styled(FixedSizeTree)`
  ${css.scrollStyleMixin};
`

const Pane = styled.div`
  padding: 16px 0 16px 0;
  height: 91px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: end;
`

const Label = styled.div`
  margin-left: 0;
`

const AccordionStyled = styled(Accordion)`
  width: 100%
`

const Div = styled.div`
`

const Item = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: ${({ height }) => height || '56px'};
  padding-left: ${({ nestingLevel }) => `${calcNestOffset(nestingLevel)}px`};
  display: flex;
  background: ${({ isLeaf }) => isLeaf ? 'none' : '#F5F5F5'};
`

const Root = styled.div`
  flex-grow: 1;
  height: auto;
  min-height: 300px;
  width: 701px;
`
