import React from 'react'
import styled from 'styled-components'

const LeftActionBarBottom = props => {
  const { children } = props
  return (
    <Container>
      {children}
    </Container>
  )
}

const Container = styled.div`
  flex-grow: 0;
`

export default LeftActionBarBottom
