import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import Popup from '../Popup'

const defaultPopupOptions = {
  anchor   : { horizontal: 'left', vertical: 'bottom' },
  transform: { horizontal: 'left', vertical: 'top' },
}

export default function DropdownList(props) {
  const {
    className,
    children,
    disableOverlayClick,
    fitContent,
    isPopupOpen,
    listChildren,
    onHidePopup,
    anchorOrigin,
    transformOrigin,
    popupOffset,
    popupBackgroundColor = 'white',
    overflowY,
    width,
    maxHeight,
    boxShadow,
    border,
  } = props

  const [popupWidth, setPopupWidth] = useState(0)

  // TODO: popupHeight не используется, рефакторинг всего useState
  // const [popupHeight, setPopupHeight] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null)
  const [popupOptions, setPopupOptions] = useState(defaultPopupOptions)
  const containerRef = useRef()

  useEffect(() => {
    if(containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect()

      if(popupWidth !== width) {setPopupWidth(width)}
    }
  }, [popupWidth, isPopupOpen])

  useEffect(() => {
    setAnchorEl(containerRef.current)

    const popUpContainer = containerRef.current.nextSibling
    const popUpElem = popUpContainer.firstChild

    // Помещаем выпадающий список в DOM и скрываем его
    popUpContainer.style.display = 'block'
    popUpElem.style.display = 'block'
    popUpElem.style.visibility = 'visible'

    // Вычисляем и запоминаем высоту выпадающего списка
    const { clientHeight } = popUpElem

    // setPopupHeight(clientHeight);

    // Удаляем из inline style установленные свойства
    popUpContainer.style.display = ''
    popUpElem.style.visibility = ''
    popUpElem.style.display = ''


    const { bottom } = containerRef.current.getBoundingClientRect()
    const { innerHeight } = window


    // Если при выпадении список будет за границей окна - выбрасываем его наверх
    if(bottom + clientHeight > innerHeight) {
      setPopupOptions({
        anchor   : { horizontal: 'left', vertical: 'top' },
        transform: { horizontal: 'left', vertical: 'bottom' },
      })
    }else{
      setPopupOptions(defaultPopupOptions)
    }
  }, [anchorEl, isPopupOpen])

  const handleResize = useCallback(() => {
    if(!containerRef.current) { return }

    const { width } = containerRef.current.getBoundingClientRect()

    if(popupWidth !== width) {setPopupWidth(width)}
  }, [popupWidth])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  return (
    <>
      <Container className={className} ref={containerRef}>
        {children}
      </Container>
      <Popup
        disableOverlayClick={disableOverlayClick}
        isShown={isPopupOpen}
        onClickOutside={onHidePopup}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin ? anchorOrigin : popupOptions.anchor}
        transformOrigin={transformOrigin ? transformOrigin : popupOptions.transform}
        width={fitContent ? 'auto' : width || `${popupWidth}px` }
        popupOffset={popupOffset}
        overflowY={overflowY}
        maxHeight={maxHeight}
        boxShadow={boxShadow}
        border={border}
        backgroundColor={popupBackgroundColor}
      >
        {listChildren}
      </Popup>
    </>
  )
}

const Container = styled.div``
