import React from 'react'

const AccountsIcon = ({
  size = 24,
  color = '#CCCCCC',
}) => <svg
  width={size}
  height={size}
  viewBox='0 0 24 24'
>
  <path
    d="M16.5 12.001C17.88 12.001 18.99 10.881 18.99 9.50098C18.99 8.12098 17.88 7.00098 16.5 7.00098C15.12 7.00098 14 8.12098 14 9.50098C14 10.881 15.12 12.001 16.5 12.001ZM9 11.001C10.66 11.001 11.99 9.66098 11.99 8.00098C11.99 6.34098 10.66 5.00098 9 5.00098C7.34 5.00098 6 6.34098 6 8.00098C6 9.66098 7.34 11.001 9 11.001ZM16.5 14.001C14.67 14.001 11 14.921 11 16.751V19.001H22V16.751C22 14.921 18.33 14.001 16.5 14.001ZM9 13.001C6.67 13.001 2 14.171 2 16.501V19.001H9V16.751C9 15.901 9.33 14.411 11.37 13.281C10.5 13.101 9.66 13.001 9 13.001Z"
    fill={color}
  />
</svg>

export default AccountsIcon
