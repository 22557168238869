import React from 'react'

const ChartIcon = props => {
  const { size = 24, color = '#A1A1A1' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fillRule="evenodd"
          d="M12 20C13.1 20 14 19.1 14 18V6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6V18C10 19.1 10.9 20 12 20ZM6 20C7.1 20 8 19.1 8 18V14C8 12.9 7.1 12 6 12C4.9 12 4 12.9 4 14V18C4 19.1 4.9 20 6 20ZM16 18V11C16 9.9 16.9 9 18 9C19.1 9 20 9.9 20 11V18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default ChartIcon
