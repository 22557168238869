import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Select from 'components/Select'
import SelectItem from 'components/SelectItem'
import DataLegend from 'components/DateSelector/Data/DataLegend'

const DataSelector = props => {
  const { value, options, onChange, colors } = props
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [selectedValue, setValue] = useState(value)

  const handleItemClick = valueData => {
    setIsPopupOpen(false)
    const findData = options.find(item => item.type === valueData)
    onChange(findData)
  }

  useEffect(() => {
    if(value != null) {
      setValue(value)
    }else if(options && options.length) {
      setValue(options[0])
    }
  }, [value, setValue, options])

  return (
    options && options.length && selectedValue ?
      <>
        <DataLegend levels={selectedValue.levels} colors={colors} />
        <StyledSelect
          title={`${selectedValue.name}${selectedValue.measure ? `, ${selectedValue.measure}` : null}`}
          isPopupOpen={isPopupOpen}
          onShowPopup={() => setIsPopupOpen(true)}
          onHidePopup={() => setIsPopupOpen(false)}
        >
          {options.map(option =>
            <SelectItemStyled
              key={option.type}
              onClick={() => handleItemClick(option.type)}
            >
              {option.name}{option.measure ? `, ${option.measure}` : null}
            </SelectItemStyled>,
          )}
        </StyledSelect>
      </> : null
  )
}

const StyledSelect = styled(Select)`
  margin: 0 4px;

  & input {
    height: 36px;
    text-align: center;
  }

  & > div > div {
    height: 36px;
  }
`

const SelectItemStyled = styled(SelectItem)`
  padding: 8px;
`

export default DataSelector
