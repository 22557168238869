import { InputClearIcon } from 'icons'
import { is, isNil } from 'ramda'
import React, {
  InputHTMLAttributes,
  MutableRefObject,
  ReactComponentElement,
  useCallback, useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import InputControlContainer from '../../InputControlContainer'

const hasValue = inputValue => !(isNil(inputValue) || inputValue === '')

export interface InputChildComponent extends InputHTMLAttributes<HTMLInputElement> {
   ref: MutableRefObject<HTMLInputElement>
}

export interface InputBaseProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'children'> {
  containerClassName?: string
  disableBorder?: boolean
  disableClear?: boolean
  error?: boolean
  filter?: (arg: string) => string
  helperText?: string
  icon?: JSX.Element
  inputIsFocused?: boolean
  label?: string
  onEnter?: () => void
  onFocus?: () => void
  onChange?: (arg: string) => void
  postProcessValue?: (arg: string) => string
  withLegend?: boolean
  value: string
  children?: (props: InputChildComponent) => ReactComponentElement<any>
}

export const InputBase = React.forwardRef(({
  containerClassName,
  disableBorder = false,
  disableClear,
  disabled,
  error,
  filter,
  helperText,
  icon,
  inputIsFocused = false,
  label,
  onChange,
  onEnter,
  onFocus,
  onKeyPress,
  postProcessValue,
  required,
  value,
  withLegend,
  children,
  ...rest
}: InputBaseProps, inputRef: React.MutableRefObject<HTMLInputElement>) => {
  const [focused, setFocused] = useState(inputIsFocused)
  const innerInputRef = useRef(null)

  const onChangeHandler = useCallback(event => {
    let inputValue = event.target.value

    if(is(Function, filter)) {
      const filteredValue = filter(inputValue)

      if(filteredValue !== inputValue) {
        if(!Number.isNaN(filteredValue)) {inputValue = filteredValue}
        else{inputValue = ''}

        innerInputRef.current.value = inputValue
      }
    }

    if(is(Function, onChange)) {
      const resValue = is(Function, postProcessValue) ? postProcessValue(inputValue) : inputValue
      onChange(resValue)
    }
  }, [filter, onChange, postProcessValue])

  const onKeyHandler = useCallback(event => {
    if(event.key === 'Enter' && is(Function, onEnter)) {
      event.preventDefault()
      onEnter()
    }

    if(is(Function, onKeyPress)) {onKeyPress(event)}
  }, [onEnter, onKeyPress])

  const handleFocus = () => {
    setFocused(true)

    if(onFocus) {onFocus()}
  }

  const handleClear = useCallback(() => {
    if(is(Function, onChange)) {onChange('')}
  }, [onChange])

  useEffect(() => {
    if(inputRef) {
      inputRef.current = innerInputRef.current
    }
  }, [innerInputRef.current])

  return (
    <InputControlContainer
      className={containerClassName}
      disableBorder={disableBorder}
      disabled={disabled}
      error={error}
      focused={hasValue(value) || focused}
      helperText={helperText}
      icon={
        icon || value && !disabled && !disableClear && <ClearButton onClick={handleClear}><ClearIcon /></ClearButton>
      }
      label={label}
      onClick={() => innerInputRef.current.focus()}
      required={required}
      withLegend={withLegend}
    >
      {children({
        ref       : innerInputRef,
        value,
        onChange  : onChangeHandler,
        onKeyPress: onKeyHandler,
        onFocus   : handleFocus,
        onBlur    : () => setFocused(false),
        disabled,
        ...rest,
      })}
    </InputControlContainer>
  )
})

const ClearIcon = styled(InputClearIcon).attrs({ color: '#a1a1a1' })``

const ClearButton = styled.button`
  /* Растягиваем кнопку на всю высоту и длину контейнера, в который он помещается */
  height: 100%;
  width: 100%;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
`
