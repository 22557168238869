import { useCallback, useState } from 'react'

export default (initial = false) => {
  const [isShown, setIsShown] = useState(initial)
  const close = useCallback(() => setIsShown(false), [setIsShown])
  const open = useCallback(() => setIsShown(true), [setIsShown])
  const toggle = useCallback(() => setIsShown(!isShown), [setIsShown, isShown])

  return { close, isShown, open, toggle }
}
