import { InputBase, InputBaseProps } from 'components/Input/InputBase'
import InputWithLegend from './InputWithLegend'
import React from 'react'

interface InputLegendProps extends InputBaseProps {
  legend: string
  legendPosition: 'left' | 'right'
}

export function InputLegend(props: InputLegendProps) {
  return <InputBase {...props} withLegend={Boolean(props.legend)}>
    {innerProps => <InputWithLegend
      inputRef={innerProps.ref}
      legend={props.legend}
      onChangeHandler={innerProps.onChange}
      onKeyHandler={innerProps.onKeyPress}
      value={innerProps.value}
      legendPosition={props.legendPosition}
      {...innerProps}
    />}
  </InputBase>
}
