import React from 'react'

const FarmsIcon = ({ size = 24, color = '#ffffff' }) => <svg
  width={size}
  height={size}
  viewBox="0 0 24 24"
>
  <path
    d="M8.17 5.70001L1 10.48V21H6V13H10V21H15V10.25L8.17 5.70001Z"
    fill={color}
  />

  <path
    d="M10 3V4.51L12 5.84L13.73 7H15V7.85L17 9.19V11H19V13H17V15H19V17H17V21H23V3H10ZM19 9H17V7H19V9Z"
    fill={color}
  />
</svg>

export default FarmsIcon
