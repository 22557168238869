import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import IconButton from 'components/IconButton'
import { ArrowIcon } from 'icons'

const today = new Date()
const currentMonth = today.getMonth()
const currentYear = today.getFullYear()

const MonthChanger = props => {
  const { monthsTitles, year, month, onChangeMonth, onChangeYear, cbChangeMonth, maxCurrentDate } = props
  const [disableInc, setDisableInc] = useState(false)

  useEffect(() => {
    setDisableInc(maxCurrentDate && month === currentMonth && year === currentYear)
  }, [maxCurrentDate, month, year])

  const handleInc = e => {
    e.stopPropagation()

    if(maxCurrentDate) {
      if(month + 1 === currentMonth && year === currentYear) {
        setDisableInc(true)
      }
    }

    if(month < 11) {
      onChangeMonth(month + 1)

      if(cbChangeMonth) {cbChangeMonth({ year: year, month: month + 1 })}
    }else{
      onChangeMonth(0)
      onChangeYear(year + 1)

      if(cbChangeMonth) {cbChangeMonth({ year: year + 1, month: 0 })}
    }
  }

  const handleDec = e => {
    e.stopPropagation()

    if(maxCurrentDate) {
      if(disableInc) {
        setDisableInc(false)
      }
    }

    if(month > 0) {
      onChangeMonth(month - 1)

      if(cbChangeMonth) {cbChangeMonth({ year: year, month: month - 1 })}
    }else{
      onChangeMonth(11)
      onChangeYear(year - 1)

      if(cbChangeMonth) {cbChangeMonth({ year: year - 1, month: 11 })}
    }
  }

  const monthYearText = `${monthsTitles[month]} ${year}`

  return (
    <Container>
      <IconButton onClick={handleDec} icon={ArrowIcon} data-testid='button-dec' />
      <MonthYearText>{monthYearText}</MonthYearText>
      <IconButton onClick={handleInc} icon={ArrowIcon} disabled={disableInc} iconProps={{ direction: 'right' }} data-testid='button-inc' />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const MonthYearText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #202020;
  display: flex;
  justify-content: center;
  min-width: 110px;
`

export default MonthChanger
