import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { isNil } from 'ramda'

import InputControlContainer from 'components/InputControlContainer'

const hasValue = inputValue => {
  if(isNil(inputValue) || inputValue === ''){return false}

  return true
}

const Input = props => {
  const {
    label,
    helperText,
    error,
    onChange,
    value,
    disabled,
    required,
    onFocus,
    icon,
    updateHelperTextHeight,
    ...rest
  } = props

  const inputRef = useRef<HTMLInputElement | undefined>()
  const [focused, setFocused] = useState(false)

  const handleFocus = () => {
    setFocused(true)

    if(onFocus) {onFocus()}
  }

  return (
    <InputControlContainer
      label={label}
      helperText={helperText}
      onClick={() => inputRef.current && inputRef.current.focus()}
      focused={hasValue(value) || focused}
      required={required}
      disabled={disabled}
      error={error}
      icon={icon}
      updateHelperTextHeight={updateHelperTextHeight}
    >
      <TextBox
        focused={focused}
        ref={inputRef}
        value={value}
        type="text"
        onChange={e => onChange(e.target.value)}
        onFocus={handleFocus}
        onBlur={() => setFocused(false)}
        disabled={disabled}
        {...rest}
      />
    </InputControlContainer>
  )
}

export default Input

const TextBox = styled.input<{focused: boolean}>`
  margin: 0;
  padding: 0;
  flex: 1 1 100%;
  height: 40px;
  color: #202020;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  outline: none;
  z-index: ${({ focused }) => focused && 10000};

  &:disabled {
    color: #a1a1a1;
  }
`
