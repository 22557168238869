import React from 'react'
import styled, { keyframes } from 'styled-components'

const Spinner = ({ size, thickness, color, transparentBackground }) =>
  <Container transparentBackground={transparentBackground}>
    <RoundLoader
      size={size}
      thickness={thickness}
      color={color}
    />
  </Container>


export default Spinner

const defaultProps = {
  size                 : '64px',
  thickness            : '2px',
  color                : '#00f',
  transparentBackground: false,
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${({ transparentBackground }) => transparentBackground ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.3)'};
`

const RoundLoader = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: ${props => props.size || defaultProps.size};
  height: ${props => props.size || defaultProps.size};
  border: ${props => props.thickness || defaultProps.thickness} solid ${props => props.color || defaultProps.color};
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${rotate} .5s linear infinite;
`
