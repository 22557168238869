import React from 'react'

const HomeIcon = props => {
  const { size = 24, color = '#A1A1A1' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fillRule="evenodd"
          d="M19.6657 6.36396C19.5488 6.08181 19.4904 5.94074 19.4209 5.81075C19.1412 5.28742 18.7126 4.85879 18.1892 4.57908C18.0593 4.50961 17.9182 4.45118 17.636 4.33432L13.4351 2.59438C13.153 2.47753 13.0119 2.41911 12.8709 2.37633C12.3031 2.20411 11.6969 2.20413 11.1292 2.37638C10.9881 2.41917 10.8471 2.4776 10.565 2.59447L6.36474 4.33441C6.08268 4.45125 5.94166 4.50967 5.8117 4.57913C5.28853 4.85879 4.86 5.28727 4.58031 5.81042C4.51083 5.94037 4.4524 6.08139 4.33553 6.36343L2.28562 11.3107C2.22874 11.448 2.2003 11.5166 2.17547 11.586C2.07601 11.8638 2.0181 12.1549 2.00362 12.4497C2 12.5233 2 12.5976 2 12.7462V20.75C2 20.9823 2 21.0985 2.01921 21.1951C2.09812 21.5918 2.40822 21.9019 2.80491 21.9808C2.90151 22 3.01767 22 3.25 22H7C7.23233 22 7.34849 22 7.44509 21.9808C7.84178 21.9019 8.15188 21.5918 8.23079 21.1951C8.25 21.0985 8.25 20.9823 8.25 20.75V15.75C8.25 13.6788 9.93 12 12 12C14.0712 12 15.75 13.6788 15.75 15.75V20.75C15.75 20.9823 15.75 21.0985 15.7692 21.1951C15.8481 21.5918 16.1582 21.9019 16.5549 21.9808C16.6515 22 16.7677 22 17 22H20.75C20.9823 22 21.0985 22 21.1951 21.9808C21.5918 21.9019 21.9019 21.5918 21.9808 21.1951C22 21.0985 22 20.9823 22 20.75V12.7459C22 12.5973 22 12.523 21.9964 12.4495C21.9819 12.1548 21.924 11.8639 21.8247 11.5861C21.7999 11.5168 21.7714 11.4481 21.7146 11.3109L19.6657 6.36396Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default HomeIcon
