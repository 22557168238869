import React from 'react'
import styled, { keyframes } from 'styled-components'

type TSkeletonLinePops = {
  height?: string
  width?: string
}

type TSkeletonCirclePops = {
  diameter?: string
}

const loaderKeyframes = keyframes`
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
`

export const SkeletonLine = styled.div<TSkeletonLinePops>`
  height: ${({ height }) => height || '10px'};
  animation: ${loaderKeyframes} 1s linear infinite forwards;
  background: #eee;
  background-image: linear-gradient(to right, #eee 0%, #ccc 20%, #ddd 40%, #eee 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  border-radius: 3px;
  width: ${({ width }) => width || '100%'};
  margin: 5px 5px 5px 0;
  float: left;
`

export const SkeletonCircle = styled.div<TSkeletonCirclePops>`
  height: ${({ diameter }) => diameter || '50px'};
  animation: ${loaderKeyframes} 1s linear infinite forwards;
  background: #eee;
  background-image: linear-gradient(to right, #eee 0%, #ccc 20%, #ddd 40%, #eee 100%);
  background-repeat: no-repeat;
  background-size: ${({ diameter }) => `600px ${diameter || '50px'}`};
  border-radius: 50%;
  width: ${({ diameter }) => diameter || '50px'};
`
