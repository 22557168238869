import React, { forwardRef, PropsWithChildren, ReactNode, useCallback } from 'react';
import styled, { css } from 'styled-components';
import SortIcon from '../../icons/SortIcon';

type TSortOrder = 'asc' | 'desc' | undefined

type TBaseStyle = {
  maxWidth?: string
  minWidth?: string
  width?: string
}

type TSortable = {
  sort?: TSortOrder
  onChangeSort?: (id: string | number | Function, order: TSortOrder) => void
  id?: number | string
  sortBy?: number | string | Function
}

type THeaderCellProps = {
  children?: ReactNode
  className?: string
  onClick?: () => void
}

export function HeaderCell({ children, ...props }: PropsWithChildren<TBaseStyle & THeaderCellProps>) {
  return (
    <ExtendedHeaderCellContainer {...props}>
      {children}
    </ExtendedHeaderCellContainer>
  )
}

export default forwardRef<HTMLTableDataCellElement, PropsWithChildren<TBaseStyle> & THeaderCellProps>(
  function TableCell({ children, ...props }, ref) {
    return (
      <ExtendedTableCellContainer {...props} ref={ref}>
        {children}
      </ExtendedTableCellContainer>
    )
  },
)

export const FooterCell = styled.td`
  padding: 0 10px;
`

export function SortableHeaderCell({
  children, sort, onChangeSort, id, className, sortBy, maxWidth, minWidth, width,
}: THeaderCellProps & TSortable & TBaseStyle) {
  const onChangeSortHandler = useCallback(() => {
    onChangeSort(sortBy || id, !sort ? 'asc' : {
      asc : 'desc',
      desc: undefined,
    }[sort] as TSortOrder)
  }, [onChangeSort, sort, id, sortBy])

  return (
    <HeaderCell
      key={id}
      className={className}
      onClick={onChangeSortHandler}
      maxWidth={maxWidth}
      minWidth={minWidth}
      width={width}
    >
      <TitleContainer>
        <Title>{children}</Title>
      </TitleContainer>
      {sort && <StyledSortIcon><SortIcon size={20} direction={sort} /></StyledSortIcon>}
    </HeaderCell>
  )
}

const handleCellWidth = width => {
  if(!width || width <= 6){return '6em'}

  return `${width}em`
}


export const BaseHeaderCellContainer = styled.th`
  font-weight: normal;
  font-size: 14px;
  text-align: left;
`

export const BaseTableCellContainer = styled.td`
  color: #a0a0a0;
  font-size: 14px;
  vertical-align: middle;
`

const BaseStyle = css<TBaseStyle>`
  &:first-child {
    padding-left: 20px;
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 ${({ width }) => handleCellWidth(width)};
  min-width: ${({ minWidth }) => minWidth || '6em'};
  max-width: ${({ maxWidth }) => maxWidth || '20em'};
  text-align: left;
`

const ExtendedHeaderCellContainer = styled(BaseHeaderCellContainer)<TBaseStyle>`
  ${BaseStyle};
  word-break: break-word;
`

const ExtendedTableCellContainer = styled(BaseTableCellContainer)<TBaseStyle>`
  ${BaseStyle};
  white-space: pre-wrap;
  word-break: break-word;
`


const TitleContainer = styled.div`
  display: flex;
  flex-direction: inherit;
  flex-wrap: nowrap;
  cursor: pointer;
  height: auto;
`

const Title = styled.span`
  line-height: 16px;
`

const StyledSortIcon = styled.div`
  overflow: inherit;
`
