import React from 'react'

const defaultParam = {
  size : '24px',
  color: '#202020',
  align: 'left',
}

const HorizontalAlignIcon = (props = defaultParam) => {
  const {
    size = defaultParam.size,
    color = defaultParam.color,
    align = defaultParam.align,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {align === 'left' &&
        <path
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 8C3.45 8 3 7.55 3 7C3 6.45 3.45 6 4 6H20C20.55 6 21 6.45 21 7C21 7.55 20.55 8 20 8H4ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3.66667 18H14.3333C14.7 18 15 17.55 15 17C15 16.45 14.7 16 14.3333 16H3.66667C3.3 16 3 16.45 3 17C3 17.55 3.3 18 3.66667 18Z"
        />
      }

      {align === 'center' &&
        <path
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 18H13C13.55 18 14 17.55 14 17C14 16.45 13.55 16 13 16H11C10.45 16 10 16.45 10 17C10 17.55 10.45 18 11 18ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7ZM7 13H17C17.55 13 18 12.55 18 12C18 11.45 17.55 11 17 11H7C6.45 11 6 11.45 6 12C6 12.55 6.45 13 7 13Z"
        />
      }

      {align === 'stretch' &&
        <path
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 8C3.45 8 3 7.55 3 7C3 6.45 3.45 6 4 6H20C20.55 6 21 6.45 21 7C21 7.55 20.55 8 20 8H4ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18Z"
        />
      }
    </svg>
  )
}

export default HorizontalAlignIcon
