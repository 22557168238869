import React from 'react'

const WarningIcon = props => {
  const { size = 20, color = '#FF9900', className } = props
  return (
    <svg
      className={className}
      width={size}
      height={size}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66699 10.0001C1.66699 5.40008 5.39199 1.66675 9.99199 1.66675C14.6003 1.66675 18.3337 5.40008 18.3337 10.0001C18.3337 14.6001 14.6003 18.3334 9.99199 18.3334C5.39199 18.3334 1.66699 14.6001 1.66699 10.0001ZM10.8337 6.66675C10.8337 6.20841 10.4587 5.83341 10.0003 5.83341C9.54199 5.83341 9.16699 6.20841 9.16699 6.66675V10.0001C9.16699 10.4584 9.54199 10.8334 10.0003 10.8334C10.4587 10.8334 10.8337 10.4584 10.8337 10.0001V6.66675ZM10.0003 16.6667C6.31699 16.6667 3.33366 13.6834 3.33366 10.0001C3.33366 6.31675 6.31699 3.33341 10.0003 3.33341C13.6837 3.33341 16.667 6.31675 16.667 10.0001C16.667 13.6834 13.6837 16.6667 10.0003 16.6667ZM9.16699 12.5001V14.1667H10.8337V12.5001H9.16699Z"
        fill={color}
      />
    </svg>
  )
}

export default WarningIcon
