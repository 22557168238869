import React from 'react'

const SaveIcon = props => {
  const {
    size = 24,
    color = '#202020',
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.795 15.8749L5.325 12.4049C5.13817 12.2176 4.88452 12.1124 4.62 12.1124C4.35548 12.1124 4.10183 12.2176 3.915 12.4049C3.525 12.7949 3.525 13.4249 3.915 13.8149L8.095 17.9949C8.485 18.3849 9.115 18.3849 9.505 17.9949L20.085 7.41489C20.475 7.02489 20.475 6.39489 20.085 6.00489C19.8982 5.81764 19.6445 5.7124 19.38 5.7124C19.1155 5.7124 18.8618 5.81764 18.675 6.00489L8.795 15.8749Z" fill={color}/>
    </svg>
  )
}

export default SaveIcon
