import React, { ChangeEvent, KeyboardEvent, useEffect } from 'react'
import styled from 'styled-components'

type TInput = {
  inputRef: React.MutableRefObject<any>
  legend: string
  onChangeHandler: (e: ChangeEvent) => void
  onKeyHandler: (e: KeyboardEvent) => void
  value: string | number | readonly string[]
  legendPosition: 'left' | 'right'
}

const InputWithLegend = ({
  inputRef,
  legend,
  onChangeHandler,
  onKeyHandler,
  value,
  legendPosition = 'right',
}: TInput) => {
  useEffect(() => {
    if(legend) {
      inputRef.current.parentNode.dataset.value = value
    }
  }, [legend, value])

  return <React.Fragment>
    {legendPosition === 'left' && <StyledLegend position={legendPosition}>{legend}</StyledLegend>}

    <TextBox>
      <input
        onChange={onChangeHandler}
        onKeyPress={onKeyHandler}
        ref={inputRef}
        size={1}
        type='text'
        value={value}
      />
    </TextBox>

    {legendPosition === 'right' && <StyledLegend position={legendPosition}>{legend}</StyledLegend>}
  </React.Fragment>
}

export default InputWithLegend

const TextBox = styled.div`
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;

  &::after,
  input {
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    font: inherit;
    padding: 0 0.35em 0.75em 0;
    resize: none;
    appearance: none;
    border: none;
    background-color: transparent;
    box-sizing : border-box;
    outline: none;
    text-align: right;
    margin: 0 0 0 -5px;
    font-size: 14px;
  }

  &::after {
    content: attr(data-value) '';
    visibility: hidden;
    white-space: pre-wrap;
  }
`

const StyledLegend = styled.div<{position?: 'left' | 'right'}>`
  font-size: 14px;
  line-height: 2.8;
  margin-right: ${({ position }) => position === 'left' ? '5px' : 'inherit'};
}
`
