import React, { useCallback } from 'react'
import { phone } from '../../../formats'
import Input from '../Input'
import { InputBaseProps } from '../InputBase'

const getType = (value: string) => {
  if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
    return 'email'
  }

  const maskedValue = phone.phoneMask(value)

  if(phone.validate(maskedValue)
    && /\d+$/.test(value)
    && /79.+/.test(phone.onlyDigits(maskedValue))
  ) {
    return 'phone'
  }

  return undefined
}

interface TInputPhoneEmailProps extends InputBaseProps {
  handleOnBlur: (type: 'phone' | 'email') => void
}

export const InputPhoneEmail = ({
  handleOnBlur,
  value,
  ...props
}: TInputPhoneEmailProps) => {
  const onBlur = useCallback(() => {
    handleOnBlur(getType(value))
  }, [handleOnBlur, value])

  return (
    <Input
      {...props}
      onBlur={onBlur}
      value={value}
    />
  )}
