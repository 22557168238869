import React from 'react'

const mapSideRotate = {
  'bottom': 90,
  'left'  : 180,
  'right' : 0,
  'top'   : 270,
}

type TArrowIcon = {
  size?: number
  direction?: keyof typeof mapSideRotate
  color?: string
}

const ArrowIcon = (props: TArrowIcon) => {
  const {
    size = 20,
    direction = 'left',
    color = '#202020',
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 12"
      transform={`rotate(${mapSideRotate[direction]})`}
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        /* eslint-disable-next-line max-len */
        d="M1.00001 9.87498L4.88001 5.99498L1.00001 2.11498C0.812752 1.92814 0.70752 1.67449 0.70752 1.40998C0.70752 1.14546 0.812752 0.891807 1.00001 0.704976C1.39001 0.314976 2.02001 0.314976 2.41001 0.704976L7.00001 5.29498C7.39001 5.68498 7.39001 6.31498 7.00001 6.70498L2.41001 11.295C2.02001 11.685 1.39001 11.685 1.00001 11.295C0.620005 10.905 0.610005 10.265 1.00001 9.87498Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowIcon
