import React from 'react'

const defaultParam = {
  size : '18',
  color: '#202020',
}

const SearchIcon = (props = defaultParam) => {
  const {
    className,
    size = defaultParam.size,
    color = defaultParam.color,
  } = props

  return (
    <svg width={size} height= {size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1867 11.4713H12.9767L17.2167 15.7313C17.6267 16.1413 17.6267 16.8113 17.2167 17.2213C16.8067 17.6313 16.1367 17.6313 15.7267 17.2213L11.4767 12.9713V12.1813L11.2067 11.9013C9.8067 13.1013 7.8967 13.7213 5.8667 13.3813C3.0867 12.9113 0.8667 10.5913 0.5267 7.79133C0.00669983 3.56133 3.5667 0.00132873 7.7967 0.521329C10.5967 0.861329 12.9167 3.08133 13.3867 5.86133C13.7267 7.89133 13.1067 9.80133 11.9067 11.2013L12.1867 11.4713ZM2.4767 6.97133C2.4767 9.46133 4.4867 11.4713 6.9767 11.4713C9.4667 11.4713 11.4767 9.46133 11.4767 6.97133C11.4767 4.48133 9.4667 2.47133 6.9767 2.47133C4.4867 2.47133 2.4767 4.48133 2.4767 6.97133Z"
        fill={color}
      />
    </svg>

  )
}

export default SearchIcon
