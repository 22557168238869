import React from 'react'
import styled from 'styled-components'
import Button from '../Button'
import Spinner from '../Spinner'
import LogoIcon from './icons/LogoIcon'

type TLang = 'ru' | 'en'

type TLoader = {
  logoutHandler: () => void
  lang: TLang
}

export default function Loader({ logoutHandler, lang = 'ru' }: TLoader) {
  const lowerCaseLang = lang.toLowerCase() as TLang
  return <Wrapper>
    <LogoIcon lang={lowerCaseLang} />
    <Spinner />

    <ButtonLogout onClick={logoutHandler}>
      {lowerCaseLang === 'ru' ? 'Выйти' : 'Logout'}
    </ButtonLogout>
  </Wrapper>
}

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 100000;
`

const ButtonLogout = styled(Button)`
  position: absolute;
  right: calc(50% - 60px);
  bottom: 20px;
  width: 120px;
  height: 40px;
`
