import { ArrowIcon, AccountSwitch } from 'icons'
import React, { FC, SyntheticEvent, useCallback } from 'react'
import { FixedSizeNodeComponentProps } from 'react-vtree'
import styled from 'styled-components'
import { ExtendedData } from '../index'

const Node: FC<FixedSizeNodeComponentProps<ExtendedData>> = ({
  data: { isLeaf, node, nestingLevel, onSelectOption },
  isOpen,
  style,
  toggle,
}) => {
  const commonPadding = nestingLevel * 20 + 7

  const clickHandler = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    onSelectOption(node)
  }, [node, onSelectOption])

  const toggleHandler = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    toggle()
  }, [toggle])

  return <Row
    onClick={clickHandler}
    style={{
      ...style,
      paddingLeft: commonPadding + (isLeaf ? 10 : 0),
    }}
  >
    {!isLeaf &&
      <RoundButton onClick={toggleHandler}>
        <ArrowIcon direction={isOpen ? 'bottom' : 'right'} size={14} />
      </RoundButton>
    }

    {node.authorizationRequired && <AccountSwitchWrapper><AccountSwitch /></AccountSwitchWrapper>}
    <StyledName>{node.name}</StyledName>
  </Row>
}


export default Node

const AccountSwitchWrapper = styled.div`
  margin: 0 10px 0 -3px;
`

const RoundButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  min-width: 36px;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-right: 4px;
`

const StyledName = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #202020;
  cursor: pointer;
`

const Row = styled.div`
  align-items : center;
  display    : flex;
  cursor: pointer;

  &:hover {
    background-color: #F2F2F2;
  }
`
