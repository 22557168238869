import React from 'react'

const TechControlIcon = props => {
  const { size = 24, color = '#A1A1A1' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.2875 13.5835L15.7977 11.465C15.7994 11.4583 15.801 11.4517 15.8028 11.4451C15.9378 10.9407 16.3352 10.5433 16.8398 10.4082L18.3878 9.99336C18.1338 9.69047 17.7528 9.4975 17.3276 9.4975H16.8709C16.7544 9.13101 16.6063 8.77381 16.4292 8.43183L16.7523 8.10879C17.2918 7.56925 17.2918 6.69135 16.7523 6.1519L15.7757 5.17513C15.5143 4.91383 15.1668 4.76988 14.7972 4.76988C14.4276 4.76988 14.0801 4.91388 13.8187 5.17522L13.4956 5.49826C13.1536 5.32122 12.7964 5.17314 12.43 5.05653V4.59999C12.43 3.83702 11.8093 3.21634 11.0463 3.21634H9.66495C8.90198 3.21634 8.28125 3.83702 8.28125 4.59999V5.05657C7.91486 5.17318 7.55766 5.32122 7.21559 5.49831L6.8925 5.17518C6.63111 4.91388 6.28361 4.76993 5.91406 4.76993C5.54451 4.76993 5.197 4.91393 4.93557 5.17527L3.95893 6.15195C3.41944 6.6914 3.41944 7.5693 3.95893 8.10884L4.28197 8.43188C4.10488 8.77386 3.95684 9.13111 3.84028 9.49755H3.38365C2.62073 9.49759 2 10.1183 2 10.8813V12.2627C2 13.0256 2.62073 13.6463 3.38369 13.6463H3.84033C3.95689 14.0128 4.10492 14.3699 4.28202 14.712L3.95893 15.035C3.41939 15.5746 3.41939 16.4524 3.95893 16.992L4.93566 17.9686C5.1971 18.23 5.5446 18.374 5.91415 18.374C6.2837 18.374 6.6312 18.23 6.89255 17.9686L7.21563 17.6456C7.55756 17.8227 7.91477 17.9706 8.2813 18.0872V18.5439C8.2813 19.3069 8.90203 19.9277 9.66499 19.9277H10.8631C10.7522 19.6635 10.6939 19.3775 10.6938 19.0824C10.6938 18.4988 10.9209 17.9502 11.3336 17.5376L15.2875 13.5835ZM10.3556 15.2855C8.30795 15.2855 6.64202 13.6196 6.64202 11.572C6.64202 9.52424 8.30795 7.85826 10.3556 7.85826C12.4033 7.85826 14.0692 9.52424 14.0692 11.572C14.0692 13.6196 12.4033 15.2855 10.3556 15.2855Z" fill={color}/>
      <path d="M21.741 12.1537L21.7262 12.1388C21.6826 12.0951 21.6246 12.0725 21.5618 12.0706C21.5002 12.0706 21.4412 12.095 21.3977 12.1386L20.3952 13.1411C20.2418 13.2945 20.0378 13.379 19.8207 13.379C19.6036 13.379 19.3996 13.2945 19.2461 13.1409C19.0926 12.9875 19.008 12.7835 19.008 12.5664C19.0081 12.3493 19.0926 12.1453 19.246 11.9919L20.2485 10.9894C20.2921 10.9458 20.3165 10.8866 20.3164 10.8251C20.3164 10.7634 20.2918 10.7044 20.2482 10.6609L20.2336 10.6464C20.0663 10.479 19.8437 10.3868 19.607 10.3868C19.5297 10.3868 19.4526 10.3969 19.3779 10.417L17.3192 10.9687C17.0143 11.0504 16.7742 11.2904 16.6926 11.5951L16.6912 11.6008L16.1427 13.8783L12.0731 17.9477C11.7701 18.2507 11.6033 18.6537 11.6033 19.0823C11.6034 19.5108 11.7703 19.9136 12.0734 20.2167L12.1703 20.3136C12.4733 20.6167 12.8763 20.7836 13.3049 20.7836C13.7334 20.7836 14.1363 20.6168 14.4393 20.3138L18.5088 16.2444L20.7863 15.6959L20.7919 15.6944C21.0966 15.6128 21.3367 15.3728 21.4184 15.0679L21.9701 13.0091C22.0516 12.7044 21.9637 12.3765 21.741 12.1537Z" fill={color}/>
    </svg>
  )
}

export default TechControlIcon
