import React, { useCallback } from 'react'
import styled from 'styled-components'
import { range } from 'ramda'

import TimeItemSelector2 from './TimeItemSelector2'

const hoursOption = range(0, 24) // /^[0-2][0-9]$/
const minutesOptions = range(0, 60) // /^[0-5][0-9]$/
const secondsOptions = range(0, 60) // /^[0-5][0-9]$/

const TimeSelector = props => {
  const { maxHeight, value, onChange } = props
  const { hour, minute, second } = value

  const handleHourChange = useCallback(
    newHour => onChange({ ...value, hour: newHour }),
    [onChange, value],
  )

  const handleMinuteChange = useCallback(
    newMinute => onChange({ ...value, minute: newMinute }),
    [onChange, value],
  )

  const handleSecondChange = useCallback(
    newSecond => onChange({ ...value, second: newSecond }),
    [onChange, value],
  )

  return (
    <Container>
      <TimeItemSelector2
        value={hour}
        options={hoursOption}
        onChange={handleHourChange}
        maxHeight={maxHeight}
      />
      :
      <TimeItemSelector2
        value={minute}
        options={minutesOptions}
        onChange={handleMinuteChange}
        maxHeight={maxHeight}
      />
      :
      <TimeItemSelector2
        value={second}
        options={secondsOptions}
        onChange={handleSecondChange}
        maxHeight={maxHeight}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

export default TimeSelector

TimeSelector.defaultProps = {
  value: {
    hour  : 0,
    minute: 0,
    second: 0,
  },
}
