import React from 'react'

const defaultParam = {
  size : '24px',
  color: '#202020',
}

const TimeIcon = (props = defaultParam) => {
  const {
    size = defaultParam.size,
    color = defaultParam.color,
  } = props

  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 2.4C8.44183 2.4 8.8 2.75817 8.8 3.2V7.6155L12 10.4C12.345 10.676 12.4009 11.1794 12.1249 11.5245C11.8489 11.8695 11.3455 11.9254 11.0005 11.6494L7.2 8.3845V3.2C7.2 2.75817 7.55817 2.4 8 2.4Z"
        fill={color}
      />
    </svg>

  )
}

export default TimeIcon
