import React from 'react'
import styled from 'styled-components'

type TWrapper = {
  direction?: 'vertical' | 'horizontal'
  height: number
  width: number
}

type TProps = {
  icon: string
  src: string
  text: string
  title: string
} & TWrapper

export const AppLink = ({ direction = 'vertical', icon, height, src, text, title, width }: TProps) => <Wrapper
  direction={direction}
  height={height}
  href={src}
  rel='noopener noreferrer'
  width={width}
>
  <img src={icon} alt='' />
  <div>
    <InnerTitle>{title}</InnerTitle>
    <InnerText>{text}</InnerText>
  </div>
</Wrapper>


const Wrapper = styled.a<TWrapper>`
  align-items: center;
  border-radius: 2px;
  border: 1px solid #FFFFFF;
  color: #FEFEFE;
  display: flex;
  height: ${({ height }) => `${height}px`};
  text-decoration: none;
  width: ${({ width }) => `${width}px`};

  flex-direction: ${({ direction }) => direction === 'vertical' ? 'column' : 'row'};
  justify-content: ${({ direction }) => direction === 'vertical' ? 'center' : 'flex-start'};
  padding: ${({ direction }) => direction === 'vertical' ? '16px' : 'unset'};
  
  & > img {
    padding: ${({ direction }) => direction === 'vertical' ? '0 0 20px 0' : '13px 16px'};
  }
`

const InnerTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`

const InnerText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`
