import React, { useRef } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { useClickOutside } from '../../../hooks'
import Button from '../../Button'
import { PopupChild, PopupContainer } from '../../Popup'

function LangPicker({ baseUrl, t, currentLang, languages, onChangeLang }) {
  const ref = useRef()

  const [pickerState, setPickerState] = React.useState(false)
  const handleSwitchPickerState = () => setPickerState(!pickerState)

  useClickOutside(ref, handleSwitchPickerState)

  return (
    <>
      <PickerButton onClick={handleSwitchPickerState}>
        <img src={`${baseUrl}/img/svg/${currentLang}.svg`} alt={currentLang} />
        {t(currentLang)}
      </PickerButton>
      <CSSTransition
        in={pickerState}
        classNames="show"
        timeout={300}
        unmountOnExit
        mountOnEnter
      >
        <StyledContainer theme="dark" ref={ref}>
          {Object.keys(languages).map(lang =>
            <StyledItem key={lang} theme="dark" onClick={onChangeLang} data-value={lang}>
              <img src={`${baseUrl}/img/svg/${lang}.svg`} alt={lang} />
              {languages[lang].name}
            </StyledItem>,
          )}
          <Button
            variant="grey"
            onClick={handleSwitchPickerState}
          >
            {t('cancel')}
          </Button>
        </StyledContainer>
      </CSSTransition>
    </>
  )
}


export default LangPicker

const PickerButton = styled.div`
  cursor: pointer;
  margin-top: 27px;
  height: 32px;
  display: flex;
  align-items: center;
  img {
    width: 32px;
    margin-right: 12px;
  }
`

const StyledContainer = styled(PopupContainer)`
  position: fixed;
  bottom: 20px;
  width: 90%;
  button {
    padding: 15px;
    margin: 5px 12px;
    width: fill-available;
  }
`

const StyledItem = styled(PopupChild)`
  display: flex;
  align-items: center;
  img {
    width: 32px;
    margin-right: 12px;
  }
`
