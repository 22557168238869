import { ArrowDropDownIcon, InputClearIcon } from 'icons'
import React, { useState } from 'react'
import DropdownList from '../../DropdownList'
import styled from 'styled-components'

export default function Select(props) {
  const {
    fieldHeight,
    fieldWidth,
    children,
    disabled,
    title,
    isPopupOpen,
    onShowPopup,
    onHidePopup,
  } = props

  return (
    <StyledDropdownList
      listChildren={children}
      onHidePopup={onHidePopup}
      isPopupOpen={isPopupOpen}
      maxHeight={'400px'}
      {...props}
    >
      <Field
        fieldHeight={fieldHeight}
        fieldWidth={fieldWidth}
        disabled={disabled}
        onClick={disabled ? null : onShowPopup}
      >
        <Title>{title}</Title>
        <StyledArrow direction={isPopupOpen ? 'top' : 'bottom'} />
      </Field>
    </StyledDropdownList>
  )
}

const ArrowIcon = styled(ArrowDropDownIcon).attrs({ color: '#ffffff' })``

const StyledDropdownList = styled(DropdownList)`
  border-radius: 18px;
`

const Field = styled.div`
  align-items: center;
  background-color: #747474;
  border-radius: 2px;
  border: none;
  cursor: ${({ disabled }) => disabled ? 'unset' : 'pointer'};
  display: flex;
  height: ${({ fieldHeight }) => fieldHeight ? `${fieldHeight}px` : '40px'};
  justify-content: space-between;
  width: ${({ fieldWidth }) => fieldWidth ? `${fieldWidth}px` : '100%'};
`

const Title = styled.div`
  font-size: 14px;
  margin-left: 20px;
`

const StyledArrow = styled(ArrowIcon)`
  margin-right: 8px;
`
