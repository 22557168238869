import React, { useMemo } from 'react'
import styled from 'styled-components'

import DayItem from './DayItem'

import { genDayKey, getDaysByWeeks, getDaysColor } from './utils'

const today = new Date()
const currentDay = today.getDate()
const currentMonth = today.getMonth()
const currentYear = today.getFullYear()

const DaysPanel = props => {
  const { year, month, getIsSelected, onSelectDay, colorValues, withData, maxCurrentDate, levels, colors } = props

  const daysByWeeks = getDaysByWeeks(year, month)
  const currentDate = useMemo(() => year === currentYear && month === currentMonth, [year, month])

  const daysColor = colorValues && getDaysColor(colorValues, levels, colors)

  return (
    <Container data-testid='days-panel'>
      {daysByWeeks.map(week =>
        <WeekRow key={week.id}>
          {week.days.map(dayConfig =>
            <DayItem
              key={genDayKey(dayConfig)}
              day={dayConfig}
              isSelected={getIsSelected(dayConfig.id)}
              onSelect={onSelectDay}
              disabled={currentDate && maxCurrentDate && dayConfig.id > currentDay}
              dayColor={withData && colorValues && daysColor[dayConfig.id - 1] ? daysColor[dayConfig.id - 1] : null}
            />,
          )}
        </WeekRow>,
      )}
    </Container>
  )
}

const Container = styled.div``

const WeekRow = styled.div`
  display: flex;
`

export default DaysPanel
