import React from 'react'
import styled from 'styled-components'

type TExtendedTableBodyContainerProps = {
  height?: string
}

export default function TableBody({ children, ...props }) {
  return <ExtendedTableBodyContainer {...props}>{children}</ExtendedTableBodyContainer>
}

export const BaseTableBodyContainer = styled.tbody``

const ExtendedTableBodyContainer = styled(BaseTableBodyContainer)<TExtendedTableBodyContainerProps>`
  flex-grow: 1;
  height: ${({ height }) => height || 'auto'};
  box-sizing: border-box;
`
