import React from 'react'
import { TSVGIconProps } from '@types'
import { colors } from '@constants'

export function MenuIcon({ size = 24, className, color = colors.icons.PRIMARY }: TSVGIconProps) {
  return <svg width={24} height={size/24*25} className={className} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M4 8.42641C3.45 8.42641 3 7.97641 3 7.42641C3 6.87641 3.45 6.42641 4 6.42641H20C20.55 6.42641 21 6.87641 21 7.42641C21 7.97641 20.55 8.42641 20 8.42641H4ZM4 13.4264H20C20.55 13.4264 21 12.9764 21 12.4264C21 11.8764 20.55 11.4264 20 11.4264H4C3.45 11.4264 3 11.8764 3 12.4264C3 12.9764 3.45 13.4264 4 13.4264ZM4 18.4264H20C20.55 18.4264 21 17.9764 21 17.4264C21 16.8764 20.55 16.4264 20 16.4264H4C3.45 16.4264 3 16.8764 3 17.4264C3 17.9764 3.45 18.4264 4 18.4264Z"
      fill={color}
    />
  </svg>
}
