import React from 'react'

const mapDirectionRotate = {
  'right' : 0,
  'bottom': 90,
  'left'  : 180,
  'top'   : 270,
}

const ArrowEdgeIcon = props => {
  const {
    size = 20,
    direction = 'right',
    color = '#000',
  } = props

  return (
    <svg
      width={size}
      height={size}
      fill={color}
      transform={`rotate(${mapDirectionRotate[direction]})`}
      focusable="false"
      viewBox="0 0 24 24"
      role="presentation"
    >
      <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"/>
      <path fill="none" d="M0 0h24v24H0V0z"/>
    </svg>
  )
}

export default ArrowEdgeIcon



