import React from 'react'

const ArrowIcon = props => {
  const {
    size = 24,
    direction = 'asc',
  } = props

  return (
    <svg
      width={size}
      height={size * 2}
      focusable="false"
      viewBox="0 0 24 48"
      aria-hidden="true"
      role="presentation"
    >
      <path
        d="M8.70615 35.4137L11.2961 38.0037C11.6861 38.3937 12.3161 38.3937 12.7061 38.0037L15.2961 35.4137C15.9261 34.7837 15.4761 33.7037 14.5861 33.7037H9.40615C8.51615 33.7037 8.07615 34.7837 8.70615 35.4137Z"
        fill={direction !== 'asc' ? '#D10029' : '#C9CCD4'}
      />
      <path
        d="M8.70615 12.5862L11.2961 9.99624C11.6861 9.60624 12.3161 9.60624 12.7061 9.99624L15.2961 12.5862C15.9261 13.2162 15.4761 14.2962 14.5861 14.2962H9.40615C8.51615 14.2962 8.07615 13.2162 8.70615 12.5862Z"
        fill={direction === 'asc' ? '#D10029' : '#C9CCD4'}
      />
    </svg>
  )
}

export default ArrowIcon
