import React from 'react'
import styled from 'styled-components'
import { useWindowDimension } from '../../hooks'
import { AgrotronicLogo } from '../../icons'
import { screenResolutions } from '../../utils'
import { AppLink } from './AppLink'
import { AppLinkCircle } from './AppLinkCircle'

export const MobilePlaceholder = () => {
  const { width } = useWindowDimension()
  const isSmallScreen = width < parseInt(screenResolutions.PAL)

  return <Wrapper>
    <ContentWrapper isSmallScreen={isSmallScreen}>
      <AgrotronicLogo key="logo-1" lang="RU" withBackground />
      <Title isSmallScreen={isSmallScreen}>Web-версия не поддерживается на мобильных устройствах</Title>

      <Text isSmallScreen={isSmallScreen}>
        Web версия доступна только для ПК и планшетов, для использования на смартфонах пожалуйста установите наше мобильное приложение
      </Text>

      {isSmallScreen
        ? <LinksWrapperSmallScreen>
          <AppLinkCircle
            icon={'/img/png/logo-apple.png'}
            src={'https://apps.apple.com/ru/app/agrotronic/id1419663625'}
          />

          <AppLinkCircle
            icon={'/img/png/logo-playmarket.png'}
            src={'https://play.google.com/store/apps/details?id=com.rostelmash.agrotronic'}
          />

          <AppLink
            direction='horizontal'
            height={58}
            icon={'/img/png/ZUK.png'}
            src={'/app/agrotronic_mobile/agrotronic-mobile.apk'}
            text={'Агротроник'}
            title={'Загрузить APK'}
            width={184}
          />
        </LinksWrapperSmallScreen>


        : <LinksWrapper>
          <AppLink
            direction='vertical'
            height={143}
            icon={'/img/png/logo-apple.png'}
            src={'https://apps.apple.com/ru/app/agrotronic/id1419663625'}
            text={'App Store'}
            title={'Доступно в'}
            width={136}
          />

          <AppLink
            direction='vertical'
            height={143}
            icon={'/img/png/logo-playmarket.png'}
            src={'https://play.google.com/store/apps/details?id=com.rostelmash.agrotronic'}
            text={'Google Play'}
            title={'Доступно в'}
            width={136}
          />

          <AppLink
            direction='vertical'
            icon={'/img/png/ZUK.png'}
            height={143}
            src={'/app/agrotronic_mobile/agrotronic-mobile.apk'}
            text={'Агротроник'}
            title={'Загрузить APK'}
            width={136}
          />
        </LinksWrapper>}

      <Footer isSmallScreen={isSmallScreen}>
        <div>
          <Copyright>© 1929-2022 ОАО КЗ Ростсельмаш</Copyright>
          <img src='/img/png/logo.png' alt='logo'/>
          <Terms href='/doc/rights.html'>Пользовательское соглашение</Terms>
        </div>

        <div>
          <Hotline>Горячая линия Ростсельмаш</Hotline>
          <Tel href="tel:88002506004">8 (800) 250-60-04</Tel>
        </div>
      </Footer>
    </ContentWrapper>
  </Wrapper>
}

type TSizeScreen = {
  isSmallScreen: boolean
}

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background: url('/img/jpg/mobilePlaceholderBg.jpg') no-repeat center;
  background-size: cover;
  color: #FEFEFE;
  padding: 16px;
  font-family: 'Open Sans', sans-serif;
  background-color: #878787;
`

const ContentWrapper = styled.div<TSizeScreen>`
  width: ${({ isSmallScreen }) => isSmallScreen ? '288px' : '735px'};
`

const Title = styled.div<TSizeScreen>`
  font-weight: 700;
  font-size: ${({ isSmallScreen }) => isSmallScreen ? '24px' : '40px'};
  padding: 24px 0 8px 0;
`

const Text = styled.div<TSizeScreen>`
  font-weight: 400;
  font-size: ${({ isSmallScreen }) => isSmallScreen ? '14px' : '16px'};
`

const Copyright = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
`

const Hotline = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: 8px;
`

const Tel = styled.a`
  display: block;
  font-weight: 700;
  font-size: 26px;
  line-height: 35px;
  color: #FEFEFE;
  text-decoration: none;
  padding-top: 10px;
`

const Terms = styled(Tel)`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding-top: 20px;
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 0;
  gap: 10px;
  width: 428px;
  flex: none;
  flex-grow: 0;
  justify-content: center;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`

const LinksWrapperSmallScreen = styled.div`
  width: 184px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 16px;
  padding: 24px 0;
`

const Footer = styled.div<TSizeScreen>`
  display: flex;
  flex-direction: ${({ isSmallScreen }) => isSmallScreen ? 'column' : 'row'};
  align-items: ${({ isSmallScreen }) => isSmallScreen ? 'unset' : 'baseline'};
  justify-content: space-between;
`
