import React from 'react'

const France = ({ size = 24 }) => <svg
  width={size}
  height={size}
  viewBox='0 0 24 24'
>
  <path d="M8.25 3.75H15.75V20.25H8.25V3.75Z" fill="#E6E7E8"/>
  <path d="M0 16.125V7.875V5.75C0 4.64543 0.895431 3.75 2 3.75H3.75H8.25V20.25H3.75H2C0.895431 20.25 0 19.3546 0 18.25V16.125Z" fill="#1B75BB"/>
  <path d="M15.75 3.75H19.5H22C23.1046 3.75 24 4.64543 24 5.75V7.875V16.125V18.25C24 19.3546 23.1046 20.25 22 20.25H20.25H15.75V3.75Z" fill="#EC1C24"/>
</svg>

export default France
