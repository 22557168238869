import React from 'react'

const ZUKIcon = props => {
  const { className, size = 25, color = '#202020' } = props
  return (
    <svg
      className={className}
      width={size}
      height={size * 14 / 25}
      viewBox="0 0 50 28"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Canvas" transform="translate(-1781 -1040)">
        <g>
          <use xlinkHref="#ZUKIconpath0_fill" transform="translate(1781.4 1040)" fill={color} />
        </g>
      </g>
      <defs>
        <path id="ZUKIconpath0_fill" fillRule="evenodd" d="M 26.6021 22C 26.6021 17.582 30.184 14 34.6021 14L 38.058 14L 40.6021 0L 28.6021 0L 28.238 2L 2.23804 2L 0 14.3101L 15.478 17.75L 18.6021 24L 26.886 24C 26.718 23.358 26.6021 22.696 26.6021 22ZM 14.6021 24C 14.6021 26.208 12.8101 28 10.6021 28C 8.39404 28 6.60205 26.208 6.60205 24C 6.60205 21.792 8.39404 20 10.6021 20C 12.8101 20 14.6021 21.792 14.6021 24ZM 42.6021 22.0006L 42.6021 21.5146L 49.088 28.0006L 39.844 28.0006C 41.52 26.5326 42.6021 24.4026 42.6021 22.0006ZM 34.6021 16C 31.2881 16 28.6021 18.686 28.6021 22C 28.6021 25.314 31.2881 28 34.6021 28C 37.916 28 40.6021 25.314 40.6021 22C 40.6021 18.686 37.916 16 34.6021 16Z" />
      </defs>
    </svg>
  )
}

export default ZUKIcon
