export default {
  weekDays: {
    1: 'Пн',
    2: 'Вт',
    3: 'Ср',
    4: 'Чт',
    5: 'Пт',
    6: 'Сб',
    7: 'Вс',
  },
  months: {
    0 : 'Янв.',
    1 : 'Февр.',
    2 : 'Март',
    3 : 'Апр.',
    4 : 'Май',
    5 : 'Июнь',
    6 : 'Июль',
    7 : 'Авг.',
    8 : 'Сент.',
    9 : 'Окт.',
    10: 'Нояб.',
    11: 'Дек.',
  },
}
