import React from 'react'
import styled from 'styled-components'

const DataLegend = ({ levels, colors }) => {
  return (
    levels && levels.length ?
      <DataLegendWrapper>
        {levels.map((level, index) =>
          <DataLegendItem color={colors[index]} key={index}>
            {`<${level}`}
          </DataLegendItem>,
        )}
        <DataLegendItem color={colors[levels.length]}>
          {`>${levels[levels.length - 1]}`}
        </DataLegendItem>
      </DataLegendWrapper> : null
  )
}

const DataLegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`

const DataLegendItem = styled.span`
  width: 36px;
  padding: 5px 2px;
  text-align: center;
  margin: 0 5px;
  font-size: 14px;
  background-color: ${({ color }) => color};
  color: #ffffff;
  border-radius: 4px;
`

export default DataLegend
