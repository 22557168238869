import React from 'react'
import styled from 'styled-components'
import AgrotronicLogo from '../../icons/AgrotronicLogo'
import { colors } from '@constants'

const SiteControl = props => {
  return (
    <Container {...props}>
      <AgrotronicLogo
        lang={props.lang || 'ru'}
        withBackground={props.withBackground}
        color={colors.text.PRIMARY_INVERSION}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
`

export default SiteControl
