import React from 'react'

const defaultParam = {
  color: '#202020',
  size : '18px',
}

const DatabaseIcon = (props = defaultParam) => {
  const {
    size = defaultParam.size,
    color = defaultParam.color,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        // eslint-disable-next-line max-len
        d="M9.00197 0C4.90753 0 1.58813 1.20999 1.58813 2.70332V4.92155C1.58813 6.41507 4.90753 7.62468 9.00197 7.62468C13.0962 7.62468 16.4156 6.41488 16.4156 4.92155V2.70332C16.4156 1.20999 13.0962 0 9.00197 0Z"
      />
      <path
        fill={color}
        // eslint-disable-next-line max-len
        d="M9.00184 8.91739C5.09269 8.91739 1.86982 7.81358 1.588 6.41394C1.57457 6.48075 1.588 10.0772 1.588 10.0772C1.588 11.5709 4.90739 12.7806 9.00184 12.7806C13.0961 12.7806 16.4155 11.5706 16.4155 10.0772C16.4155 10.0772 16.4238 6.48056 16.4106 6.41394C16.1284 7.81377 12.9108 8.91739 9.00184 8.91739Z"
      />
      <path
        fill={color}
        // eslint-disable-next-line max-len
        d="M16.4084 11.6332C16.1268 13.0321 12.9109 14.1365 9.00175 14.1365C5.0926 14.1365 1.86898 13.0319 1.58716 11.6332L1.58792 15.2967C1.58792 16.7902 4.90731 17.9998 9.00175 17.9998C13.096 17.9998 16.4154 16.7898 16.4154 15.2967L16.4084 11.6332Z"
      />
    </svg>
  )
}

export default DatabaseIcon
