import { css } from
  'styled-components'

export const scrollStyleMixin = css`
  &::-webkit-scrollbar {
    width: 5px;
    right: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(209, 0, 41, 0.7);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #D10029;
  }
`
