import { colors } from '@constants'
import Tippy from '@tippyjs/react'
import React from 'react'
import styled from 'styled-components'
import ArrowDownIcon from '../../icons/ArrowDownIcon'
import { PopupChild, PopupContainer } from '../Popup'

const LangList = ({ onChangeLang, languages }) =>
  <StyledPopupContainer>
    {Object.keys(languages).map(L =>
      <PopupChildStyled key={L} onClick={() => onChangeLang(languages[L].locale)}>
        {languages[L].name}
      </PopupChildStyled>,
    )}
  </StyledPopupContainer>


const LangPicker = ({ onChangeLang, lang, languages }) =>
  <LangPickrItem>
    <span>{ lang }</span>
    <Tippy
      arrow={false}
      placement="bottom"
      interactive
      delay={[null, 200]}
      offset={[0, -122]}
      content={<LangList onChangeLang={onChangeLang} languages={languages} />}
    >
      <IconBox>
        <ArrowDownIcon />
      </IconBox>
    </Tippy>
  </LangPickrItem>


export default LangPicker

const StyledPopupContainer = styled(PopupContainer)`
  &:hover {
    background: ${colors.PRIMARY_BACKGROUND};
  }
`

const LangPickrItem = styled(PopupChild)`
  padding: 4px 0;
  position: relative;

  span {
    line-height: 24px;
    padding-left: 24px;
  }

  &:hover{
    background: ${colors.PRIMARY_HOVER};
  }
`

const PopupChildStyled = styled(PopupChild)`
  font-size: 14px;
`

const IconBox = styled.div`
  float: right;
  border-left: 1px solid ${colors.PRIMARY_OUTLINE};
  padding: 0px 16px 0px 8px;
  height: 24px;
  svg {
    line-height: 100%;
    border-radius: 50%;
    :hover {
      background-color: rgba(1, 1, 1, 0.1);
    }
  }
`
