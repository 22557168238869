import React, { PropsWithChildren, useCallback } from 'react'
import { CloseIcon } from 'icons'
import styled from 'styled-components'

export type TTagProps<P = any> = PropsWithChildren<{
  closeIconColor?: string
  onClose?: (arg: P) => void
  onClick?: (arg: P) => void
  value?: P
  title?: string
  className?: any
}>

const noop = () => {}

function Tag<P = any>({ className, closeIconColor='#D10029', title, children, onClose = noop, onClick = noop, value }: TTagProps<P>) {
  const onCloseHandler = useCallback(event => {
    event.stopPropagation()
    onClose(value)
  }, [onClose, value])

  const onClickHandler = useCallback(event => {
    event.stopPropagation()
    onClick(value)
  }, [onClick, value])

  return (
    <StyledTag className={className} onClick={onClickHandler}>
      {title || children}
      {onClose && onClose !== noop && <Button onClick={onCloseHandler}><CloseIcon color={closeIconColor} isBold size={24} /></Button>}
    </StyledTag>
  )
}

const Button = styled.span`
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 3px;
  cursor: pointer;
`

const StyledTag = styled.div`
  font-size: 14px;
  margin-left: 15px;
  padding: 0 5px 0 10px;
  display: flex;
  background: #FFEDED;
  color: #D10029;
  align-items: center;
  justify-items: center;
  height: 30px;
  border-radius: 2px;
  white-space: nowrap;
`

export default Tag
