import { isEmpty, isNil, propOr } from 'ramda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import Search from '../Search'
import Select from './Select'
import Tree from './Tree'

const createDataTree = (dataset: TAccount[], idCaption = 'id', parentIdCaption = 'parentId') => {
  let hashTable = {}

  dataset.forEach(data => hashTable[data[idCaption]] = { ...data, children: [] } )

  let dataTree = []

  dataset.forEach(data => {
    if(data[parentIdCaption] && hashTable[data[parentIdCaption]]) {
      hashTable[data[parentIdCaption]].children?.push(hashTable[data[idCaption]])
    } else {
      dataTree.push(hashTable[data[idCaption]])
    }
  })

  return dataTree
}

export type TAccount = {
  id: number
  login: string
  name: string
  parentId: number
  parentName: string
  typeId: number
  typeName: string
}

export type TAnotherAccount = TAccount & {
  authorizationRequired?: boolean
}


export type TDataTree = TAnotherAccount & {
  children?: TDataTree[]
}

type TProps = {
  accounts: TAccount[]
  anotherAccounts: TAnotherAccount[]
  fieldHeight?: number
  fieldWidth?: number
  disabled?: boolean
  listHeight: number
  listWidth?: number
  itemSize: number
  label?: string
  noData: string
  onSelectOption: (node: TAnotherAccount) => void
  overflowY?: string
  placeholder: string
  required?: boolean
  title: string
}

const SelectTree = ({
  accounts,
  anotherAccounts,
  fieldHeight,
  fieldWidth,
  disabled,
  itemSize,
  label,
  listHeight,
  listWidth,
  noData,
  onSelectOption,
  overflowY,
  placeholder,
  required,
  title,
}: TProps) => {
  const [showParentOrgList, setShowParentOrgList] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredAccounts, setFilteredAccounts] = useState(accounts)
  const treeContainer = useRef(null)

  const onHidePopupHandler = useCallback(event => {
    if(treeContainer.current.contains(event.target)) { return }

    if(showParentOrgList) {
      setShowParentOrgList(false)
      setSearch('')
      setFilteredAccounts(accounts)
    }
  }, [showParentOrgList])

  const onShowPopupHandler = useCallback(() => {
    setShowParentOrgList(true)
  }, [])

  const tree: TDataTree[] = useMemo(() => {
    const hashTable = createDataTree(filteredAccounts)

    if(!isEmpty(hashTable)) {
      return !isEmpty(search) ? hashTable : anotherAccounts.concat(hashTable)
    }

    return !isEmpty(search) ? [] : anotherAccounts
  }, [anotherAccounts, filteredAccounts])

  const handleSearch = useCallback((value?: string) => {
    const filteredItems = accounts.filter(item => isEmpty(value)
      || isNil(value)
      || propOr<'', typeof item, string>('', 'name', item).toLowerCase().includes(value.trim().toLowerCase()))

    setFilteredAccounts(filteredItems)
    setSearch(value)
  }, [])

  const onSelectHandle = useCallback((node: TAccount) => {
    onSelectOption(node)
    setShowParentOrgList(false)
  }, [onSelectOption])

  return <InputContainer listWidth={listWidth}>
    <StyledSelect
      fieldHeight={fieldHeight}
      fieldWidth={fieldWidth}
      disabled={disabled}
      isPopupOpen={showParentOrgList}
      label={label}
      onHidePopup={onHidePopupHandler}
      onShowPopup={onShowPopupHandler}
      overflowY={overflowY}
      required={required}
      title={title}
    >
      <div ref={treeContainer}>
        <SearchInputContainer>
          <Search onChange={handleSearch} placeholder={placeholder} value={search} />
        </SearchInputContainer>

        {!isEmpty(tree)
          ? <Tree
            height={listHeight}
            items={tree}
            itemSize={itemSize}
            onSelectOption={onSelectHandle}
          />

          : <NoData>{noData}</NoData>}
      </div>
    </StyledSelect>
  </InputContainer>
}

export default SelectTree

const InputContainer = styled.div<{ listWidth: number }>`
  width: ${({ listWidth }) => listWidth ? `${listWidth}px` : '384px' };

  & > div:nth-child(2) > ul{
    padding-top: 0;
    border-radius: 8px;
  }
`

const NoData = styled.div`
  padding: 10px 0 5px 0;
  text-align: center;
  color: #666666;
`

const StyledSelect = styled(Select) `
  border-radius: 8px;
`

const SearchInputContainer = styled.div`

  div > div {
    height: 48px;
    border-radius: 8px;
    background: transparent;
  }
`
