import React, { useCallback } from 'react'
import RadioButtonIcon from '../../icons/RadioButtonIcon'
import styled from 'styled-components'

type TFormRadioProps<P> = {
  className?: string
  size?: number
  label?: string
  checked?: boolean
  disabled?: boolean
  onChange?: (item?: P) => void
  item?: P
}

export default function RadioButton<P = any>(
  { label, checked, disabled = false, className, onChange, size, item }: TFormRadioProps<P>,
) {
  const onChangeHandler = useCallback(() => {
    if(onChange instanceof Function) { onChange(item) }
  }, [onChange, item])

  return (
    <Box className={className} disabled={disabled} onClick={disabled ? undefined : onChangeHandler}>
      <RadioButtonIcon
        size={size}
        disabled={disabled}
        checked={checked}
      />
      <span>{label}</span>
    </Box>
  )
}

interface TBoxProps extends React.HTMLProps<HTMLDivElement> {
  disabled?: boolean
  onClick?: () => void
}

const Box = styled.div<TBoxProps>`
  cursor: ${({ disabled }: TBoxProps) => disabled ? 'inherit' : 'pointer'};
  width: fit-content;
  display: flex;
  align-items: center;

  span {
    color: ${({ disabled }) => disabled ? '#a1a1a1' : '#202020'};
    padding-left: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`
