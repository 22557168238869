import React from 'react'

const ArrowDownIcon = props => {
  const {
    size = 24,
    color = '#202020',
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.12499 9.00002L12.005 12.88L15.885 9.00002C16.275 8.61002 16.905 8.61002 17.295 9.00002C17.685 9.39002 17.685 10.02 17.295 10.41L12.705 15C12.315 15.39 11.685 15.39 11.295 15L6.70499 10.41C6.51774 10.2232 6.41251 9.96954 6.41251 9.70502C6.41251 9.4405 6.51774 9.18685 6.70499 9.00002C7.09499 8.62002 7.73499 8.61002 8.12499 9.00002Z" fill={color}/>
    </svg>
  )
}

export default ArrowDownIcon
