import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import DropdownList from 'components/DropdownList'
import Button from 'components/Button'
import SelectItem from 'components/SelectItem'
import { ArrowDropDownIcon } from 'icons'

const RowsPerPage = props => {
  const {
    onChangeRowsPerPage,
    initialRowsPerPage,
    pages,
    rowsPerPageLabel,
  } = props

  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const hidePopup = useCallback(() => setIsPopupOpen(false), [setIsPopupOpen])
  const showPopup = useCallback(() => setIsPopupOpen(true), [setIsPopupOpen])

  const changeRowsPerPage = useCallback(item => {
    setRowsPerPage(item)
    onChangeRowsPerPage(item)
  }, [setRowsPerPage, onChangeRowsPerPage])

  const items = useMemo(() => pages
    .map(item =>
      <SelectItem key={item.value} onClick={() => changeRowsPerPage(item.value)}>
        {item.label}
      </SelectItem>,
    ), [changeRowsPerPage, pages])

  const selectedLabel = useMemo(
    () => pages.find(P => P.value === rowsPerPage).label,
    [pages, rowsPerPage],
  )

  return (
    <RowsOnPage>
      <RowsOnPageSelect>
        <DropdownList
          listChildren={items}
          onHidePopup={hidePopup}
          isPopupOpen={isPopupOpen}
        >
          <DropdownButton variant="border" width="auto" onClick={showPopup} minHeight="24px">
            <LeftIconText>{selectedLabel}</LeftIconText>
            <ArrowIcon direction="bottom" color="#a1a1a1" />
          </DropdownButton>
        </DropdownList>
      </RowsOnPageSelect>
      <RowsOnPageTitle>
        {rowsPerPageLabel}
      </RowsOnPageTitle>
    </RowsOnPage>
  )
}

export default RowsPerPage

const RowsOnPage = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

const RowsOnPageSelect = styled.div`
  margin-right: 16px;
`

const RowsOnPageTitle = styled.span`
  @media screen and (max-width: 800px) {
    display: none;
  }
  display: inline;
  white-space: nowrap;
  margin-right: 32px;
`

const DropdownButton = styled(Button)`
  padding: 4px;
  min-height: 24px;

  &:not(:disabled):hover {
    border-color: #202020;
  }

  &:active {
    background-color: transparent;
    border-color: #C9CCD4;
  }
`

const LeftIconText = styled.span`
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
`

const ArrowIcon = styled(ArrowDropDownIcon).attrs({ color: '#a1a1a1' })``
