import React from 'react'

export const SocialGroupIcon = ({
  size = 24,
  color = 'currentColor',
}) => <svg
  width={size}
  height={size}
  viewBox="0 0 24 25"
>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.99 8.42627C10.99 10.0863 9.66 11.4263 8 11.4263C6.34 11.4263 5 10.0863 5 8.42627C5 6.76627 6.34 5.42627 8 5.42627C9.66 5.42627 10.99 6.76627 10.99 8.42627ZM18.99 8.42627C18.99 10.0863 17.66 11.4263 16 11.4263C14.34 11.4263 13 10.0863 13 8.42627C13 6.76627 14.34 5.42627 16 5.42627C17.66 5.42627 18.99 6.76627 18.99 8.42627ZM8 13.4263C5.67 13.4263 1 14.5963 1 16.9263V18.4263C1 18.9763 1.45 19.4263 2 19.4263H14C14.55 19.4263 15 18.9763 15 18.4263V16.9263C15 14.5963 10.33 13.4263 8 13.4263ZM15.03 13.4763C15.38 13.4463 15.71 13.4263 16 13.4263C18.33 13.4263 23 14.5963 23 16.9263V18.4263C23 18.9763 22.55 19.4263 22 19.4263H16.82C16.93 19.1163 17 18.7763 17 18.4263V16.9263C17 15.4563 16.21 14.3463 15.07 13.5163C15.067 13.5132 15.0639 13.5093 15.0606 13.505C15.053 13.4951 15.0439 13.4832 15.03 13.4763Z" fill={color} />
</svg>
