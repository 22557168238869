import React from 'react'

const SprayerIcon = props => {
  const { className, size = 25, color = '#202020' } = props
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 31 31"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M27.5092 7.10669H27.4853L27.4615 7.10954L1.85707 10.1821C1.63773 10.2084 1.48125 10.4075 1.50757 10.6269C1.53389 10.8462 1.73304 11.0027 1.95238 10.9764L27.0092 7.96956V8.17347L16.9832 11.6034H10.0498C8.94523 11.6034 8.0498 12.4988 8.0498 13.6034L8.0498 14.6596L8.00198 14.6759H7.53771L6.00144 11.6034H3.95308V15.7001L7.02562 15.7001L8.0498 15.7001L9.07398 15.7001L9.58645 15.7001C11.5932 15.7001 13.3002 16.9825 13.9327 18.7727H19.3641C20.4687 18.7727 21.3641 17.8772 21.3641 16.7727V13.6034C21.3641 12.6765 20.7335 11.8968 19.8779 11.67L27.671 9.00395L28.0092 8.88827V8.53087V7.90669H28.0213V7.10669H27.5092ZM13.1175 20.277C13.1175 18.2796 11.4987 16.6608 9.50126 16.6608C7.50385 16.6608 5.88442 18.2796 5.88442 20.277C5.88442 22.2745 7.50385 23.8933 9.50126 23.8933C11.4987 23.8933 13.1175 22.2745 13.1175 20.277ZM29.4953 18.5555V19.7181L22.3888 18.465V17.3024L29.4953 18.5555Z" fill={color}/>
    </svg>
  )
}

export default SprayerIcon
