const screenResolutions = {
  PAL     : '768px',
  XGA     : '1024px',
  SXGA    : '1280px',
  notebook: '1366px',
  UXGA    : '1600px',
  desktop : '1920px',
}

export default screenResolutions
