import React from 'react'
import styled from 'styled-components'

import { CloseIcon } from '../../icons'
import IconButton from '../IconButton'

const LeftActionBarContent = props => {
  const {
    children,
    className,
    onClose,
    title,
  } = props

  return (
    <Container className={className} {...props}>
      {onClose &&
        <Close>
          <IconButton icon={CloseIcon} size={25} onClick={onClose} />
        </Close>
      }
      <Header>
        <Title>{title}</Title>
      </Header>
      <Body>
        {children}
      </Body>
      {/* {footer && (
        <Footer>
          {footer}
        </Footer>
      )} */}
    </Container>
  )
}

export default LeftActionBarContent


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 420px;
  position: relative;
  background-color: #fff;
  border-left: 1px solid #ECECEC;
  box-sizing: border-box;
  z-index: 999;
  overflow: hidden;
`

const Header = styled.div`
  padding: 25px;
  position: relative;
`

const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  min-height: 0;
  height: 100%;
`

// const Footer = styled.div`
//   padding-bottom: 25px;
//   padding-top: 20px;
// `;

const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
`
