import { createGlobalStyle } from 'styled-components'

const AppGlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src:
      local('Open Sans'),
      local('OpenSans-Regular'),
      url(/fonts/Open_Sans/subset-OpenSans-Regular.woff) format('font-woff'),
      url(/fonts/Open_Sans/subset-OpenSans-Regular.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src:
      local('Open Sans'),
      local('OpenSans-SemiBold'),
      url(/fonts/Open_Sans/subset-OpenSans-SemiBold.woff) format('font-woff'),
      url(/fonts/Open_Sans/subset-OpenSans-SemiBold.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src:
      local('Open Sans'),
      local('OpenSans-Bold'),
      url(/fonts/Open_Sans/subset-OpenSans-Bold.woff) format('font-woff'),
      url(/fonts/Open_Sans/subset-OpenSans-Bold.woff2) format('woff2');
  }

  html {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    background-color: #f5f5f5;
  }

  html, body, #root {
    height: 100%;
    padding: 0;
    margin: 0;
  }
`

export default AppGlobalStyles
