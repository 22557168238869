import React from 'react'

const SettingsIcon = props => {
  const { size = 24, color = '#323232', ...restProps } = props
  return (
    <svg
      width={size}
      height={size}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <g>
        <path
          d="M19.5023 12C19.5023 12.34 19.4723 12.66 19.4323 12.98L21.5423 14.63C21.7323 14.78 21.7823 15.05 21.6623 15.27L19.6623 18.73C19.5423 18.95 19.2823 19.04 19.0523 18.95L16.5623 17.95C16.0423 18.34 15.4823 18.68 14.8723 18.93L14.4923 21.58C14.4623 21.82 14.2523 22 14.0023 22H10.0023C9.75228 22 9.54228 21.82 9.51228 21.58L9.13228 18.93C8.52228 18.68 7.96228 18.35 7.44228 17.95L4.95228 18.95C4.73228 19.03 4.46228 18.95 4.34228 18.73L2.34228 15.27C2.22228 15.05 2.27228 14.78 2.46228 14.63L4.57228 12.98C4.53228 12.66 4.50228 12.33 4.50228 12C4.50228 11.67 4.53228 11.34 4.57228 11.02L2.46228 9.37C2.27228 9.22 2.21228 8.95 2.34228 8.73L4.34228 5.27C4.46228 5.05 4.72228 4.96 4.95228 5.05L7.44228 6.05C7.96228 5.66 8.52228 5.32 9.13228 5.07L9.51228 2.42C9.54228 2.18 9.75228 2 10.0023 2H14.0023C14.2523 2 14.4623 2.18 14.4923 2.42L14.8723 5.07C15.4823 5.32 16.0423 5.65 16.5623 6.05L19.0523 5.05C19.2723 4.97 19.5423 5.05 19.6623 5.27L21.6623 8.73C21.7823 8.95 21.7323 9.22 21.5423 9.37L19.4323 11.02C19.4723 11.34 19.5023 11.66 19.5023 12ZM8.50228 12C8.50228 13.93 10.0723 15.5 12.0023 15.5C13.9323 15.5 15.5023 13.93 15.5023 12C15.5023 10.07 13.9323 8.5 12.0023 8.5C10.0723 8.5 8.50228 10.07 8.50228 12Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default SettingsIcon
