import React from 'react'
import styled, { css } from 'styled-components'

type TSpacerProps = {
  size?: string | number
  horizontal?: boolean
  className?: string
}

const Spacer = ({ size, horizontal = false, className }: TSpacerProps) =>
  <Container size={size} horizontal={horizontal} className={className} />


const defaultProps = {
  height   : '100%',
  width    : '100%',
  minHeight: '10px',
  minWidth : '10px',
}

const Container = styled.div<TSpacerProps>`
  ${({ size, horizontal }) => horizontal
    ? css`
      height: ${size || defaultProps.minHeight};
      width: ${defaultProps.width};
    `
    : css`
      width: ${size || defaultProps.minWidth};
      height: ${defaultProps.height};
    `};
`

export default Spacer
