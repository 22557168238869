import React from 'react'

export const ContentHowToRegIcon = ({
  size = 24,
  color = 'currentColor',
}) => <svg
  width={size}
  height={size}
  viewBox="0 0 24 25"
>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6973 12.39C12.9073 12.39 14.6973 10.6 14.6973 8.39001C14.6973 6.18001 12.9073 4.39001 10.6973 4.39001C8.48727 4.39001 6.69727 6.18001 6.69727 8.39001C6.69727 10.6 8.48727 12.39 10.6973 12.39ZM11.6973 20.39L10.8373 19.53C9.65727 18.35 9.66727 16.43 10.8573 15.27L11.6973 14.45C11.3073 14.41 11.0173 14.39 10.6973 14.39C8.02727 14.39 2.69727 15.73 2.69727 18.39V20.39H11.6973ZM14.4573 20.17C14.8473 20.56 15.4873 20.56 15.8773 20.17L21.0173 14.99C21.3973 14.61 21.3973 13.99 21.0173 13.6L21.0073 13.59C20.6173 13.2 19.9873 13.2 19.5973 13.59L15.1673 18.05L13.7973 16.68C13.4173 16.29 12.7873 16.29 12.3973 16.68L12.3873 16.69C12.0073 17.07 12.0073 17.69 12.3873 18.08L14.4573 20.17Z" fill={color}/>
</svg>
