import React from 'react'

const AnaliticIcon = ({
  size = 24,
  color = '#FFFFFF',
}) => <svg
  width={size}
  height={size}
  viewBox='0 0 22 22'
>
  <path
    d="M22.8206 14.0275C22.9213 13.4515 22.4576 12.9506 21.8728 12.9506L14.3199 12.9506V12.9506C13.9857 12.9506 13.8183 13.3547 14.0547 13.591L14.3774 13.9138L19.3935 18.9299C19.8071 19.3435 20.4893 19.3171 20.8256 18.8385C21.8168 17.4275 22.512 15.7937 22.8206 14.0275Z"
    fill={color}
  />

  <path
    d="M8.90888 2.16983C9.4873 2.04661 10 2.51297 10 3.10436V11.9505C10 12.2158 10.1054 12.4701 10.2929 12.6577L16.5465 18.9113C16.9648 19.3295 16.9322 20.0217 16.436 20.3438C14.8708 21.3601 13.0039 21.9506 11 21.9506C5.48 21.9506 1 17.4705 1 11.9505C1 7.14765 4.39161 3.1321 8.90888 2.16983ZM12 3.10436C12 2.51296 12.5127 2.04661 13.0911 2.16983C16.9339 2.98841 19.9621 6.01662 20.7807 9.85945C20.9039 10.4379 20.4376 10.9505 19.8462 10.9505H13C12.4477 10.9505 12 10.5028 12 9.95055V3.10436Z"
    fill={color}
  />
</svg>

export default AnaliticIcon
