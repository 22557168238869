import React from 'react'

import WeekDaysTitlesHeader from './WeekDaysTitlesHeader'
import DaysPanel from './DaysPanel'

const DaySelector = props => {
  const {
    weekDaysTitles,
    month,
    year,
    getIsSelected,
    onSelectDay,
    colors,
    levels,
    colorValues,
    withData,
    maxCurrentDate,
  } = props

  return (
    <>
      <WeekDaysTitlesHeader
        weekDaysTitles={weekDaysTitles}
      />
      <DaysPanel
        year={year}
        month={month}
        getIsSelected={getIsSelected}
        onSelectDay={onSelectDay}
        colorValues={colorValues}
        withData={withData}
        maxCurrentDate={maxCurrentDate}
        colors={colors}
        levels={levels}
      />
    </>
  )
}

export default DaySelector
