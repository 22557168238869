import React from 'react'
import styled from 'styled-components'

type TExtendedTableContainer = {
  height?: string
  maxHeight?: string
}

export default function Table(props) {
  const {
    children,
    className,
    apperance = 'tiny',
  } = props

  return (
    <ExtendedTableContainer
      apperance={apperance}
      className={className}
      {...props}
    >
      {children}
    </ExtendedTableContainer>
  )
}

export const BaseTableContainer = styled.table`
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`

const ExtendedTableContainer = styled(BaseTableContainer)<TExtendedTableContainer>`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height || 'auto'};
  max-height: ${({ maxHeight }) => maxHeight || 'inherit'};

  /* Line height use for calculate multiline cut in table cell content */
  --lh: 18px;
  line-height: var(--lh);

  display: grid;
  box-sizing: border-box;

  // для корректной работы TruncateText нужно раскомментировать то что ниже
  //& > * {
  //  min-width: 0;
  //}
`
