import React, { useCallback } from 'react'
import { is } from 'ramda'
import styled from 'styled-components'
import { TDefaultItem } from './'

export default function DefaultItem<P>({ isSelected, onClick, value, children }: TDefaultItem<P>) {
  const itemClick = useCallback(
    e => {
      if(is(Function, onClick)) {
        onClick(value)
      }

      e.preventDefault()
    }, [value, onClick])

  return <StyledItem onClick={itemClick} isSelected={isSelected}>{children}</StyledItem>
}

const StyledItem = styled.div<{ isSelected: boolean }>`
  display: block;
  padding: 8px 24px;
  white-space: nowrap;
  cursor: pointer;
  z-index: 12;
  color: ${({ isSelected }) => isSelected ? '#fff' : '#202020'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background-color: ${props => props.isSelected ? '#6d6d6d' : 'transparent'};

  &:hover {
    background-color: rgba(0, 0, 0, .08);
  }
`
