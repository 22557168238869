import React from 'react'

const Germany = ({ size = 24 }) => <svg
  width={size}
  height={size}
  viewBox='0 0 24 24'
>
  <path d="M0 9.375H24V14.625H0V9.375Z" fill="#EC1C24"/>
  <path d="M20.25 3.75H3.75H2C0.895431 3.75 0 4.64543 0 5.75V7.875V9.375H24V7.875V5.75C24 4.64543 23.1046 3.75 22 3.75H20.25Z" fill="#202020"/>
  <path d="M3.75 20.25H20.25H22C23.1046 20.25 24 19.3546 24 18.25V16.125V14.625H0V16.125V18.25C0 19.3546 0.895431 20.25 2 20.25H3.75Z" fill="#F9CB38"/>
</svg>

export default Germany
