export const timezoneOffset = new Date().getTimezoneOffset() / 60 * -1
export const apiTimestampRegexp = /^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{3})$/;

export const parseDateTimeFromApiToUTC = (dateApiFormat: string): number => {
  const [, year, month, day, hours, mins, seconds, ms] = apiTimestampRegexp.exec(dateApiFormat);
  return Date.UTC(2000 + +year, +month - 1, +day, +hours, +mins, +seconds, +ms)
}

export const parseDateTimeFromApi = (dateApiFormat: string): Date => {
  return new Date(parseDateTimeFromApiToUTC(dateApiFormat))
}

export const parseDateTimeFromCalendarApi = (dateApiFormat: string): Date => {
  const [, year, month, day, hours, mins, seconds, ms] = apiTimestampRegexp.exec(dateApiFormat);
  return new Date(2000 + +year, +month - 1, +day, +hours, +mins, +seconds, +ms)
}
