import React, { useCallback } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { ArrowRightIcon, ExitIcon } from '../../icons'
import Avatar from '../Avatar'
import { colors } from '../../constants'

export { default as LangPicker } from './LangPicker'

function MobileMenu({
  baseUrl,
  children,
  info,
  t,
  langPicker,
  onNavigate,
  items,
  setMenuState,
  menuState,
  logout,
  profileLink,
}) {
  const handleSwitchMenuState = useCallback(() => {
    document.body.style.overflow = menuState ? '' : 'hidden'
    setMenuState(!menuState)
  }, [setMenuState, menuState])

  const handleGoTo = useCallback(e => {
    if(e.currentTarget.dataset.external) {
      window.location.href = e.currentTarget.dataset.to
    }else{
      onNavigate(e.currentTarget.dataset.to)
    }

    handleSwitchMenuState()
  }, [onNavigate, handleSwitchMenuState])

  return (
    <>
      <button
        type="button"
        onClick={handleSwitchMenuState}
        className={`hamburger hamburger--squeeze ${menuState && 'is-active'}`}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>

      <CSSTransition
        in={menuState}
        classNames="show"
        timeout={300}
        unmountOnExit
        mountOnEnter
      >
        <MenuContainer>
          <MenuItem
            data-to={profileLink}
            onClick={handleGoTo}
            key="top"
          >
            <ProfileBlock>
              <Avatar src={info.photo} size="40px" />

              <UserNameContainer>
                <p>{info.name}</p>
                <span>{info.email}</span>
              </UserNameContainer>
              <ArrowRightIcon color="#fff" />
            </ProfileBlock>
          </MenuItem>
          {items.map(item => item &&
            <MenuItem
              onClick={handleGoTo}
              data-external={item.external}
              data-to={item.linkTo}
              key={item.label}
            >
              {item.icon}
              <p>{t(item.label)}</p>
            </MenuItem>,
          )}
          <MenuItem onClick={logout} key="bottom">
            <ExitIcon color="#fff" />
            <p>{t('sign_out')}</p>
          </MenuItem>
          {children &&
          <InteractiveItem>
            { children }
          </InteractiveItem>
          }
          <Footer>
            <p>{t('Download app for any device')}</p>
            <DownloadWrapper>
              <a href="https://apps.apple.com/ru/app/agrotronic/id1419663625" rel="noopener noreferrer">
                <img src={`${baseUrl}/img/svg/apple_appstore.svg`} alt="AppStore" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.rostelmash.agrotronic" rel="noopener noreferrer">
                <img src={`${baseUrl}/img/svg/google_play.svg`} alt="Google Play" />
              </a>
            </DownloadWrapper>
            {langPicker}
          </Footer>
        </MenuContainer>
      </CSSTransition>
    </>
  )
}

export default MobileMenu

const DownloadWrapper = styled.div`
  display: flex;
`

const TextWrapper = styled.div`
  margin-left: 9px !important;
  text-align: center;
`

const DownloadApkWrapper = styled.a`
  border-radius: 12px;
  min-width: 156px;
  height: 42px;
  border: 1px solid #BCBCBC;
  display: inline-flex;
  font-size: unset;
  color: #8F8F8F;
  text-decoration: none;
  margin-right: 0 !important;
  padding-top: 5px !important;

    & svg {
      margin: 3px 0 0 10px;
      height: 25px;
    }
`

const StyledAgrodriveTitle = styled.div`
  font-size: 10px !important;
  font-weight: 400;
`

const MenuContainer = styled.div`
  * {
    box-sizing: border-box;
  }
  position: fixed;
  top: 60px;
  z-index: 9999;
  background-color: #202020;
  width: 100vw;
  height: calc(100vh - 60px);
  overflow-y: auto;
`

const MenuItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${colors.LIGHT_FONT};
  text-decoration: none;
  min-height: 48px;
  padding: 10px 20px;
  p {
    padding-left: 20px;
    font-size: 16px;
  }
  span {
    padding-left: 20px;
  }
  :hover {
    background-color: ${colors.BACKGROUND_DARK};
  }
`

const ProfileBlock = styled.div`
  display: flex;
  background-color: #333333;
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;
  width: 100%;
  align-items: center;
`

const InteractiveItem = styled.div`
  padding: 16px;
  width: 100%;
`

const Footer = styled.footer`
  border-top: 1px solid ${colors.SECONDARY_FONT};
  margin-top: 10px;
  padding: 20px;
  position: relative;
  bottom: 0;
  width: 100%;
  p {
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 21px;
  }
  a {
    margin-right: 18px;
  }
`

const UserNameContainer = styled.div`
  width: 100%;
`
