import { TSVGIconProps } from '@types'
import React from 'react'

export function InfoIcon({ size = 20, className, color = '#323232' }: TSVGIconProps) {
  return <svg width={size} height={size} className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"

      // eslint-disable-next-line max-len
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 5V7H11V5H9ZM11 14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"

      fill={color}
    />
  </svg>
}
