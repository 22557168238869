import React from 'react'
import styled from 'styled-components'

type TProps = {
  icon: string
  src: string
}

export const AppLinkCircle = ({ icon, src }: TProps) => <Wrapper
  href={src}
  rel='noopener noreferrer'
>
  <img src={icon} alt='' />
</Wrapper>


const Wrapper = styled.a`
  align-items: center;
  border-radius: 150px;
  border: 1px solid #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: center;
  width: 64px;
`
