import { groupBy, head, inc, is, isNil, mapObjIndexed, pipe, prop, slice } from 'ramda'
import { parseDateTimeFromApi, parseDateTimeFromApiToUTC, parseDateTimeFromCalendarApi } from './api'

export const dateToString = (date: Date): string => date.toString()
export const C_INVALID_DATE = Symbol('Invalid Date')

export const langToLocale = (lang?: string) => {
  if(lang?.length > 2){return lang}

  return {
    de: 'de-DE',
    en: 'en-US',
    fr: 'fr-FR',
    ru: 'ru-RU',
  }[(lang || 'ru').toLocaleLowerCase()] || []
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const humanDateViewFormatter = (date: Date, lang: string) => {
  const parts = mapObjIndexed(pipe(head, prop('value')), groupBy(
    prop('type'),
    new Intl.DateTimeFormat(langToLocale(lang), { day: 'numeric', month: 'short', year: 'numeric' })
      .formatToParts(date),
  ))

  return `${capitalizeFirstLetter(String(parts.month).replace(/\.$/, ''))} ${parts.day}, ${parts.year}`
}

export const dateViewFormatter = (date: Date, lang: string, options = {}) =>
  new Intl.DateTimeFormat(
    langToLocale(lang),

    // @ts-ignore пока rt2 не поддердживает dateStyle в DateTimeFormat
    { dateStyle: 'short', ...options },
  ).format(date)

export const timeFormatter = (date: Date, lang: string) =>
  date.toLocaleString(langToLocale(lang), { hour: 'numeric', minute: 'numeric' })

export const dateTimeViewFormatter = (date: Date, lang: string, options = {}) =>
  `${dateViewFormatter(date, lang, options)} ${timeFormatter(date, lang)}`

const callMethod = method => obj => obj[method]()
const pad2 = str => str.padStart(2, '0')
const getDate = pipe(callMethod('getDate'), String, pad2)
const getMonth = pipe(callMethod('getMonth'), inc, String, pad2)
const getYear = pipe(callMethod('getFullYear'), String, slice(2, Infinity))
const getFullYear = pipe(callMethod('getFullYear'), String)

export const YYMMDDHHmmssFormatter = (date: Date) => [getYear, getMonth, getDate ].map(F => F(date)).join('')
  + ['getHours', 'getMinutes', 'getSeconds'].map(M => String(date[M]()).padStart(2, '0')).join('')

export const YYMMDDHHmmss000Formatter = (date: Date) => YYMMDDHHmmssFormatter(date)
  + String(date.getMilliseconds()).padStart(3, '0')

export const knownFormatFormatter = (date: Date, format: string | undefined) => {
  if(isNil(date) || !is(Object, date) || !date.getFullYear) {
    return C_INVALID_DATE
  }

  switch(format){
    case 'DD.MM.YY':
      return [getDate, getMonth, getYear].map(F => F(date)).join('.')

    case 'DD.MM.YYYY':
      return [getDate, getMonth, getFullYear].map(F => F(date)).join('.')

    case 'YYMMDD':
      return [getYear, getMonth, getDate].map(F => F(date)).join('')

    case 'HH:mm:ss':
      return ['getHours', 'getMinutes', 'getSeconds'].map(M => String(date[M]()).padStart(2, '0')).join(':')

    case 'dd':
      return new Intl.DateTimeFormat('en', { weekday: 'short' }).format(date).slice(0, 2)

    case 'YYMMDDHHmmss000':
      return YYMMDDHHmmss000Formatter(date)

    case 'YYMMDDHHmmss':
      return YYMMDDHHmmssFormatter(date)

    case 'DD.MM.YY HH:mm:ss':
      return `${knownFormatFormatter(date, 'DD.MM.YY')} ${knownFormatFormatter(date, 'HH:mm:ss')}`

    case undefined:
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
  }

  throw `Unknown format "${format}"`
}


// Расширение системного класса Date функциями для смещения часового пояса в 2 состояния: UTC, и пользовательское смещение,
// которое задается в часах относительно UTC времени
export const extendDate = (gmt: number) => {
  // Для методов которые должны возвращать значение с учетом часового пояса требуется враппер для корректной работы
  // Враппер для userDate и utcDate формирует объект даты из текущего значения в UTC и вызывает метод для него
  // для локальных дат вызывается оригинальный метод
  ['toISOString', 'getUTCDate', 'getUTCDay', 'getUTCFullYear', 'getUTCHours', 'getUTCMilliseconds', 'getUTCMinutes',
    'getUTCMonth', 'getUTCSeconds', 'setUTCDate', 'setUTCFullYear', 'setUTCHours', 'setUTCMilliseconds',
    'setUTCMinutes', 'setUTCMonth', 'setUTCSeconds', 'toJSON', 'toGMTString', 'toUTCString']
    .filter(M => Date.prototype[M])

    .forEach(M => {
      if(!Date.prototype[`__${M}`]) {
        Object.defineProperties(Date.prototype, {
          [`__${M}`]: { value: Date.prototype[M] || Date.prototype[M] },
        })
      }

      Object.defineProperties(Date.prototype, {
        [M]: {
          configurable: true,
          enumerable  : false,

          value: function (...args) {
            const utcDate = this.utc

            return (!this.userDate && !this.utcDate ? this : new Date(Date.UTC(
              utcDate.getFullYear(),
              utcDate.getMonth(),
              utcDate.getDate(),
              utcDate.getHours(),
              utcDate.getMinutes(),
              utcDate.getSeconds(),
              utcDate.getMilliseconds(),
            )))[`__${M}`](...args)
          },
        },
      })
    })

  // Парсеры создают объек Date из прочих форматов, в частности строк
  Object.defineProperties(Date, {

    // Длительность минуты в мс
    DUR_SECOND: {
      configurable: true,
      enumerable  : false,
      value       : 1000,
      writable    : false,
    },

    // Длительность минуты в мс
    DUR_MINUTE: {
      configurable: true,
      enumerable  : false,
      value       : 60000,
      writable    : false,
    },

    // Длительность часа в мс
    DUR_HOUR: {
      configurable: true,
      enumerable  : false,
      value       : 3600000,
      writable    : false,
    },

    // Длительность дня в мс
    DUR_DAY: {
      configurable: true,
      enumerable  : false,
      value       : 86400000,
      writable    : false,
    },

    // Длительность недели в мс
    DUR_WEEK: {
      configurable: true,
      enumerable  : false,
      value       : 604800000,
      writable    : false,
    },

    parseDateTimeFromCalendarApi: {
      configurable: true,
      enumerable  : false,
      value       : parseDateTimeFromCalendarApi,
      writable    : false,
    },

    parseDateTimeFromApiToUTC: {
      configurable: true,
      enumerable  : false,
      value       : parseDateTimeFromApiToUTC,
      writable    : false,
    },

    parseDateTimeFromApi: {
      configurable: true,
      enumerable  : false,
      value       : parseDateTimeFromApi,
      writable    : false,
    },
  })

  // Манипуляции с датами
  Object.defineProperties(Date.prototype, {
    subtract: { configurable: true, enumerable  : false, value       : function (value, type) {
      return this.add(value * -1, type)
    } },

    add: { configurable: true, enumerable  : false, value       : function (value, type) {
      const typeMaps = {
        day    : Date.DUR_DAY,
        days   : Date.DUR_DAY,
        hour   : Date.DUR_HOUR,
        hours  : Date.DUR_HOUR,
        week   : Date.DUR_WEEK,
        weeks  : Date.DUR_WEEK,
        min    : Date.DUR_MINUTE,
        mins   : Date.DUR_MINUTE,
        minute : Date.DUR_MINUTE,
        minutes: Date.DUR_MINUTE,
      }

      if(type === 'month') {
        return this.new(this.setMonth(this.getMonth() + value))
      }

      if(!typeMaps[type]) {
        throw `Unsupported duration type '${type}'`
      }

      return this.new(Number(this) + typeMaps[type] * value)
    } },

    startOf: { configurable: true, enumerable  : false, value       : function (type) {
      switch(type) {
        case 'day':
          return this.new(new Date(this).setHours(0,0,0,0))

        case 'week':
          // @ts-ignore
          return this.new(this - (this.getDay() - 1) * Date.DUR_DAY).startOf('day')

        case 'month':
          // @ts-ignore
          return this.new(new Date(this).setDate(1)).startOf('day')

        case 'year':
          return this.new(new Date(this).getFullYear(), 0, 1, 0, 0, 0, 0)
      }

      throw `Unsupported startOf type '${type}'`
    } },

    endOf: { configurable: true, enumerable  : false, value       : function (type) {
      switch(type) {
        case 'day':
          return this.new(new Date(this).setHours(23,59,59,999))

        case 'month':
          // @ts-ignore
          return this.new(new Date(new Date(this).setMonth(this.getMonth() + 1)).setDate(0)).endOf('day')

        case 'year':
          // @ts-ignore
          return this.new(new Date(this).getFullYear(), 11, 31, 23, 59, 59, 999)
      }

      throw `Unsupported endOf type '${type}'`
    } },

    new: { configurable: true, enumerable  : false, value       : function (value) {
      // new Date с флагами userDate или utcDate как у текущей
      const newDate = new Date(value)

      if(this.userDate) {
        Object.defineProperty(newDate, 'userDate', {
          configurable: false,
          enumerable  : false,
          value       : true,
        })
      } else if(this.utcDate) {
        Object.defineProperty(newDate, 'utcDate', {
          configurable: false,
          enumerable  : false,
          value       : true,
        })
      }

      return newDate
    } },
  })

  // Форматтеры формируют строковое представление объекта Date
  Object.defineProperties(Date.prototype, {
    dateTimeViewFormatter: { configurable: true, enumerable  : false, value       : function (lang, options) {
      return dateTimeViewFormatter(this, lang, options)
    } },

    timeFormatter: { configurable: true, enumerable  : false, value       : function (lang) {
      return timeFormatter(this, lang)
    } },

    dateViewFormatter: { configurable: true, enumerable  : false, value       : function (lang, options) {
      return dateViewFormatter(this, lang, options)
    } },

    humanDateViewFormatter: { configurable: true, enumerable  : false, value       : function (lang) {
      return humanDateViewFormatter(this, lang)
    } },

    format: { configurable: true, enumerable  : false, value       : function (format) {
      return knownFormatFormatter(this, format)
    } },
  })

  // Реализация .user, .utc, .local аттрибутов для перевода даты в требуемый часовой пояс
  Object.defineProperties(Date.prototype, {

    // .user - дата в часовом поясе пользователя
    user: {
      configurable: true,
      enumerable  : false,

      get: function () {
        if(this.userDate) {
          return this
        }

        // добавляем пользовательское смещение времени и вычитаем локальное если дата не utc
        const newDate = new Date(Number(this) + (this.utcDate ? 0 : this.getTimezoneOffset() * 60000) + gmt * 3600000)

        Object.defineProperty(newDate, 'userDate', {
          configurable: false,
          enumerable  : false,
          value       : true,
        })

        return newDate
      },
    },

    // .utc - дата в UTC
    utc: {
      configurable: true,
      enumerable  : false,

      get: function () {
        if(this.utcDate) {
          return this
        }

        // Для получения даты в UTC вычитаем локальное смещение, а если дата .user то вычитаем пользовательское смещение
        const newDate = new Date(Number(this) + (this.userDate ? gmt * -3600000 : this.getTimezoneOffset() * 60000))

        Object.defineProperty(newDate, 'utcDate', {
          configurable: false,
          enumerable  : false,
          value       : true,
        })

        return newDate
      },
    },

    // .local - дата в локальному часовом поясе, для перевода .user и .utc к локальному времени
    local: {
      configurable: true,
      enumerable  : false,

      get: function () {
        if(!this.utcDate && !this.userDate) {
          return this.new(this)
        }

        return new Date(Number(this.utc) + new Date().getTimezoneOffset() * Date.DUR_MINUTE * -1)
      },
    },

    // Сохраняем в дате пользовательский часовой пояс для отладки
    gmt: {
      configurable: true,
      enumerable  : false,
      value       : gmt,
      writable    : false,
    },
  })
}

