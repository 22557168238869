import { is } from 'ramda'

const isString = is(String)

/**
 * Параметры числа по умолчанию.
 * @param {number} maxDigits Максимальное количество символов в числе (null - длина не ограничена).
 * @param {number} precision Длина дробной части числа (0 - число целое).
 * @param {boolean} positive Признак, должно ли число быть положительным.
 */
const defaultOptions = {
  maxDigits: null,
  precision: 0,
  positive : true,
}

/**
 * Проверяет, является ли переданный аргумент целым неотрицительным числом.
 * @param {number} inputNumber Входное число.
 * @returns Возвращает true, если аргумент целое и неотрицательное число. В ином случае false.
 */
export const isPositiveInteger = inputNumber => {
  if(Number.isInteger(inputNumber) && inputNumber >= 0){return true}

  return false
}

/**
 * Возвращает подстроку заданной длины. Отсчет длины начинается с первого символа.
 * @param {string} inputString Входная строка.
 * @param {number} maxLength Длина строки.
 * @returns Возвращает строку заданной длины.
 */
export const limitString = (inputString, maxLength) => {
  if(!isString(inputString)){return ''}

  if(isPositiveInteger(maxLength)){return inputString.slice(0, maxLength)}

  return inputString
}

export const numericProcessor = {
  maxDigits: (value, digitsCount) => limitString(value, digitsCount),

  precision: (value, num) => {
    value = isString(value) ? value : ''

    // Если первый символ цифры точка (что недопустимо) - возвращаем пустую строку
    if(value.charAt(0) === '.'){return ''}

    // Если первый символ цифры минус, а второй точка - игнорируем ввод точки
    if(value.charAt(0) === '-' && value.charAt(1) === '.'){return '-'}

    if(isPositiveInteger(num)) {
      if(!num){return value.replace(/\./g, '')} // Если число без дробной части - удаляем все точки

      // Если в значении есть точка - удаляем лишние точки, обрезаем дробную часть
      if(value.indexOf('.') !== -1) {
        // Удаляем все вхождения разделителя кроме первого
        const arr = value.replace(/^([^.]*\.)|\./g, '$1').split('.')

        // Возвращаем результат с урезанной дробной частью
        return arr[0].concat(`.${arr[1].slice(0, num)}`).toString()
      }

      return value
    }

    return value
  },

  positive: (value, isPositive) => {
    value = isString(value) ? value : '0'

    if(isPositive) {
      value = value.replace(/-/g, '')

      if(!value){return '0'} // Если получилась пустая строка (был введен только знак -)

      // Обрабатываем ситуацию, когда положительное многозначное число начинается с нуля
      if(value.length > 1) {
        // Если число не дробное - возвращаем значение без 0
        if(value.charAt(0) === '0' && value.charAt(1) !== '.'){return value.slice(1)}

        return value
      }
    }

    if(!value){return '0'} // Если на входе пустая строка

    if(value.length > 1) {
      // Исключаем ситуацию когда число начинается с 0 (0123). После 0 должна быть .
      if(value.charAt(0) === '0' && value.charAt(1) !== '.') {value = value.slice(1)}

      // Исключаем ввода нескольких нулей в начале числа.
      if(value.startsWith('-0') && value.charAt(2) !== '.'){return '-0'}
    }

    // Если разрешены отрицательные числа - оставляем знак минус только в начале строки
    const firstChar = value.charAt(0)
    const substr = value.slice(1).replace(/-/g, '')
    return firstChar + substr
  },
}

/**
 * Устанавливает набор правил для обработки числа.
 * @param {object} numberOptions Набор правил для обработки числа.
 * @returns Возвращает функцию, которая обрабатывает строку по набору правил.
 */
export default numberOptions => {
  // Если не поступил список опций или не указана опция, то она берется из значений по умолчанию
  numberOptions = { ...defaultOptions, ...numberOptions }

  // Возвращает строку составленную по числовым правилам.
  // Возвращаем не число, т.к. пользователь мог ввести только минус и его необходимо вывести в input
  return inputValue => {
    if(inputValue.trim() === '') {
      const { allowNull = false } = numberOptions

      if(allowNull) {
        return ''
      }
    }

    let result = isString(inputValue) ? inputValue.replace(/[^\d.-]/g, '') : '' // Удаляем символы кроме 0-9, знака - и .

    // Запускаем каждый обработчик из numericProcessor
    Object.keys(numericProcessor).forEach(processor => {
      const numericHandler = numericProcessor[processor]

      if({}.hasOwnProperty.call(numberOptions, processor)) {
        result = numericHandler(result, numberOptions[processor])
      }
    })

    return result
  }
}
