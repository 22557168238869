import Tippy from '@tippyjs/react'
import React from 'react'
import styled from 'styled-components'

const Popover = ({ children, trigger }) =>
  <StyledMenuTippy
    interactive
    popperOptions={{ positionFixed: true }}
    content={children}
    trigger='click'
  >
    {trigger}
  </StyledMenuTippy>

export default Popover

export const StyledMenuTippy = styled(Tippy)`
  box-shadow: 0px 7px 24px 0px #00000030;
  border-radius: 8px;

  .tippy-content {
    padding: 0;
    border-radius: 8px;
  }

  .tippy-arrow {
    color: #fff;
    z-index: 1;
  }
`
