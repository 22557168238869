import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useClickOutside } from '../../hooks'
import { PopupChild, PopupContainer } from './style'

// Дефолтное положение точки якоря на вызываюем элементе
const defaultAnchorOrigin = {
  horizontal: 'center',
  vertical  : 'top',
}


// Дефолтное положения точки привязки к якорю на попапе
const defaultTransformOrigin = {
  horizontal: 'center',
  vertical  : 'top',
}


// Дефолтный сдвиг попаппа относительно якоря
const defaultAnchorPosition = {
  left: 0,
  top : 0,
}


// Минимальный остутп от краев браузера
const minWindowEdgeOffset = 15

// Расчет координат якоря
const getPointOnAnchor = (options, boundingRect, offsetOptions) => {
  const { horizontal = 'center', vertical = 'bottom' } = options

  let x
  let y

  const {
    left,
    right,
    bottom,
    top,
    width,
    height,
  } = boundingRect

  let offsetY

  if(offsetOptions) {[, offsetY = y] = offsetOptions}

  if(horizontal === 'left') {x = left}
  else if(horizontal === 'right') {x = right}
  else if(horizontal === 'center') {x = left + Math.round(width / 2)}

  if(vertical === 'top') {y = top}
  else if(vertical === 'bottom') {y = offsetY ? bottom + offsetY : bottom}
  else if(vertical === 'center') {y = boundingRect.top + Math.round(height / 2)}

  return [x, y]
}

const parseTransform = el =>
  window
    .getComputedStyle(el)
    .transform.split(/\(|,|\)/)
    .slice(1, -1)
    .map(v => parseFloat(v))

export default function Popup(props) {
  const {
    backgroundColor,
    border,
    boxShadow,
    children,
    className,
    disableOverlayClick,
    isShown,
    onClickOutside,
    overflowY,
    width,

    // DOMElement anchor to
    anchorEl,

    // Tuple [x, y]
    anchorPoint,
    anchorOrigin = defaultAnchorOrigin,
    transformOrigin = defaultTransformOrigin,
    anchorPosition = defaultAnchorPosition,
    maxHeight = '350px',
    popupOffset,
  } = props

  const [topAnchorOffset, setTopAnchorOffset] = useState(0)
  const [leftAnchorOffset, setLeftAnchorOffset] = useState(0)

  const [topOffset, setTopOffset] = useState(0)
  const [leftOffset, setLeftOffset] = useState(0)

  // Дополнительная переменная
  const [redrawTick, setRedrawTick] = useState(0)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  /*
    Параметры нужны для того чтобы отрисовывать компонент в два этапа.
    Сначала строится невидимый компонент согласно anchorOrigin,
    у него вычисляется размеры и происходит сдвиг согласно transformOrigin.
    После этого включается видимость.
  */
  const [drawed, setDrawed] = useState(false)
  const [visible, setVisible] = useState(false)
  const popUpContainer = useRef(null)

  useClickOutside(popUpContainer, (event) => {
    if(disableOverlayClick) {
      onClickOutside(event)
    }
  })

  /*
    В этом эффекте выставляется позиция попапа относительно точки якоря.
    Попап становится display: block, но visibility: hidden.
  */
  useEffect(() => {
    if(isShown) {
      if(anchorEl) {
        const anchorElRect = anchorEl.getBoundingClientRect().toJSON()
        let el = anchorEl
        let correctionX = 0
        let correctionY = 0

        do{
          const transform = parseTransform(el)

          if(transform.length > 0) {
            correctionX += transform[4]
              + parseFloat(window.getComputedStyle(el).left)
              + parseFloat(window.getComputedStyle(el).borderLeftWidth)

            correctionY += transform[5]
              + parseFloat(window.getComputedStyle(el).top)
              + parseFloat(window.getComputedStyle(el).borderTopWidth)
          }

          el = el.offsetParent
        }while(el)

        anchorElRect.left -= correctionX
        anchorElRect.right -= correctionX
        anchorElRect.x -= correctionX
        anchorElRect.top -= correctionY
        anchorElRect.bottom -= correctionY
        anchorElRect.y -= correctionY

        // Находим точку на anchorEl по настройкам
        const [x, y] = getPointOnAnchor(anchorOrigin, anchorElRect, popupOffset)
        setTopAnchorOffset(x)
        setLeftAnchorOffset(y)
      }else if(anchorPoint) {
        const [x, y] = anchorPoint
        setTopAnchorOffset(x)
        setLeftAnchorOffset(y)
      }

      setDrawed(true)
    }else{
      setDrawed(false)
    }
  }, [anchorEl, anchorPoint, isShown, redrawTick, anchorOrigin, popupOffset])

  // Должен срабатывать когда элемент display: block
  // но еще visible: hidden.
  useEffect(() => {
    if(drawed) { // Расчитываем ширину/высоту/положение попапа
      const popUpBoundingRect = popUpContainer.current.getBoundingClientRect()

      const {
        width,
        height,
      } = popUpBoundingRect

      const { horizontal, vertical } = transformOrigin
      const { top, left } = anchorPosition
      let x
      let y

      if(horizontal === 'left') {x = 0} // default
      else if(horizontal === 'right') {x = -width}
      else if(horizontal === 'center') {x = -width / 2}

      if(vertical === 'top') {y = 0}
      else if(vertical === 'center') {y = -height / 2}
      else if(vertical === 'bottom') {y = -height}

      let transformedX = topAnchorOffset + x + top
      let transformedY = leftAnchorOffset + y + left

      // Проверки на уход за границу экрана
      if(transformedX < minWindowEdgeOffset) {
        transformedX = minWindowEdgeOffset
      }

      if(transformedY < minWindowEdgeOffset) {
        transformedY = minWindowEdgeOffset
      }

      setLeftOffset(transformedX)
      setTopOffset(transformedY)

      // TODO чек overlay и сдвиг
      setVisible(true)
      document.body.style.overflow = 'hidden'

      // if(document.body.scrollHeight > document.body.clientHeight) {
      //   document.body.style.paddingRight = '17px'
      // }
    }else{
      setVisible(false)
      document.body.style.overflow = ''

      // document.body.style.paddingRight = ''
    }
  }, [children, drawed, topAnchorOffset, windowHeight, windowWidth, leftAnchorOffset, transformOrigin, anchorPosition])

  useEffect(() => {
    let timeout

    const callbackOnResize = () => {
      if(timeout) {clearTimeout(timeout)}

      timeout = setTimeout(() => {
        setWindowWidth(window.innerWidth)
        setWindowHeight(window.innerHeight)
        setRedrawTick(redrawTick + 1)
      }, 150)
    }

    window.addEventListener('resize', callbackOnResize)
    return () => window.removeEventListener('resize', callbackOnResize)
  }, [isShown, redrawTick])

  return (
    <PageOverlay
      onClick={disableOverlayClick ? null : onClickOutside}
      isShown={isShown}
    >
      <StyledPopup
        backgroundColor={backgroundColor}
        border={border}
        boxShadow={boxShadow}
        ref={popUpContainer}
        drawed={drawed}
        visible={visible}
        width={width}
        maxHeight={maxHeight}
        topOffset={topOffset}
        leftOffset={leftOffset}
        overflowY={overflowY}
        className={className}
      >
        {children}
      </StyledPopup>
    </PageOverlay>
  )
}

const PageOverlay = styled.div`
  display: ${({ isShown }) => isShown ? 'block' : 'none'};
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  position: fixed;
`

export const StyledPopup = styled(PopupContainer)`
  z-index: 11;
  position: absolute;
  top: -16px;
  display: ${({ drawed }) => drawed ? 'block' : 'none'};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
  width: ${({ width }) => width || 'auto'};
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  overflow-y: ${props => props.overflowY || 'auto'};
  ${({ backgroundColor }) => `background-color: ${backgroundColor}`};
  border: ${({ border }) => border || '1px solid #aaa'};
  ${({ boxShadow }) => `box-shadow: ${boxShadow}`};
  transform-origin: 0 0 0;
  transform: ${({ leftOffset, topOffset }) => `translateX(${leftOffset}px) translateY(${topOffset}px);`};
  margin-top: 16px;
`

export { PopupContainer, PopupChild }
