import { ReactNode, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
let portalId = 1

const useCreatePortal = (portalRef = null, debugId='', outerElement: HTMLElement) => {
  const wrapperRef = useRef(null)

  if(wrapperRef.current === null && typeof document !== 'undefined') {
    wrapperRef.current = document.createElement('div')
    wrapperRef.current.id = `portal-${portalId++}-${debugId}`

    if(portalRef) {
      portalRef.current = wrapperRef.current
    }
  }

  useEffect(() => {
    const wrapper = wrapperRef.current

    if(!wrapper || typeof document === 'undefined') {
      return
    }

    let rootElement = document.body

    if(outerElement) {
      rootElement = outerElement
      rootElement.style.position = 'relative'
    }

    rootElement.appendChild(wrapper)

    return () => {
      rootElement.removeChild(wrapper)
    }
  }, [])

  return (children: ReactNode) => wrapperRef.current && createPortal(children, wrapperRef.current)
}

export default useCreatePortal
