import React from 'react'
import styled from 'styled-components'

import { colors } from '@constants'

const { BACKGROUND_LIGHT, PRIMARY_OUTLINE } = colors

const TabContent = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>
}

export default TabContent

const Container = styled.div`
  height: 100%;
  max-height: 100%;
  padding: 25px;
  overflow-y: auto;
  background-color: ${BACKGROUND_LIGHT};
  border: 1px solid ${PRIMARY_OUTLINE};
  box-sizing: border-box;
`
