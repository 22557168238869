import { colors } from '@constants'
import React, { FC } from 'react'
import styled from 'styled-components'
import TopMenuHelpIcon from '../../icons/TopMenuHelpIcon'
import { PopupContainer } from '../Popup'
import Popover from '../UserMenu/Popover'

type Props = {
  t: (value: string) => string
}

const items = [{
  href : 'https://agrotronic.rostselmash.com/doc/man_agrotronic.pdf',
  title: 'User manual Agrotronic',
}, {
  href : 'https://agrotronic.rostselmash.com/doc/man_agrotronic_pilot.pdf',
  title: 'User manual Agrotronic Pilot',
}, {
  href : 'https://agrotronic.rostselmash.com/doc/man_yield_mapping_chopper_guide.pdf',
  title: 'Humidity sensor FH',
}, {
  href : 'https://agrotronic.rostselmash.com/doc/man_agrotronic_admin.pdf',
  title: 'Agrotronic Administrator Tools Description',
}, {
  href : 'https://agrotronic.rostselmash.com/doc/man_cruise_control_kuk.pdf',
  title: 'User manual cruise control KUK',
}, {
  href : 'https://agrotronic.rostselmash.com/doc/2650.10.33.100ИМ_22.04.2024.pdf',
  title: 'RSM User Manual Auto-unloading KUK',
}]

const HelpMenu: FC<Props> = ({ t }) => {
  return (
    <Popover trigger={<div><TopMenuHelpIcon color={'white'} /></div>}>
      <PopupContainerStyled>
        {items.map(({ href, title }) =>
          <PopupChild key={title}>
            <PopupChildLink href={href} target="_blank">
              {t(title)}
            </PopupChildLink>
          </PopupChild>,
        )}
      </PopupContainerStyled>
    </Popover>
  )
}

export default HelpMenu

const PopupContainerStyled = styled(PopupContainer)`
  margin-top: 0;
`

const PopupChild = styled.li`
  list-style-type: none;
`

const PopupChildLink = styled.a`
  color: #202020;
  text-decoration: none;
  padding: 8px 24px;
  display: block;

  &:hover{
    background: ${colors.PRIMARY_HOVER};
  }
`
