import React from 'react'

const ArrowLeftIcon = props => {
  const {
    size = 24,
    color = '#202020',
    className
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 15.8751L11.12 11.9951L15 8.11508C15.39 7.72508 15.39 7.09508 15 6.70508C14.8132 6.51783 14.5595 6.4126 14.295 6.4126C14.0305 6.4126 13.7768 6.51783 13.59 6.70508L9 11.2951C8.61 11.6851 8.61 12.3151 9 12.7051L13.59 17.2951C13.98 17.6851 14.61 17.6851 15 17.2951C15.38 16.9051 15.39 16.2651 15 15.8751Z" fill={color}/>
    </svg>
  )
}

export default ArrowLeftIcon
