import React from 'react'

const mapSideRotate = {
  'bottom': 0,
  'left'  : 90,
  'right' : 270,
  'top'   : 180,
}

const ArrowDropDownIcon = ({
  size = 24,
  color = '#202020',
  direction = 'bottom',
}) => <svg
  width={size}
  height={size}
  transform={`rotate(${mapSideRotate[direction]})`}
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill={color}
    d="M8.70624 11.4137L11.2962 14.0037C11.6862 14.3937 12.3162 14.3937 12.7062 14.0037L15.2962 11.4137C15.9262 10.7837 15.4762 9.70374 14.5862 9.70374H9.40624C8.51624 9.70374 8.07624 10.7837 8.70624 11.4137Z"
  />
</svg>

export default ArrowDropDownIcon
