import React, {MutableRefObject, ReactChild, ReactNode, ReactNodeArray} from 'react'
import styled from 'styled-components'

type TTableHeaderProps = {
  className?: string
  children?: ReactNode
}

export default React.forwardRef(function TableHeader(props: TTableHeaderProps, ref: MutableRefObject<HTMLTableSectionElement> ) {
  return <ExtendedTableHeaderContainer
    ref={ref}
    className={props.className}
  >{props.children}</ExtendedTableHeaderContainer>
})

export const BaseTableHeaderContainer = styled.thead<TTableHeaderProps>`
  border-bottom: 1px solid #E1E2E4;
  background: white;
  width: 100%;
`

export const TableTitle = styled.div`
  padding-left: 10px;
  margin: 5px 0 5px 0;
  color: #323232;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
`

const ExtendedTableHeaderContainer = styled(BaseTableHeaderContainer)`
  display: inline-table;
  box-sizing: border-box;
`
