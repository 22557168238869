import React from 'react'

const FieldsIcon = ({
  size = 24,
  color = '#CCCCCC',
}) => <svg
  width={size}
  height={size}
  viewBox='0 0 24 24'
>
  <path
    d="M4.99986 1.50098C4.99986 1.22483 4.77601 1.00098 4.49986 1.00098H1.5C1.22386 1.00098 1 1.22483 1 1.50098V22.7644C1 22.8951 1.10592 23.001 1.23658 23.001V23.001C1.29933 23.001 1.35951 22.9761 1.40387 22.9317L4.85342 19.4821C4.94719 19.3884 4.99986 19.2612 4.99986 19.1286V1.50098ZM7.49986 1.00098C7.22372 1.00098 6.99986 1.22483 6.99986 1.50098V16.1286C6.99986 16.574 7.53843 16.7971 7.85342 16.4821L10.8534 13.4821C10.9472 13.3884 10.9999 13.2612 10.9999 13.1286V1.50098C10.9999 1.22483 10.776 1.00098 10.4999 1.00098H7.49986ZM13.4999 1.00098C13.2237 1.00098 12.9999 1.22483 12.9999 1.50098V10.1286C12.9999 10.574 13.5384 10.7971 13.8534 10.4821L16.8534 7.48214C16.9472 7.38837 16.9999 7.26119 16.9999 7.12859V1.50098C16.9999 1.22483 16.776 1.00098 16.4999 1.00098H13.4999ZM19.4999 1.00098C19.2237 1.00098 18.9999 1.22483 18.9999 1.50098V4.12859C18.9999 4.57404 19.5384 4.79712 19.8534 4.48214L22.9306 1.40497C22.9749 1.36061 22.9999 1.30046 22.9999 1.23772V1.23772C23 1.10699 22.894 1.00098 22.7633 1.00098H19.4999ZM23 5.54266C23 5.09721 22.4614 4.87413 22.1464 5.18911L5.18813 22.1474C4.87315 22.4624 5.09623 23.001 5.54169 23.001H9.78433C9.91694 23.001 10.0441 22.9483 10.1379 22.8545L22.8536 10.1389C22.9473 10.0451 23 9.91791 23 9.78531V5.54266ZM18.6232 22.8545C18.5295 22.9483 18.4023 23.001 18.2697 23.001H14.027C13.5816 23.001 13.3585 22.4624 13.6735 22.1474L22.1464 13.6745C22.4614 13.3595 23 13.5826 23 14.028V18.2707C23 18.4033 22.9473 18.5304 22.8536 18.6242L18.6232 22.8545ZM22.1526 22.1536C21.8399 22.4663 22.0614 23.001 22.5036 23.001V23.001C22.7778 23.001 23 22.7787 23 22.5046V22.5046C23 22.0624 22.4653 21.8409 22.1526 22.1536V22.1536Z"
    fill={color}
  />

  <path
    d="M23 22.5046C23 22.0624 22.4653 21.8409 22.1526 22.1536V22.1536C21.8399 22.4663 22.0614 23.001 22.5036 23.001V23.001C22.7778 23.001 23 22.7787 23 22.5046V22.5046Z"
    fill={color}
  />

  <path
    d="M13.6735 22.1474C13.3585 22.4624 13.5816 23.001 14.027 23.001H18.2697C18.4023 23.001 18.5295 22.9483 18.6232 22.8545L22.8536 18.6242C22.9473 18.5304 23 18.4033 23 18.2707V14.028C23 13.5826 22.4614 13.3595 22.1464 13.6745L13.6735 22.1474Z"
    fill={color}
  />

  <path
    d="M5.18813 22.1474C4.87315 22.4624 5.09623 23.001 5.54169 23.001H9.78433C9.91694 23.001 10.0441 22.9483 10.1379 22.8545L22.8536 10.1389C22.9473 10.0451 23 9.91791 23 9.78531V5.54266C23 5.09721 22.4614 4.87413 22.1464 5.18911L5.18813 22.1474Z"
    fill={color}
  />

  <path
    d="M22.9306 1.40497C22.9749 1.36061 22.9999 1.30046 22.9999 1.23772V1.23772C23 1.10699 22.894 1.00098 22.7633 1.00098H19.4999C19.2237 1.00098 18.9999 1.22483 18.9999 1.50098V4.12859C18.9999 4.57404 19.5384 4.79712 19.8534 4.48214L22.9306 1.40497ZM7.85342 16.4821C7.53843 16.7971 6.99986 16.574 6.99986 16.1286V1.50098C6.99986 1.22483 7.22372 1.00098 7.49986 1.00098H10.4999C10.776 1.00098 10.9999 1.22483 10.9999 1.50098V13.1286C10.9999 13.2612 10.9472 13.3884 10.8534 13.4821L7.85342 16.4821ZM16.9999 7.12859C16.9999 7.26119 16.9472 7.38837 16.8534 7.48214L13.8534 10.4821C13.5384 10.7971 12.9999 10.574 12.9999 10.1286V1.50098C12.9999 1.22483 13.2237 1.00098 13.4999 1.00098H16.4999C16.776 1.00098 16.9999 1.22483 16.9999 1.50098V7.12859ZM4.49986 1.00098C4.77601 1.00098 4.99986 1.22483 4.99986 1.50098V19.1286C4.99986 19.2612 4.94719 19.3884 4.85342 19.4821L1.40387 22.9317C1.35951 22.9761 1.29933 23.001 1.23658 23.001V23.001C1.10592 23.001 1 22.8951 1 22.7644V1.50098C1 1.22483 1.22386 1.00098 1.5 1.00098H4.49986Z"
    fill={color}
  />
</svg>

export default FieldsIcon
