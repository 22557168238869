import React from 'react'

const CrownIcon = ({
  size = 24,
  color = '#CCCCCC',
}) => <svg
  width={size}
  height={size}
  viewBox="0 0 22 16"
>
  <path
    d="M11.8443 0.463992C11.6608 0.175047 11.3423 0 11.0001 0C10.6578 0 10.3393 0.175047 10.1558 0.463992L6.32387 6.49935L1.64325 2.56763C1.31935 2.29556 0.859299 2.25671 0.494366 2.47062C0.129433 2.68453 -0.0614341 3.10491 0.0177152 3.52045L2.23994 15.1871C2.32978 15.6588 2.74214 16 3.22228 16H18.7778C19.258 16 19.6703 15.6588 19.7602 15.1871L21.9824 3.52045C22.0615 3.10491 21.8707 2.68453 21.5057 2.47062C21.1408 2.25671 20.6808 2.29556 20.3569 2.56763L15.6762 6.49935L11.8443 0.463992Z"
    fill={color}
  />
</svg>

export default CrownIcon
