import React, { useCallback } from 'react'
import styled from 'styled-components'
import { CheckboxIcon } from '../../icons'

const CheckBox = props => {
  const {
    checked,
    className,
    disabled,
    semiChecked,
    onChange,
    label,
    size,
    applyClickOnlyCheckbox = false
  } = props

  return (
    <Box className={className} size={size} disabled={disabled} onClick={applyClickOnlyCheckbox || disabled ? null : onChange}>
      <CheckboxIcon
        active={!disabled}
        checked={checked}
        semiChecked={semiChecked}
        size={size}
        onClick={applyClickOnlyCheckbox ? onChange : undefined}
      />

      <span>{label}</span>
    </Box>
  )
}

export default CheckBox

const Box = styled.div`
  cursor: ${({ disabled }) => disabled || 'pointer'};
  width: fit-content;
  display: flex;
  align-items: center;
  span {
    color: ${({ disabled }) => disabled ? '#a1a1a1' : '#202020'};
    padding-left: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`
