import React from 'react'

const UK = ({ size = 24 }) => <svg
  width={size}
  height={size}
  viewBox='0 0 24 24'
>
  <mask id="mask0_15854_2877" style={{ maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="3" width="24" height="18">
    <rect y="3.75" width={size} height={size / 1.4545} rx="2" fill="#C4C4C4"/>
  </mask>
  <g mask="url(#mask0_15854_2877)">
    <path d="M-0.461426 5.81008V8.53846H3.43488L-0.461426 5.81008ZM2.7675 21H8.53858V16.959L2.7675 21ZM15.4617 16.9597V21H21.232L15.4617 16.9597ZM-0.461426 15.4615V18.1899L3.43627 15.4615H-0.461426ZM21.2334 3H15.4617V7.041L21.2334 3ZM24.4617 18.1906V15.4615H20.5633L24.4617 18.1906ZM24.4617 8.53846V5.81008L20.5647 8.53846H24.4617ZM8.53858 3H2.7675L8.53858 7.041V3Z" fill="#00247D"/>
    <path d="M16.9429 15.4614L23.6666 20.1698C23.995 19.8315 24.231 19.4146 24.352 18.9589L19.357 15.4614H16.9429ZM8.53829 15.4614H7.05605L0.33305 20.1691C0.693743 20.536 1.1562 20.7984 1.67474 20.9202L8.53829 16.1142V15.4614ZM15.4614 8.53832H16.9436L23.6666 3.83062C23.2997 3.45835 22.8347 3.19787 22.3256 3.07947L15.4614 7.88547V8.53832ZM7.05605 8.53832L0.33305 3.83062C0.0048635 4.16914 -0.23131 4.58596 -0.353027 5.04147L4.64198 8.53832H7.05605Z" fill="#CF1B2B"/>
    <path d="M24.4617 14.0769H14.077V21H15.4617V16.9597L21.232 21H21.6924C22.0602 21.0001 22.4243 20.9267 22.7634 20.7841C23.1024 20.6416 23.4096 20.4328 23.6669 20.1699L16.9432 15.4615H19.3573L24.3523 18.9591C24.4167 18.7258 24.4617 18.4842 24.4617 18.2308V18.1906L20.5633 15.4615H24.4617V14.0769ZM-0.461426 14.0769V15.4615H3.43627L-0.461426 18.1899V18.2308C-0.461426 18.9861 -0.157503 19.6694 0.333344 20.1692L7.05635 15.4615H8.53858V16.1144L1.67504 20.9197C1.87858 20.9682 2.08904 21 2.30781 21H2.7675L8.53858 16.959V21H9.9232V14.0769H-0.461426ZM24.4617 5.76923C24.4621 5.04368 24.1765 4.3472 23.6669 3.83077L16.9439 8.53846H15.4617V7.88562L22.3259 3.07962C22.1185 3.02875 21.906 3.00203 21.6924 3H21.2334L15.4617 7.041V3H14.077V9.92308H24.4617V8.53846H20.5647L24.4617 5.81008V5.76923ZM8.53858 3V7.041L2.7675 3H2.30781C1.93993 2.99984 1.57574 3.07326 1.23665 3.21593C0.897568 3.3586 0.590442 3.56765 0.333344 3.83077L7.05635 8.53846H4.64227L-0.352733 5.04162C-0.421424 5.27818 -0.457983 5.52292 -0.461426 5.76923L-0.461426 5.81008L3.43488 8.53846H-0.461426V9.92308H9.9232V3H8.53858Z" fill="#EEEEEE"/>
    <path d="M14.077 9.92308V3H9.9232V9.92308H-0.461426V14.0769H9.9232V21H14.077V14.0769H24.4617V9.92308H14.077Z" fill="#CF1B2B"/>
  </g>
</svg>

export default UK
