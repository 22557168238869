import React from 'react'
import styled from 'styled-components'

const LeftActionBar = props => {
  const {
    children,
  } = props

  return (
    <Container>
      {children}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  /* TODO remove font-family? */
  font-family: 'Open Sans', sans-serif;

  background-color: #FFFFFF;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  padding: 25px 25px;
  height: 100%;
  max-width: 145px;
  position: relative;
  z-index: 999;
`

export default LeftActionBar
