import React, { HTMLProps, PropsWithChildren } from 'react'
import styled from 'styled-components'

type TModalFooteraActionsProps = PropsWithChildren<Pick<HTMLProps<HTMLDivElement>, 'className'>>

const ModalFooteraActions = ({ children, className }: TModalFooteraActionsProps) => {
  return (
    <ActionsBlock className={className}>
      {children}
    </ActionsBlock>
  )
}

const ActionsBlock = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 16px;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`

export default ModalFooteraActions
