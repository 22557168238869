import React from 'react'

const SortDownIcon = props => {
  const {
    size = 24,
    color = '#202020',
  } = props

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        d="M6.29 14.29L6 14.59V7C6 6.73478 5.89465 6.48043 5.70711 6.29289C5.51957 6.10536 5.26522 6 5 6C4.73479 6 4.48043 6.10536 4.2929 6.29289C4.10536 6.48043 4 6.73478 4 7V14.59L3.71 14.29C3.5217 14.1017 3.2663 13.9959 3 13.9959C2.7337 13.9959 2.47831 14.1017 2.29 14.29C2.1017 14.4783 1.99591 14.7337 1.99591 15C1.99591 15.2663 2.1017 15.5217 2.29 15.71L4.29 17.71C4.38511 17.801 4.49725 17.8724 4.62 17.92C4.7397 17.9729 4.86913 18.0002 5 18.0002C5.13087 18.0002 5.2603 17.9729 5.38 17.92C5.50275 17.8724 5.6149 17.801 5.71 17.71L7.71 15.71C7.80324 15.6168 7.8772 15.5061 7.92766 15.3842C7.97812 15.2624 8.00409 15.1319 8.00409 15C8.00409 14.8681 7.97812 14.7376 7.92766 14.6158C7.8772 14.4939 7.80324 14.3832 7.71 14.29C7.61676 14.1968 7.50607 14.1228 7.38425 14.0723C7.26243 14.0219 7.13186 13.9959 7 13.9959C6.86814 13.9959 6.73758 14.0219 6.61575 14.0723C6.49393 14.1228 6.38324 14.1968 6.29 14.29ZM11 8H21C21.2652 8 21.5196 7.89464 21.7071 7.70711C21.8946 7.51957 22 7.26522 22 7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6H11C10.7348 6 10.4804 6.10536 10.2929 6.29289C10.1054 6.48043 10 6.73478 10 7C10 7.26522 10.1054 7.51957 10.2929 7.70711C10.4804 7.89464 10.7348 8 11 8ZM21 11H11C10.7348 11 10.4804 11.1054 10.2929 11.2929C10.1054 11.4804 10 11.7348 10 12C10 12.2652 10.1054 12.5196 10.2929 12.7071C10.4804 12.8946 10.7348 13 11 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11ZM21 16H11C10.7348 16 10.4804 16.1054 10.2929 16.2929C10.1054 16.4804 10 16.7348 10 17C10 17.2652 10.1054 17.5196 10.2929 17.7071C10.4804 17.8946 10.7348 18 11 18H21C21.2652 18 21.5196 17.8946 21.7071 17.7071C21.8946 17.5196 22 17.2652 22 17C22 16.7348 21.8946 16.4804 21.7071 16.2929C21.5196 16.1054 21.2652 16 21 16Z"/>
    </svg>
  )
}

export default SortDownIcon
