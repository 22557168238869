import ArrowIcon from 'icons/ArrowIcon'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { PRIMARY_BACKGROUND } from '../../constants/colors'

type TProps = {
  goBackHandler?: () => void
  children?: ReactNode
  title: string
}

const AppHeader = ({ goBackHandler, children, title }: TProps) => <AppHeaderWrapper>
  {goBackHandler && <ArrowWrapper onClick={goBackHandler}>
    <ArrowIcon />
  </ArrowWrapper>}

  <Title>{title}</Title>

  {children && <RightContentWrapper>{children}</RightContentWrapper>}
</AppHeaderWrapper>

const AppHeaderWrapper = styled.nav`
  align-items: center;
  background-color: ${PRIMARY_BACKGROUND};
  box-sizing: border-box;
  display: flex;
  height: 64px;
  padding: 0 32px;
`

const ArrowWrapper = styled.div`
  display: flex;
  margin-right: 28px;
  cursor: pointer;
`

const RightContentWrapper = styled.div`
  margin-left: auto;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: #0A0A0A;
`

export default AppHeader
