import React from 'react'

const ContextMenuIcon = props => {
  const { size = 20, color = '#A1A1A1', ...restProps } = props
  return (
    <svg
      width={size}
      height={size}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
      fill="none"
      {...restProps}
    >
      <g>
        <path
          d="M9.99967 6.66671C10.9163 6.66671 11.6663 5.91671 11.6663 5.00004C11.6663 4.08337 10.9163 3.33337 9.99967 3.33337C9.08301 3.33337 8.33301 4.08337 8.33301 5.00004C8.33301 5.91671 9.08301 6.66671 9.99967 6.66671ZM9.99967 8.33337C9.08301 8.33337 8.33301 9.08337 8.33301 10C8.33301 10.9167 9.08301 11.6667 9.99967 11.6667C10.9163 11.6667 11.6663 10.9167 11.6663 10C11.6663 9.08337 10.9163 8.33337 9.99967 8.33337ZM9.99967 13.3334C9.08301 13.3334 8.33301 14.0834 8.33301 15C8.33301 15.9167 9.08301 16.6667 9.99967 16.6667C10.9163 16.6667 11.6663 15.9167 11.6663 15C11.6663 14.0834 10.9163 13.3334 9.99967 13.3334Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default ContextMenuIcon
