import * as icons from 'icons'
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../constants'
import Avatar from '../Avatar'
import { PopupContainer } from '../Popup'
import LangPicker from './LangPicker'
const { ImportIcon } = icons

const Menu = ({ links, lang = 'en', onChangeLang, userLogout, userInfo, t, push, languages }) => {
  const handleGoTo = link => () => push(link)

  return (
    <PopupContainerStyled>
      <UserCard>
        <Avatar src={userInfo.photo} size="65px" />
        <div>
          <p>{userInfo.name}</p>
          <span>{userInfo.email}</span>
        </div>
      </UserCard>

      <ListWrapper>
        <PopupChild borderTop onClick={handleGoTo(links.profile)}>
          <PopupChildLink>{t('profile')}</PopupChildLink>
        </PopupChild>

        <PopupChild>
          <PopupChildLink
            href="https://agrotronic.rostselmash.com/d"
            rel="noopener noreferrer"
          >
            {t('download app')} {t('Agrotronic')}
          </PopupChildLink>
        </PopupChild>

        <PopupChild onClick={handleGoTo(links.contact)}>
          <PopupChildLink>{t('Contact form')}</PopupChildLink>
        </PopupChild>

        <PopupChild onClick={handleGoTo(links.import)}>
          <PopupChildLink>
            <ImportIcon size={14} color="#000000" /> {t('Import data from USB')}
          </PopupChildLink>
        </PopupChild>

        {languages && <LangPicker
          lang={t(lang)}
          onChangeLang={onChangeLang}
          languages={languages}
        />}

        <PopupChild borderTop theme="red" onClick={userLogout}>
          <PopupChildLink>{t('sign_out')}</PopupChildLink>
        </PopupChild>
      </ListWrapper>
    </PopupContainerStyled>
  )
}

export default Menu

const PopupChild = styled.li`
  border-top: ${({ borderTop }) => borderTop && '1px solid #BDBDBD'};

  list-style-type: none;
`

const PopupChildLink = styled.a`
  color: #202020;
  text-decoration: none;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover{
    background: ${colors.PRIMARY_HOVER};
  }
`

const PopupContainerStyled = styled(PopupContainer)`
  margin-top: 0;
`

const ListWrapper = styled.div`
  // устанавливает шрифт для пунктов меню
  & > li, li > a, li > span {
    font-size: 14px;
  }
`

const UserCard = styled.div`
  padding: 18px 24px;
  display: flex;
  align-items: center;
  div {
    margin: 0 16px;

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: ${colors.PRIMARY_FONT};
      margin: 0;
    }

    span {
      font-size: 12px;
      line-height: 16px;
      color: ${colors.SECONDARY_FONT};
    }
  }
`
