import React from 'react'
import styled from 'styled-components'

const LeftActionBar = props => {
  const {
    children,
  } = props

  return (
    <Container {...props}>
      {children}
    </Container>
  )
}

const Container = styled.aside`
  display: flex;
  flex-shrink: 0;
  position: relative;
  font-family: 'Open Sans', sans-serif;
  background-color: #FFFFFF;
  border-right: 1px solid #ECECEC;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 1;
`

export default LeftActionBar
