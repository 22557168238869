import React from 'react'

const TableApperanceIcon = props => {
  const { size = 24, color = '#202020', apperance = 'tiny' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g>
        {apperance === 'large' &&
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.99998 4C1.99998 2.34315 3.34313 1 4.99998 1H19.3885C21.0453 1 22.3885 2.34315 22.3885 4C22.3885 5.65685 21.0453 7 19.3885 7H4.99998C3.34313 7 1.99998 5.65685 1.99998 4ZM1.99998 12C1.99998 10.3431 3.34313 9 4.99998 9H19.3885C21.0453 9 22.3885 10.3431 22.3885 12C22.3885 13.6569 21.0453 15 19.3885 15H4.99998C3.34313 15 1.99998 13.6569 1.99998 12ZM4.99998 17C3.34313 17 1.99998 18.3431 1.99998 20C1.99998 21.6569 3.34313 23 4.99998 23H19.3885C21.0453 23 22.3885 21.6569 22.3885 20C22.3885 18.3431 21.0453 17 19.3885 17H4.99998Z"
            fill={color}
          />
        }

        {apperance === 'medium' &&
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.99998 1C2.89541 1 1.99998 1.89543 1.99998 3C1.99998 4.10457 2.89541 5 3.99998 5H20.6666C21.7712 5 22.6666 4.10457 22.6666 3C22.6666 1.89543 21.7712 1 20.6666 1H3.99998ZM3.99998 7C2.89541 7 1.99998 7.89543 1.99998 9C1.99998 10.1046 2.89541 11 3.99998 11H20.6666C21.7712 11 22.6666 10.1046 22.6666 9C22.6666 7.89543 21.7712 7 20.6666 7H3.99998ZM1.99998 15C1.99998 13.8954 2.89541 13 3.99998 13H20.6666C21.7712 13 22.6666 13.8954 22.6666 15C22.6666 16.1046 21.7712 17 20.6666 17H3.99998C2.89541 17 1.99998 16.1046 1.99998 15ZM3.99998 19C2.89541 19 1.99998 19.8954 1.99998 21C1.99998 22.1046 2.89541 23 3.99998 23H20.6666C21.7712 23 22.6666 22.1046 22.6666 21C22.6666 19.8954 21.7712 19 20.6666 19H3.99998Z"
            fill={color}
          />
        }

        {apperance === 'tiny' &&
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.99997 1C2.44769 1 1.99997 1.44772 1.99997 2C1.99997 2.55228 2.44769 3 2.99997 3H21.1818C21.7341 3 22.1818 2.55228 22.1818 2C22.1818 1.44772 21.7341 1 21.1818 1H2.99997ZM2.99997 5C2.44769 5 1.99997 5.44772 1.99997 6C1.99997 6.55228 2.44769 7 2.99997 7H21.1818C21.7341 7 22.1818 6.55228 22.1818 6C22.1818 5.44772 21.7341 5 21.1818 5H2.99997ZM1.99997 10C1.99997 9.44771 2.44769 9 2.99997 9H21.1818C21.7341 9 22.1818 9.44771 22.1818 10C22.1818 10.5523 21.7341 11 21.1818 11H2.99997C2.44769 11 1.99997 10.5523 1.99997 10ZM2.99997 13C2.44769 13 1.99997 13.4477 1.99997 14C1.99997 14.5523 2.44769 15 2.99997 15H21.1818C21.7341 15 22.1818 14.5523 22.1818 14C22.1818 13.4477 21.7341 13 21.1818 13H2.99997ZM1.99997 18C1.99997 17.4477 2.44769 17 2.99997 17H21.1818C21.7341 17 22.1818 17.4477 22.1818 18C22.1818 18.5523 21.7341 19 21.1818 19H2.99997C2.44769 19 1.99997 18.5523 1.99997 18ZM2.99997 21C2.44769 21 1.99997 21.4477 1.99997 22C1.99997 22.5523 2.44769 23 2.99997 23H21.1818C21.7341 23 22.1818 22.5523 22.1818 22C22.1818 21.4477 21.7341 21 21.1818 21H2.99997Z"
            fill={color}
          />
        }
      </g>
    </svg>
  )
}

export default TableApperanceIcon
