import React from 'react'

const CalendarBlocksIcon = props => {
  const {
    size = 24,
    color = '#828282',
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="9.61905" y="6.28572" width="5.71429" height="2.85714" rx="1" fill={color}/>
      <rect x="16.2857" y="6.28572" width="5.71429" height="2.85714" rx="1" fill={color}/>
      <rect x="2" y="10.5714" width="5.71429" height="2.85714" rx="1" fill={color}/>
      <rect x="9.61905" y="10.5714" width="5.71429" height="2.85714" rx="1" fill={color}/>
      <rect x="16.2857" y="10.5714" width="5.71429" height="2.85714" rx="1" fill={color}/>
      <rect x="2" y="14.8571" width="5.71429" height="2.85714" rx="1" fill={color}/>
      <rect x="9.61905" y="14.8571" width="5.71429" height="2.85714" rx="1" fill={color}/>
    </svg>
  )
}

export default CalendarBlocksIcon
