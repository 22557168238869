import React, { ReactChild } from 'react'
import styled from 'styled-components'

type TTableFooter = {
  children?: ReactChild
  className?: string
}

export default function TableFooter({ children, className }: TTableFooter) {
  return <ExtendedTableFooterContainer className={className}>{children}</ExtendedTableFooterContainer>
}

export const BaseTableFooterContainer = styled.tfoot`
  padding: 4px;
`

const ExtendedTableFooterContainer = styled(BaseTableFooterContainer)`
  display: flex;
  align-items: center;
`
