import { is, isEmpty, isNil } from 'ramda'
import React, { KeyboardEvent, ReactNode, useCallback, useEffect, useRef } from 'react'
import InputControlContainer from '../InputControlContainer'
import styled from 'styled-components'

const hasValue = inputValue => {
  return !(isNil(inputValue) || isEmpty(inputValue))
}

type TDefaultInput = {
  children: ReactNode
  error: boolean
  helperText: string
  icon: ReactNode
  isPopupOpen: boolean
  label: string
  onChange: (arg: string) => void
  onClick: () => void
  onFocus: () => void
  onKeyDown: (arg: KeyboardEvent<HTMLInputElement>) => void
  required: boolean
  updateHelperTextHeight: (arg: number) => void
  value: string
}

const DefaultInput = ({
  children,
  error,
  helperText,
  icon,
  isPopupOpen,
  label,
  onChange,
  onClick,
  onFocus,
  onKeyDown,
  required,
  updateHelperTextHeight,
  value,
}: TDefaultInput) => {
  const inputRef = useRef<HTMLInputElement>()

  const handleClick = useCallback(() => {
    handleFocus()

    if(is(Function, onClick)) {
      onClick()
    }
  }, [])

  const handleFocus = useCallback(() => {
    if(onFocus) {onFocus()}
  }, [onFocus])

  useEffect(() => {
    if(inputRef.current) {
      inputRef.current.focus()
    }
  }, [isPopupOpen])

  return (
    <StyledInputControlContainer
      error={error}
      focused={hasValue(value)}
      helperText={helperText}
      label={label}
      onClick={handleClick}
      required={required}
      updateHelperTextHeight={updateHelperTextHeight}
    >
      <InputContentWrapper>
        <TagsWrapper>
          {children}
        </TagsWrapper>

        {isPopupOpen && <InputWrapper withChildren={Boolean(children)}>
          <TextBox
            onChange={e => onChange(e.target.value)}
            onFocus={handleFocus}
            onKeyDown={onKeyDown}
            ref={inputRef}
            type="text"
            value={value}
          />
        </InputWrapper>}
      </InputContentWrapper>

      <IconContainer>{icon}</IconContainer>
    </StyledInputControlContainer>
  )
}

export default DefaultInput

const InputWrapper = styled.div`
  width: ${({ withChildren }: { withChildren: boolean }) => withChildren ? '30%' : '100%'};
`

const InputContentWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  width: 100%;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 35px;
    bottom: 0;
    display: block;
    width: 40px;
    background-image: linear-gradient(
      90deg
      ,hsla(0,0%,100%,0),#F5F5F5);
    pointer-events: none;
  }
`

const StyledInputControlContainer = styled(InputControlContainer)`
  > div:first-child {
    background-color: #F5F5F5;
    overflow: unset;

    > div {
      padding-right: 30px;
    }
  }
`

const TagsWrapper = styled.div`
  overflow: hidden;
  width: auto;
`

const TextBox = styled.input`
  margin: 0;
  padding: 0;
  height: 40px;
  color: #202020;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  border: none;
  box-sizing : border-box;
  outline: none;
  cursor: pointer;
  width: auto;
`

const IconContainer = styled.div`
  line-height: .9;
  padding-right: 10px;
  position: absolute;
  right: 0;
`
