import React from 'react'

const defaultParam = {
  size : '24px',
  color: '#202020',
}

const AutoDriveTypeIcon = (props = defaultParam) => {
  const {
    size = defaultParam.size,
    color = defaultParam.color,
  } = props

  return (
    <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.67816 0.511719C1.97587 0.511719 2.2432 0.641817 2.42639 0.848263C2.97767 1.12332 3.35632 1.69284 3.35632 2.3508C3.35632 2.90292 3.08969 3.39277 2.67816 3.6986V12.8393C2.67816 13.4455 3.16962 13.937 3.77586 13.937C4.38211 13.937 4.87356 13.4455 4.87356 12.8393V3.60942C4.87356 1.89861 6.26045 0.511719 7.97126 0.511719C9.68208 0.511719 11.069 1.89861 11.069 3.60942V11.937C11.069 12.8751 11.7149 13.6624 12.5862 13.8784V11.5807L18.25 14.8507L12.5862 18.1207V15.9082C10.6047 15.6698 9.06896 13.9828 9.06896 11.937V3.60942C9.06896 3.00318 8.57751 2.51172 7.97126 2.51172C7.36502 2.51172 6.87356 3.00318 6.87356 3.60942V12.8393C6.87356 14.5501 5.48668 15.937 3.77586 15.937C2.06505 15.937 0.678161 14.5501 0.678161 12.8393V3.6986C0.266633 3.39277 0 2.90292 0 2.3508C0 1.69284 0.378652 1.12332 0.92993 0.848263C1.11312 0.641817 1.38045 0.511719 1.67816 0.511719Z"
        fill={color}
      />
    </svg>
  )
}

export default AutoDriveTypeIcon
