import React from 'react'

const defaultParam = {
  className: '',
  color    : '#202020',
  size     : '24px',
}

const NotesIcon = (props = defaultParam) => {
  const {
    className = defaultParam.className,
    color = defaultParam.color,
    size = defaultParam.size,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.6666 2.6665H7.99998C6.53331 2.6665 5.34665 3.8665 5.34665 5.33317L5.33331 26.6665C5.33331 28.1332 6.51998 29.3332 7.98665 29.3332H24C25.4666 29.3332 26.6666 28.1332 26.6666 26.6665V10.6665L18.6666 2.6665ZM17.3333 11.9998V4.6665L24.6666 11.9998H17.3333Z"
        fill={color}
      />
    </svg>

  )
}

export default NotesIcon
