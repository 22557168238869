import React from 'react'

const ConnectionIcon = props => {
  const { size = 15, color = '#A1A1A1', ...restProps } = props
  return (
    <svg
      width={size / 15 * 20}
      height={size}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 15"
      fill="none"
      {...restProps}
    >
      <path
        d="M1 4.57278C3.20516 2.36762 6.24787 1 9.61923 1C12.9906 1 16.0333 2.36762 18.2385 4.57278"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />

      <path
        d="M15.9913 6.82049C14.3587 5.18782 12.1005 4.18066 9.61969 4.18066C7.13888 4.18066 4.88072 5.18782 3.24805 6.82049"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />

      <path
        d="M13.7433 9.06771C12.6937 8.01813 11.2307 7.36084 9.6192 7.36084C8.00773 7.36084 6.54469 8.01813 5.49512 9.06771"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />

      <path
        d="M9.61956 14.7821C10.4978 14.7821 11.2098 14.0701 11.2098 13.1918C11.2098 12.3135 10.4978 11.6016 9.61956 11.6016C8.74128 11.6016 8.0293 12.3135 8.0293 13.1918C8.0293 14.0701 8.74128 14.7821 9.61956 14.7821Z"
        fill={color}
      />
    </svg>
  )
}

export default ConnectionIcon
