import React, { FC } from "react";
import styled from "styled-components";
import { MenuTippy } from "./MenuTippy";
import { SingleTippy } from "./SingleTippy";

export type SubMenuItemType = {
  external: boolean;
  icon?: JSX.Element;
  label: string;
  link: string;
  separated?: boolean;
  linkTo?: string;
};

export type MenuItemType = {
  external?: boolean;
  icon: JSX.Element;
  label: string;
  link?: string;
  items?: SubMenuItemType[];
};

export type MainMenuItemType = MenuItemType & {
  active: boolean;
  linkTo?: string;
  testId?: string;
};

type Props = {
  items: MainMenuItemType[];
  noTooltip: boolean;
  t: (key: string) => string;
  onNavigate: (link: string) => void;
};

const MainMenu: FC<Props> = ({ items, t, noTooltip, onNavigate }) => {
  return (
    <Navigation>
      {items.map(
        (item) =>
          item &&
          (item.items?.length ? (
            <MenuTippy
              item={item}
              t={t}
              onNavigate={onNavigate}
              key={item.link}
              testId={item.testId}
            />
          ) : (
            <SingleTippy
              item={item}
              t={t}
              noTooltip={noTooltip}
              onNavigate={onNavigate}
              key={item.link}
              testId={item.testId}
            />
          ))
      )}
    </Navigation>
  );
};

export default MainMenu;

const Navigation = styled.div`
  display: flex;
  flex-grow: 1;
`;
