import { ArrowIcon } from 'icons'
import { is } from 'ramda'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../constants'
import Spinner from '../Spinner'

const {
  PRIMARY_HOVER,
  SECONDARY_HOVER,
  SECONDARY_SELECT,
  PRIMARY_IMPORTANT,
  MARKED_FONT,
} = colors

export default function TreeNode({
  childLeftOffset = 44,
  className,
  hasChildren,
  isExpanded,
  isLoading,
  isMarked,
  isSelected,
  level,
  onCollapse,
  onContentClick,
  onExpand,
  title,
  titleLeftOffset = 44,
  value,
}) {
  const [isExpanedLocal, setIsExpanedLocal] = useState(false)
  const isExpandedComputed = isExpanded || isExpanedLocal

  const handleExpand = () => {
    if(is(Function, onExpand)) {onExpand(value)}

    if(isExpanded === undefined) {setIsExpanedLocal(true)}
  }

  const handleCollapse = () => {
    if(is(Function, onCollapse)) {onCollapse(value)}

    if(isExpanded === undefined) {setIsExpanedLocal(false)}
  }

  const handleClick = () => isExpandedComputed ? handleCollapse() : handleExpand()

  const handleContentClick = useCallback(event => {
    event.stopPropagation()

    if(is(Function, onContentClick)) {onContentClick(value)}
  }, [value, onContentClick])

  return <Node
    childLeftOffset={childLeftOffset}
    className={className}
    hasChildren={hasChildren}
    isSelected={isSelected}
    level={level || 0}
    titleLeftOffset={titleLeftOffset}
  >
    {isSelected && <RowSelectIndicator />}

    {isLoading
      ? <Spinner size={20} color={PRIMARY_IMPORTANT} />

      : hasChildren &&
            <RoundButton onClick={handleClick} isSelected={isSelected}>
              <ArrowIcon direction={isExpandedComputed ? 'bottom' : 'right'} size={14} />
            </RoundButton>
    }

    <Title onClick={handleContentClick} isMarked={isMarked}>
      {title}
    </Title>
  </Node>
}

/**
 * Обработчик отступа для узла дерева.
 * @param {number} nodeLevel Уровень вложенности узла (корневой уровень имеет 0).
 * @param {boolean} hasChildren Признак, указывающий есть ли у узла дочерние узлы.
 * @returns {string} Сдвиг элемента по горизонтали относительно его исходного расположения.
 */
const handleMargin = (nodeLevel, hasChildren, isSelected, titleLeftOffset, childLeftOffset) => {
  // Вложенный узел
  if(nodeLevel) {
    if(hasChildren){return `padding-left: ${nodeLevel * titleLeftOffset}px;`}

    return `padding-left: ${nodeLevel * titleLeftOffset + childLeftOffset}px;`
  }

  // Корневой узел
  if(hasChildren){return isSelected ? 'padding-left: 4px;' : 'padding-left: 0px;'}

  return `padding-left: ${titleLeftOffset}px;`
}

const Node = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 44px;
  font-size: 14px;
  ${({ level, hasChildren, isSelected, titleLeftOffset, childLeftOffset }) => handleMargin(level, hasChildren, isSelected, titleLeftOffset, childLeftOffset)};
`

const RoundButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  min-width: 36px;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:not(:disabled):hover {
    background-color: ${({ isSelected }) => isSelected ? SECONDARY_HOVER : PRIMARY_HOVER};
  }
`

const Title = styled.span`
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: ${({ isMarked }) => isMarked ? 600 : 400};
  background-color: ${({ isMarked }) => isMarked ? MARKED_FONT : 'transparent'};
`

const RowSelectIndicator = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  background-color: ${SECONDARY_SELECT};
  border-left: 4px solid ${PRIMARY_IMPORTANT};
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`
