import React from 'react'

const BookSearchIcon = props => {
  const {
    size = 20,
    color = '#FFFFFF',
  } = props

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M15.5 12C16.3048 12 17.0949 12.2157 17.788 12.6249C18.481 13.034 19.0517 13.6215 19.4405 14.3262C19.8293 15.0308 20.022 15.8269 19.9985 16.6313C19.975 17.4358 19.7362 18.2193 19.307 18.9L22.391 21.984L20.984 23.391L17.876 20.322C17.2845 20.6896 16.6158 20.9149 15.9225 20.9802C15.2292 21.0455 14.5302 20.949 13.8805 20.6983C13.2308 20.4475 12.6482 20.0495 12.1784 19.5353C11.7087 19.0212 11.3647 18.4051 11.1736 17.7355C10.9824 17.0658 10.9492 16.361 11.0767 15.6764C11.2041 14.9917 11.4888 14.346 11.9081 13.7901C12.3275 13.2341 12.8702 12.7831 13.4935 12.4725C14.1167 12.1619 14.8036 12.0001 15.5 12V12ZM15.5 14C14.837 14 14.2011 14.2634 13.7322 14.7323C13.2634 15.2011 13 15.837 13 16.5C13 17.1631 13.2634 17.799 13.7322 18.2678C14.2011 18.7367 14.837 19 15.5 19C16.163 19 16.7989 18.7367 17.2678 18.2678C17.7366 17.799 18 17.1631 18 16.5C18 15.837 17.7366 15.2011 17.2678 14.7323C16.7989 14.2634 16.163 14 15.5 14ZM6 21.998C4.896 21.998 4 21.102 4 19.998V3.99805C4 2.89405 4.896 1.99805 6 1.99805H7V8.99805L9.5 7.49805L12 8.99805V1.99805H18C18.5304 1.99805 19.0391 2.20876 19.4142 2.58383C19.7893 2.95891 20 3.46761 20 3.99805V11.809C19.344 11.1805 18.5635 10.6965 17.7089 10.3882C16.8544 10.0799 15.9446 9.95419 15.0385 10.0191C14.1323 10.0841 13.2498 10.3383 12.4479 10.7653C11.646 11.1923 10.9426 11.7827 10.3829 12.4984C9.82328 13.214 9.41987 14.039 9.19871 14.9202C8.97756 15.8014 8.94357 16.7191 9.09896 17.6142C9.25434 18.5093 9.59564 19.3619 10.1008 20.117C10.606 20.8721 11.2639 21.5129 12.032 21.998H5.998H6Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default BookSearchIcon
