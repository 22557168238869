import React from 'react'

const RingIcon = props => {
  const { size = 20, color = '#FFFFFF' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fillRule="evenodd"
          d="M 14 6L 14 11.184C 15.163 11.598 16 12.696 16 14L 0 14C 0 12.696 0.837036 11.598 2 11.184L 2 6C 2 2.68701 4.68701 0 8 0C 11.313 0 14 2.68701 14 6ZM 10 15.0004C 10 16.1044 9.104 17.0004 8 17.0004C 6.896 17.0004 6 16.1044 6 15.0004L 10 15.0004Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default RingIcon
