import React from 'react'
import Button from '../Button'

const getColorOf = (disabled, pressed) => {
  const defaultColor = '#202020'
  const disabledColor = '#E0E0E0'
  const presssedColor = '#D10029'

  let color = defaultColor

  if(disabled) {
    color = disabledColor
  }else if(pressed) {
    color = presssedColor
  }

  return color
}

const IconButton = React.forwardRef((props, ref) => {
  const {
    icon,
    disabled = false,
    onClick,
    isLoading,
    size = 15,
    iconProps = {},
    ...rest
  } = props

  return (
    <Button
      variant="icon"
      disabled={disabled}
      onClick={onClick}
      isLoading={isLoading}
      ref={ref}
      {...rest}
    >
      {({ pressed }) => icon({ color: getColorOf(disabled, pressed), size, ...iconProps })}
    </Button>
  )
})

export default IconButton
