import { is } from 'ramda'
import React from 'react'
import styled from 'styled-components'
import { CloseIcon } from '../../icons'
import IconButton from '../IconButton'

const ModalHeader = ({ children, onClose }) => {
  const handleCancel = e => {
    e.stopPropagation()

    if(is(Function, onClose)) {onClose()}
  }

  return (
    <Header>
      <Title>{children}</Title>
      {onClose &&
        <ButtonClose
          size={20}
          icon={CloseIcon}
          onClick={handleCancel}
        />
      }
    </Header>
  )
}

const Header = styled.div``

const ButtonClose = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`

const Title = styled.h1`
  margin: 0 0 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
`

export default ModalHeader
