import React, { useCallback } from 'react'
import styled from 'styled-components'
import Avatar from '../Avatar'
import Menu from './Menu'
import Popover from './Popover'

function UserMenu({ changeLang, logout, userInfo, links, t, gotoLink, languages }) {
  const onChangeLang = useCallback(lang => {
    changeLang(lang)
  }, [changeLang])

  const UserPhoto = photo =>
    <ImageWrapper>
      <Avatar src={photo} size="36px" />
    </ImageWrapper>

  return (
    <Popover trigger={UserPhoto(userInfo.photo)}>
      <Menu
        photo={userInfo.photo}
        name={userInfo.name}
        email={userInfo.email}
        lang={userInfo.lang}
        userLogout={logout}
        onChangeLang={onChangeLang}
        userInfo={userInfo}
        links={links}
        push={gotoLink}
        t={t}
        languages={languages}
      />
    </Popover>
  )
}

export default UserMenu

const ImageWrapper = styled.div`
  margin: 0 24px;
  cursor: pointer;
`
