import React from 'react'

const defaultParam = {
  size : '24px',
  color: '#202020',
}

const FolderIcon = (props = defaultParam) => {
  const {
    size = defaultParam.size,
    color = defaultParam.color,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z"
      />
    </svg>
  )
}

export default FolderIcon
