import React from 'react'

const LifebuoyIcon = ({
  size = 24,
  color = '#FFFFFF',
}) => <svg
  width={size}
  height={size}
  viewBox='0 0 22 22'
>
  <path
    d="M19.79 15.41C20.74 13.24 20.74 10.75 19.79 8.59L17.05 9.83C17.65 11.21 17.65 12.78 17.06 14.17L19.79 15.41ZM15.42 4.21C13.25 3.26 10.76 3.26 8.59 4.21L9.83 6.94C11.22 6.35 12.79 6.35 14.18 6.95L15.42 4.21ZM4.21 8.58C3.26 10.76 3.26 13.24 4.21 15.42L6.95 14.17C6.35 12.79 6.35 11.21 6.95 9.82L4.21 8.58ZM8.59 19.79C10.76 20.74 13.25 20.74 15.42 19.78L14.18 17.05C12.8 17.65 11.22 17.65 9.84 17.06L8.59 19.79ZM12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C10.6868 22 9.38642 21.7413 8.17317 21.2388C6.95991 20.7362 5.85752 19.9997 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2ZM12 8C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12C8 13.0609 8.42143 14.0783 9.17157 14.8284C9.92172 15.5786 10.9391 16 12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8Z"
    fill={color}
  />
</svg>

export default LifebuoyIcon
