import React from 'react'
import styled from 'styled-components'

import AvatarPlaceholder from '../../icons/AvatarPlaceholder'

function Avatar(props) {
  const {
    src,
    size = '26px',
    square,
    className,
  } = props

  return src ?
    <Image {...{ src, size, square }} alt="" className={className} />
    :
    <Wrapper {...{ size, square }}><AvatarPlaceholder {...{ size }} /></Wrapper>
}

export default Avatar

const Image = styled.img`
  border-radius: ${({ square }) => square ? '5%' : '50%'};
  color: white;
  object-fit: cover;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

const Wrapper = styled.div`
  border-radius: ${({ square }) => square ? '5%' : '50%'};
  background-color: #BDBDBD;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`
